import {useSelector} from "react-redux";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import React from "react";
import Login from "../Pages/Dashboard/Login";
import AdminDashboard from "../Admin/Dashboard";
import AdminSessions from "../Admin/Sessions/Sessions";
import {NotFound} from "../Pages/Alerts/404";
import Users from "../Admin/Sessions/Users";
import AdminNotifications from "../Admin/Notifications/lists";
import Payments from "../Admin/Payments";
import PayLinks from "../Admin/PayLinks";
import PlanUpdate from "../Admin/PlanUpdatePage";
const paths =[
    {path: `/`,component: <AdminDashboard />},
    {path: `/sessions`,component: <AdminSessions />},
    {path: `/users`,component: <Users />},
    {path: `/notifications`,component: <AdminNotifications />},
    {path: `/payments`,component: <Payments />},
    {path: `/paylinks`,component: <PayLinks />},
    {path: `/update-plan`,component: <PlanUpdate />},
]
const Navigator = () =>{
    return  <Routes>
        <Route exact path="/login" element={<PublicOutlet />}>
            <Route path="" element={<Login />}/>
        </Route>
        {paths.map(path =><Route exact path={path?.path} element={<PrivateOutlet />}>
            <Route path="" element={path?.component} />
        </Route>)}
        <Route path="*" element={<NotFound />}/>
    </Routes>
}
function PrivateOutlet() {
    const isLogged = useSelector(state => state.user?.isLogged && state?.user?.userId);
    return isLogged ? <Outlet /> : <Navigate to="/login" />;
}
function PublicOutlet() {
    const isLogged = useSelector(state => state.user?.isLogged && state?.user?.userId);
    return isLogged ? <Navigate to={'/'} /> : <Outlet />;
}

export default Navigator;

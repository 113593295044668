import {Button, Divider, Drawer, Group, Image, Paper, ScrollArea, Stack} from "@mantine/core";
import {BoldText, ParagraphText} from "../Components/TextComponents";
import {IconView} from "../Components/PageComponents";
import {IconChevronLeft} from "@tabler/icons";

const NotificationPage = ({data,onClose}) =>{
    return <Drawer opened={true} onClose={onClose} position={"right"} size={"80%"} withCloseButton={false}>
        <Paper shadow={"md"} withBorder>
            <Group my={5}>
                <IconView icon={<IconChevronLeft />} onClick={onClose} />
                <BoldText size={"md"} text={data?.title} />
            </Group>
        </Paper>
        <ScrollArea type={"auto"} sx={{height: "87%"}}>
            <Stack mx={10} my={20}>
                {data?.image && <Image src={data?.image} fit={"contain"} height={200} width={200}/>}
                <ParagraphText size={"sm"} text={data?.content} />
            </Stack>
            <Group position={"apart"} m={5} grow >
                {data?.buttons?.map(a =>{
                    return <Button onClick={() => window.open(a?.url)} sx={{maxWidth: "300px"}}>{a?.label}</Button>
                })}
            </Group>
        </ScrollArea>
    </Drawer>
}
export default NotificationPage;
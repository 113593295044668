import React, {useEffect, useState} from "react";
import {Button, Divider, Grid, Group, Paper, SegmentedControl, Stack, Text} from "@mantine/core";
import {AdminApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import PlanPreview from "./PlanPreview";
import {useSelector} from "react-redux";
import {PageLoading} from "../Components/PageComponents";


const Update = () =>{
    const {userId} = useSelector(state => state.user)
    const [planType,setPlanType] = useState("Premium");
    const [plans,setPlans] = useState([]);
    const [devices,setDevices] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${AdminApi.PlanUpdates}/userPlans`,{});
        setPlans(data?.plans || []);
        setIsLoading(false);

    }
    const getDevices = async () =>{
        const data = await RequestGet(`${AdminApi.deviceList}/${userId}`);
        setDevices({wba: data?.wba, bsm: data?.bsm});
    };
    useEffect(()=>{
        getDetails();
        getDevices();
    },[])

    return <PageLoading isLoading={isLoading}>
        <SegmentedControl data={["Premium", "Advanced"]} value={planType} onChange={setPlanType} size={"xs"} my={10} color={"violet"} />
        <Grid m={10}>
            {plans?.filter(a => a?.type === planType)?.map(a => <Grid.Col span={3}>
                <PlanPreview details={a} devices={devices} isAdmin={false} />
            </Grid.Col>)}
        </Grid>
    </PageLoading>
}

export default Update;
import {Grid, SegmentedControl, Select, Text, TextInput, Button, Group} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {forwardRef} from "react";
import {IconView} from "../Components/PageComponents";
import {BoldText} from "../Components/TextComponents";

const VariablesView = ({label,variables,props,updateVariables,addVariable,webhookList}) =>{
    console.log("Variables",props);
    const changeVarType = (i, type) =>{
        const vari = variables;
        vari[i] = {...vari[i],type}
        updateVariables(vari);
    }
    const changeVarValue = (i, value) =>{
        const vari = variables;
        vari[i] = {...vari[i],value}
        updateVariables(vari);
    }
    const changeVarLabel = (i, label) =>{
        const vari = variables;
        vari[i] = {...vari[i],label}
        updateVariables(vari);
    }
    const addVar = () =>{
        const v = variables;
        v.push({label: "",type: "map",value: "", required: false});
        updateVariables(v);
    }
    const deleteVar = (i) =>{
        const v = variables;
        v.splice(i,1)
        updateVariables(v);
    }
    const SelectItem = forwardRef(({label, description, ...others }, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <Text size="sm">{label}</Text>
                    <Text size="xs" color={"dimmed"}>
                        {description}
                    </Text>
                </div>
            </Group>
        </div>
    ));

    return <>
        {addVariable && <Grid.Col span={12}>
            <Group position={"apart"}>
                <Group position={"left"}>
                    <BoldText text={label} size={"md"} />
                </Group>
                <Group position={'right'}>
                    <Button variant={'gradient'} compact onClick={addVar}>Add Variable</Button>
                </Group>
            </Group>
        </Grid.Col>}
        {variables?.map((a,i) =>{
            return <>
                <Grid.Col span={3}>
                    {a?.required  === true ?
                        <Text weight={"bold"}>{a?.label?.toUpperCase()}</Text> :
                        <TextInput size={"xs"} value={a?.label} onChange={(e) => changeVarLabel(i, e.target.value)} /> }
                </Grid.Col>
                <Grid.Col span={3}>
                    <SegmentedControl size={"xs"} value={a?.type} data={[{label: "Fixed", value: "fixed"},{label: "Map From Response", value: "map"}]} onChange={(type) => changeVarType(i, type)} />
                </Grid.Col>
                <Grid.Col span={5}>
                    {a?.type  === 'fixed' ?
                        <TextInput size={"xs"} value={a?.value} onChange={(e) => changeVarValue(i, e.target.value)} /> :
                        <Select searchable
                                itemComponent={SelectItem}
                                size={"xs"}
                                data={webhookList || []}
                                value={a?.value}
                                nothingFound={"No Results Found"}
                                filter={(value, item) =>  item?.label?.toString()?.toLowerCase().includes(value?.toLowerCase()?.trim()) ||
                                    item?.description?.toString()?.toLowerCase().includes(value?.toLowerCase()?.trim())}
                                onChange={(value) => changeVarValue(i, value)} />}
                </Grid.Col>
                <Grid.Col span={1}>
                    {!a?.required && <IconView iconType={"delete"} label={"Delete Variable"} onClick={() => deleteVar(i)} />}
                </Grid.Col>
            </>
        })}
    </>
}

export default VariablesView;

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import  {ReactFlow,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge, applyNodeChanges, applyEdgeChanges, Position, Handle, Panel, ReactFlowProvider,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import {
    Grid,
    Paper,
    Textarea,
    Text,
    Group,
    Button,
    Menu,
    Modal,
    Divider,
    Stack,
    List,
    TextInput,
    SegmentedControl, Badge, Select, Image
} from "@mantine/core";
import {
    IconBookmark,
    IconDots,
    IconDotsVertical,
    IconEdit, IconFile, IconMusic,
    IconPhoto,
    IconPlus,
    IconTrash,
    IconVideo
} from "@tabler/icons";
import MessageOptions from "../Schedule/MessageOptions";
import MessageView from "../Components/MessageView";
import {forwardRef, useImperativeHandle} from "react";
import CustomEdge from "./CustomEdge";
import ButtonNode from "./CustomNodes/ButtonNode";
import ListNode from "./CustomNodes/ListNode";
import TemplateNode from "./CustomNodes/TemplateNode";
import MessageNode from "./CustomNodes/MessageNode";
import {IconView, IconWithText} from "../Components/PageComponents";
import {getRandomString} from "../../Constants/Functions";

const SendMessageNode = ({ data,isConnectable,id ,...props}) => {

    const messageRender = (message) =>{
        // const {message,image,header,body,footer,editOptions,headerText,bodyText,footerText} = message;
        console.log("JK122121", message);

        let header = [];
        let body = [];
        let footer = [];
        let buttons = [];
        let sections = [];

        if(message?.header) header.push(message?.header);
        if(message?.headerText) header.push(message?.headerText);
        if(message?.body) body.push(message?.body);
        if(message?.bodyText) body.push(message?.bodyText);
        if(message?.message) body.push(message?.message);
        if(message?.footer) footer.push(message?.footer);
        if(message?.footerText) footer.push(message?.footerText);
        if(message?.buttons) buttons.push(...message?.buttons);
        if(message?.urlButton?.text) buttons.push({title: message?.urlButton?.text});
        if(message?.list) sections = message?.list;
        let handles = [
            {type: "target", position: Position.Left,id: getRandomString(5)},
        ]
        if(buttons?.length === 0 && sections?.length === 0){
            handles.push({type: "source", position: Position.Right,id: getRandomString(5), style: {background: "green"}});
        }
        if(buttons?.length > 0){
            let position = [80,55,30];
            buttons?.map((a,i) => {
                handles.push({type: "source", position: Position.Bottom,id: getRandomString(5), style: {background: "green", bottom: position?.[2 - i],left: 200}});
            })
        }
        return {header,body,footer,buttons,sections,handles};
    }
    const SaveMessage = () =>{
        const {isValid,message} = messageRef.current.isValid();
        if(isValid){
            const msgRender = messageRender(message);
            data?.onChange({message: {...message,msgRender},id})
            setOpen();
        }
    }
    const messageRef = useRef();
    const [open,setOpen] = useState();
    const [openMenu,setOpenMenu] = useState(false);
    const onEdit = (data) =>{
        setOpen(data);
        setOpenMenu(false)
    }
    const onDelete = (id) =>{
        data?.onDelete(id);
        setOpenMenu(false);
    }

    return (
        <>
            {openMenu && <Paper shadow={"md"} sx={{width: 180, backgroundColor: "#ccc"}} ml={20} withBorder>
                <Stack sx={{gap: 3}}>
                    <IconWithText onClick={() => onEdit(data)} icon={<IconEdit size={"14"} color={"red"} />} text={"Edit"} textProps={{size: "xs"}} />
                    <IconWithText onClick={() => onDelete(id)} icon={<IconTrash size={"14"} color={"red"} />} text={"Delete"} textProps={{size: "xs"}} />
                </Stack>
            </Paper>}
            <Paper shadow={"md"} withBorder px={2} pb={2} sx={{backgroundColor: "lightblue", width: 200}}>
                <Group position={"apart"} m={3}>
                    <Text size={"xs"} weight={"bold"}>Send Message</Text>
                    <IconView icon={<IconDotsVertical size={"14"} />} onClick={() => setOpenMenu(!openMenu)} />
                </Group>
                <Divider />
                <Stack sx={{gap:3, backgroundColor: "white"}} mb={10}>
                    {data?.message ? <MessageView details={data?.message?.msgRender} /> :<Text variant={"gradient"} sx={{textDecoration: "underline"}} onClick={() => setOpen({})} size={"xs"} align={"center"} my={20}>+ Add Message</Text>}
                    <Modal opened={open != undefined} size={"80%"} title={<b>Message To Send</b>} onClose={() => setOpen()}>
                        <Grid>
                            <MessageOptions ref={messageRef} details={open?.message?.editOptions} />
                            <Grid.Col span={12}>
                                <Group position={"center"}>
                                    <Button onClick={SaveMessage}>Save Message</Button>
                                </Group>
                            </Grid.Col>
                        </Grid>
                    </Modal>
                </Stack>
                {data?.message?.msgRender?.handles?.map(a =>{
                    return <Handle {...a} />
                })}

                <Handle type="target" position={Position.Bottom}  style={{ background: 'red' }} />
                {/*<Handle type="source" position={Position.Bottom} id="b" style={{background: 'green',bottom: 30, left: 200}} title={"Source"} />*/}
                {/*<Handle type="source" position={Position.Bottom} id="1" style={{background: 'green',bottom: 55, left: 200}} title={"Source"} />*/}
                {/*/!*<Handle type="source" position={Position.Bottom}  id="2" style={{background: 'green',bottom: 80, left: 200}} title={"Source"} />*!/*/}
                {/*<Handle type="source" position={Position.Bottom}  id="3" style={{background: 'green',bottom: 115, left: 200}} title={"Source"} />*/}
                {/*<Handle type="source" position={Position.Bottom}  id="4" style={{background: 'green',bottom: 140, left: 200}} title={"Source"} />*/}
            </Paper>
        </>
    );
}


const nodeTypes = {button: ButtonNode,list: ListNode,message: MessageNode,template: TemplateNode };

const SequenceBuilder =  forwardRef((props, ref) => {

    const [nodes, setNodes, onNodesChange] = useNodesState( []);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [startNode, setStartNode] = useState();
    const [render,setRender] = useState(0);

    useEffect(() =>{
        setNodes(props?.details?.nodes?.map(a => ({...a, data: {...a?.data,onChange: onChangeValues, onDelete: onDeleteValues,onStartNodeChange}})));
        setEdges(props?.details?.edges || []);
        setStartNode(props?.details?.nodes?.find(a => a?.data?.startNode === true)?.id);
        setRender(render + 1);
    },[props?.details]);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge({ ...params, type: 'custom', data: { onDelete: handleDeleteEdge } }, eds)),
        [],
    );
    const handleDeleteEdge = (id) => {
        setEdges((els) => els.filter((el) => el.id !== id));
    };
    const onChangeValues = useCallback( (props) =>{
        setNodes(nodes => nodes?.map(a =>  a?.id === props?.id ? {...a, data: {...a?.data, ...props}} : a));
        setRender(render+1)
    },[]);
    const onDeleteValues = useCallback( (props) =>{
        setNodes(nodes => nodes?.filter(a => a?.id !== props));
    },[]);
    const onStartNodeChange =  useCallback( (startNode) =>{
        setNodes(nodes => nodes?.map(a => ({...a,data: {...a?.data,startNode: a?.id === startNode}})));
        setStartNode(startNode);
        setRender(render+1)
    },[]);

    useImperativeHandle(ref, () => ({
        addNode: (nodeType) =>{
            const id = getRandomString(5);
            setNodes([...nodes,{
                id,
                type: nodeType,
                position: { x: 0, y:0 },
                data: {id,onChange: onChangeValues,onDelete: onDeleteValues,onStartNodeChange},

            }]);
            setRender(render+1);
        },
        onSave: () =>{
            return {nodes,edges,startNode}
        }
    }));

    return  <div style={{height: "75vh" }}>
                <ReactFlowProvider>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        nodeTypes={nodeTypes}
                        edgeTypes={{ custom: CustomEdge }}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        fitView
                    >
                            <Controls />
                            <MiniMap />
                            <Background variant="dots" gap={12} size={1} />
                    </ReactFlow>
                </ReactFlowProvider>
            </div>
});

export default SequenceBuilder;

import React, {useState} from "react";
import {Button, Grid, Group, Paper, Text, TextInput} from "@mantine/core";
import {IconChevronDown, IconChevronUp} from "@tabler/icons";
import {IconView} from "../Components/PageComponents";
const ResponseView = ({response,onRefresh}) =>{
    const [open,setOpen] = useState(false);

    return <Paper shadow={'md'} withBorder p={5} style={{width: "100%"}}>
        <Group position={"apart"}  onClick={() => setOpen(!open)}>
            <Text weight={"bold"} size={"md"} variant={"gradient"}>Sample Response</Text>
            <Group>
                <IconView icon={open ? <IconChevronUp /> : <IconChevronDown />} />
            </Group>
        </Group>
        {open && <Grid mt={10}>
            {Object.keys(response)?.map(a => {
                return <>
                    <Grid.Col span={6}>
                        <TextInput variant={"filled"}  size={"xs"} sx={{backgroundColor: "#ccc"}} value={a}
                                                  contentEditable={"inline"}/>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <TextInput  variant={"filled"} size={"xs"} sx={{backgroundColor: "#ccc"}} value={response?.[a]}
                                                  contentEditable={"inline"}/>
                    </Grid.Col>
                </>
            })}
        </Grid>}
    </Paper>
}
export default ResponseView;

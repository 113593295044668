var CryptoJS = require("crypto-js");

export const R365Url = 'https://r365server.businesssalesmachine.com/';
export const socketUrl = 'https://coach.businesssalesmachine.com:3002';
export const affiliateUrl = 'https://affiliate.businesssalesmachine.com:3002';
export const waSocketUrl = 'https://whatsapp.businesssalesmachine.com:81';
export const WBAUrl = 'https://wba.businesssalesmachine.com:3001/';
export const CloudUrl = 'https://wba1.businesssalesmachine.com:3005/';
// export const WBAUrl = 'http://localhost:3000/';
// export const CloudUrl = 'http://localhost:3004/';
// export const R365Url = 'http://localhost/';


export const CloudApi = {
    login: `${CloudUrl}v1/login`,
    connectDevice: `${CloudUrl}v1/connect-device`,
    deviceList: `${CloudUrl}v1/device-list`,

    deviceDetails: `${CloudUrl}v1/device-details`,
    deviceDashboard: `${CloudUrl}v1/device-dashboard`,
    msgStats: `${CloudUrl}v1/user-msg-count`,
    deviceHealth: `${CloudUrl}v1/health-refresh`,
    businessProfile: `${CloudUrl}v1/device/profile`,
    deviceOperations: `${CloudUrl}v1/device/operations`,
    disconnectWhatsapp: `${CloudUrl}v1/device/disconnect`,
    UserProfile: `${CloudUrl}v1/user-profile`,

    whatsappFlows: `${CloudUrl}v1/flows`,
    downloadFlowLeads: `${CloudUrl}v1/flows/leads`,

    templateCreate: `${CloudUrl}v1/templates/create`,
    templateList: `${CloudUrl}v1/templates/list`,
    templateDelete: `${CloudUrl}v1/templates/delete`,
    templateSync: `${CloudUrl}v1/templates/sync`,
    templateDetails: `${CloudUrl}v1/templates/details`,
    templateRawDetails: `${CloudUrl}v1/templates/raw-details`,

    sendMessageSingle: `${CloudUrl}v1/message/single`,
    ResendMessage: `${CloudUrl}v1/message/resend`,
    ResendBulkMessage: `${CloudUrl}v1/message/bulk-resend`,

    InboxNumbers: `${CloudUrl}v1/inbox/numbers`,
    InboxMessages: `${CloudUrl}v1/inbox/messages`,
    UserStatus: `${CloudUrl}v1/inbox/user-status`,
    ChangeUserStatus: `${CloudUrl}v1/inbox/change-status`,

    CreateSchedule: `${CloudUrl}v1/schedule/create`,
    AllSchedules: `${CloudUrl}v1/schedule/list`,
    UpcomingSchedules: `${CloudUrl}v1/schedule/upcoming`,
    DateSchedules: `${CloudUrl}v1/schedule/date`,
    planSchDetails: `${CloudUrl}v1/schedule/plan-details`,

    deleteSchedule: `${CloudUrl}v1/schedule/delete`,
    pauseSchedule: `${CloudUrl}v1/schedule/pause`,
    resumeSchedule: `${CloudUrl}v1/schedule/resume`,
    ScheduleDetails: `${CloudUrl}v1/schedule/details`,

    logs: `${CloudUrl}v1/logs/list`,
    logDetails: `${CloudUrl}v1/logs/details`,
    logDownload: `${CloudUrl}v1/logs/download`,
    singleLogs: `${CloudUrl}v1/logs/single`,
    singleLogDetails: `${CloudUrl}v1/logs/single-details`,

    programs: `${CloudUrl}v1/programs`,
    programOptin: `${CloudUrl}v1/programs/optin`,
    programDeleteOptin: `${CloudUrl}v1/programs/optin-delete`,
    programAddDay: `${CloudUrl}v1/programs/day-add`,
    programCloneDay: `${CloudUrl}v1/programs/clone-add`,
    programDayMsg: `${CloudUrl}v1/programs/day-msg`,
    programSettings: `${CloudUrl}v1/programs/settings`,
    programMembers: `${CloudUrl}v1/programs/members`,
    programMsgFormat: `${CloudUrl}v1/programs/format-msg`,
    programSchedules: `${CloudUrl}v1/programs/schedules`,


    chatbot: `${CloudUrl}v1/chatbot`,
    notifications: `${CloudUrl}v1/notifications`,
    sequence: `${CloudUrl}v1/sequence`,
    messageList: `${CloudUrl}v1/message-list`,
    Attachments: `${CloudUrl}v1/attachments`,
    ContactFolders: `${CloudUrl}v1/contact-folders`,
    tracker: `${CloudUrl}v1/tracker`,
    CRM: `${CloudUrl}v1/crm`,
    CRMFormat: `${CloudUrl}v1/crm-format`,
    bsmFlows: `${CloudUrl}v1/bsm-flow`,
    convertkit: `${CloudUrl}v1/convertkit`,
    fbLeads: `${CloudUrl}v1/fb-leads`,
    bsmConnections: `${CloudUrl}v1/connections`,
    sequenceTrigger: `${CloudUrl}v1/trigger-sequence`,
    FB: `${CloudUrl}v1/fb`,


    downloadMembers: `${CloudUrl}public/download/members`,
    downloadChatbot: `${CloudUrl}public/download/chatbot`,
    downloadAllMsg: `${CloudUrl}public/download/all-msg`,
    downloadBtnClicks: `${CloudUrl}public/download/btn-click`,

    bsmFlowWebhook: `${CloudUrl}public/process-flow`,
    indSchedules: `${CloudUrl}v1/sch-stats`,

    QueueOptions: `${CloudUrl}v1/queue`,
    ReuploadMedia: `${CloudUrl}v1/media/reupload`,
    mediFileDetails: `${CloudUrl}v1/media/details`,
    mediaIdPreview: `${CloudUrl}v1/media/preview`,
    mediaIdDownload: `${CloudUrl}public/download/mediaId`,



}
export const AdminApi = {
    AdminUsers: `${CloudUrl}admin/users`,
    sessions: `${CloudUrl}admin/sessions`,
    sessionDetails: `${CloudUrl}admin/session-details`,
    sessionMsgStats: `${CloudUrl}admin/session-messages`,
    cpuStats: `${CloudUrl}admin/cpu-stats`,
    msgStats: `${CloudUrl}admin/msg-stats`,
    schStats: `${CloudUrl}v1/all-sch-stats`,
    formatPref: `${CloudUrl}v1/format-pref`,
    userPayments: `${CloudUrl}v1/user-payments`,
    payLinks: `${CloudUrl}v1/pay-links`,
    usersList: `${CloudUrl}admin/users-list`,
    deviceList: `${CloudUrl}admin/device-list`,
    createPay: `${CloudUrl}v1/create-pay`,
    PlanUpdates: `${CloudUrl}admin/plan-updates`,
}
export const R365Api = {
    books: `${CloudUrl}r365/books`,
    GetMessageStats: `${CloudUrl}r365/msg-stats`,
    bookTemplates:  `${CloudUrl}r365/book-templates`,
    bookTemplateDetails:  `${CloudUrl}r365/book-template-details`,
    UpdateBookTemplate:  `${CloudUrl}r365/update-book-template`,
    DeleteUpdateBookTemplate:  `${CloudUrl}r365/delete-book-template`,
    CreateBookTemplate:  `${CloudUrl}r365/create-book-template`,
    BulkBookUpload:  `${CloudUrl}r365/bulk-book-upload`,
    Customers:  `${CloudUrl}r365/customers`,
    CustomerDetails:  `${CloudUrl}r365/customer-details`,
    CustomerMessages:  `${CloudUrl}r365/customer-messages`,
    DeleteCustomerMessages:  `${CloudUrl}r365/delete-customer-messages`,
    ResendCustomerMessages:  `${CloudUrl}r365/resend-customer-messages`,
    DeleteBookTemplate:  `${CloudUrl}r365/delete-book-part`,
    SendMessages:  `${CloudUrl}r365/trigger-r365`,
    UpdateBooktoUser: `${CloudUrl}r365/update-books-user`,

    TestTemplate:  `${R365Url}dash/test-template`,



    //wba
    UpdateAddOn:  `${CloudUrl}r365/addons`,
    UpdateMessages:  `${CloudUrl}r365/messages`,
    UpdateSettings:  `${CloudUrl}r365/settings`,
    UpdateMisc:  `${CloudUrl}r365/misc`,
    blockList:  `${CloudUrl}r365/block`,
    stats:  `${CloudUrl}r365/stats`,
    R365Customers:  `${CloudUrl}r365/customers`,
    ButtonClicks:  `${CloudUrl}r365/btn-click`,
    UserActions:  `${CloudUrl}r365/user-actions`,

    triggerBooks:  `${CloudUrl}r365/book-trigger`,
}


export const WBAUrls = {
    msgDetails: `${WBAUrl}dash/msg-details`,
    deviceList: `${WBAUrl}dash/devices`,
    AddWbaDevice: `${WBAUrl}dash/add-wba-device`,
    DeleteWbaDevice: `${WBAUrl}dash/delete-wba-device`,
    syncTemplates:`${WBAUrl}dash/sync-templates`,
    approvedTemplates:  `${WBAUrl}dash/approved-templates`,
    approveTemplate:  `${WBAUrl}dash/approve-template`,
    TemplateDetails:  `${WBAUrl}dash/template-details`,
    messageLogs:  `${WBAUrl}dash/message-logs`,
    messageLogDetails:  `${WBAUrl}dash/log-details`,
    triggerChatbot:  `${WBAUrl}dash/trigger-chatbot`,
}
// export const socketUrl = 'http://localhost:3001';
// export const waSocketUrl = 'http://localhost';
// export const affiliateUrl = 'http://localhost:3002';
export const waUrls = {
    deleteSession: `${waSocketUrl}/client/delete-session`,
    activateConnection: `${waSocketUrl}/client/activate-connections`,
    UpdateAvgTime: `${waSocketUrl}/client/calculate-avg`,
    formatNumbersCount: `${waSocketUrl}/client/format-numbers-count`,
}
export const serverUrls = {
    deviceInfo: `${socketUrl}/api/device-info`,
    uploadImage: `${socketUrl}/api/upload-image`,
    uploadFile: `${socketUrl}/api/upload-file`,
    memberDetails: `${socketUrl}/api/member-details`,
    downloadLeads: `${socketUrl}/api/download-leads`,
    downloadMembers: `${socketUrl}/api/download-members`,
    downloadContacts: `${socketUrl}/api/download-contacts`,
    downloadChatbot: `${socketUrl}/api/download-chatbot`,
    downloadMeetings: `${socketUrl}/api/zoom-download`,
    templateDetails: `${socketUrl}/api/template-details`,
    connectionDetails: `${socketUrl}/api/connection`,
    downloadLog: `${socketUrl}/api/download-log`,
    calendlyLogin: `${socketUrl}/flow/calendly-login`,
    calendlyWHCreate: `${socketUrl}/flow/calendly-wh-create`,
    calendlyRefresh: `${socketUrl}/flow/calendly-refresh`,
    ckLogin: `${socketUrl}/flow/ck-login`,
    ckDetails: `${socketUrl}/flow/ck-details`,
    ckSubs: `${socketUrl}/flow/ck-subs`,
    fbForms: `${socketUrl}/flow/fb-forms`,
    fbPages: `${socketUrl}/flow/fb-pages`,
    fbListen: `${socketUrl}/flow/fb-listen`,
    WANumbers: `${socketUrl}/flow/wa-numbers`,
    WATemplates: `${socketUrl}/flow/wa-templates`,
}



export const RequestGet = async (url) =>{
    try{
    const data = await fetch(url)
    return data.json();
    }catch (err) {
        console.log(err)
    }
}
export const RequestPost = async (url,body, headers = {}) =>{
    try{
        const response = await fetch(url,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });
        return response.json();
    }catch (err) {
        console.log(err)
    }
}







const userDetails = () =>{
    const data = localStorage.getItem('user');
    if(data){
        var bytes  = CryptoJS.AES.decrypt(data, 'coachesMagaduka');
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }else{
        return undefined;
    }

}
export const isUserAuthenticated = () =>{
    const details = userDetails();
    return details ? true: false;
}
export const isAdminUser = () =>{
    const details = userDetails();
    return details?.isAdmin;
}
export const getUserDetails = () =>{
    const details = userDetails();
    return details;
}
export const userLogout = (navigation) =>{
    localStorage.removeItem('user');
    navigation('/');
}

import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {ActionShowAlert} from "../../store/actions/alert";
import {Button, Group, Modal, TextInput} from "@mantine/core";
import {BoldText, ErrorText} from "../Components/TextComponents";
import {FilledTextInput} from "../Sequence/CustomButton";

const CreateFlow = ({details,onClose,onRefresh}) =>{
    const {sessionId,userId} = useSelector(s => s.active);

    const [title,setTitle] = useState(details?.title || "");
    const [flowError,setFlowError] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const createFlow = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.bsmFlows}/${details?.id ? "updateTitle": "create"}/${sessionId}`,{
            title: title?.trim()?.toUpperCase(),
            userId,
            id: details?.id,
            type: "NODE"
        });
        setIsLoading(false);
        if(data?.status === 1){
            dispatch(ActionShowAlert({message: data?.message}));
            onClose();
            onRefresh()
        }else{
            setFlowError(data?.message)
        }

    }

    return <>
        <Modal title={<BoldText text={details?.id? "Edit Flow" : `Create Flow`} />} opened={true} onClose={onClose}>
            <FilledTextInput required label={"Flow Name"} value={title} onChange={setTitle} />
            {flowError && <ErrorText text={flowError} />}
            <Group position={'center'} my={20}>
                <Button disabled={title?.trim() === ""} onClick={createFlow} loading={isLoading} variant={'gradient'}>{details?.id? "Update Flow" : `Create Flow`}</Button>
            </Group>
        </Modal>
    </>

}
export default CreateFlow;
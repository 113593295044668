import {useSelector} from "react-redux";
import Dashboard from "../Pages/Dashboard/Dashboard";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import DeviceList from "../Pages/Device/DeviceList";
import ExpiryPage from "../Pages/Alerts/ExpiryPage";
import AddWBA from "../Pages/Device/AddWBA";
import React from "react";
import Login from "../Pages/Dashboard/Login";
import ApprovedTemplates from "../Pages/Templates/ApprovedTemplates";
import Inbox from "../Pages/Inbox/Inbox";
import CreateMessage from "../Pages/Schedule/CreateMessage";
import ScheduleList from "../Pages/Schedule/ScheduleList";
import MessageLogs from "../Pages/Logs/MessageLogs";
import Programs from "../Pages/Programs/Programs";
import ProgramDetails from "../Pages/Programs/ProgramDetails";
import AddBulkMembers from "../Pages/Programs/AddBulkMembers";
import Forms from "../Pages/Forms/Forms";
import FormUpdate from "../Pages/Forms/FormUpdate";
import Chatbot from "../Pages/ChatBot/ChatBot";
import ChatbotLeads from "../Pages/ChatBot/ChatbotLeads";
import Sequence from "../Pages/Sequence/Sequence";
import SequenceEdit from "../Pages/Sequence/SequenceEdit";
import SequenceLeads from "../Pages/Sequence/SequenceLeads";
import {NotConnected, NotFound} from "../Pages/Alerts/404";
import FlowList from "../Pages/BSMFlow/FlowList";
import FlowLogs from "../Pages/BSMFlow/FlowLogs";
import Books from "../Read365/Books/Books";
import Customers from "../Read365/Customers/Cusotmers";
import CustomerDetails from "../Read365/Customers/CustomerDetails";
import Actions from "../Read365/Misc/Actions";
import MessageList from "../Pages/MessageList/MessageList";
import R365Dashboard from "../Read365/R365Dashboard";
import R365UserStats from "../Read365/Stats/R365UserStats";
import Folders from "../Pages/Attachments/Folders";
import CustomerMessages from "../Read365/Customers/CustomerMessages";
import ContactFolders from "../Pages/ContactFolder/ContactFolder";
import CRMList from "../Pages/CRM/CRMList";
import EditFlow from "../Pages/Flows/EditFlow";
import BSMFlowEdit from "../Pages/BSMFlow/CreateFlow";
import Tracker from "../Pages/Tracker";
import TrackerDetails from "../Pages/Tracker/details";
import Flows from "../Pages/Flows";
import FlowLogsNew from "../Pages/Flows/FlowLogs";
import Update from "../Pages/Dashboard/Update";


const paths =[
    {path: `/`,component: <Dashboard />},
    {path: `/templates`,component: <ApprovedTemplates />},
    {path: `/inbox`,component: <Inbox />},
    {path: `/schedule-message`,component: <CreateMessage />},
    {path: `/schedules`,component: <ScheduleList />},
    {path: `/logs`,component: <MessageLogs />},

    {path: `/forms`,component: <Forms />},
    {path: `/form-edit`,component: <FormUpdate />},

    {path: `/chatbots`,component: <Chatbot />},
    {path: `/chat-leads`,component: <ChatbotLeads />},

    {path: `/sequence`,component: <Sequence />},
    {path: `/sequence-edit/:id`,component: <SequenceEdit />},
    {path: `/sequence-leads/:id`,component: <SequenceLeads />},

    {path: `/message-list`,component: <MessageList />},
    // {path: `/attachments`,component: <Attachments />},

    {path: `/programs`,component: <Programs />},
    {path: `/programs/view`,component: <ProgramDetails />},
    {path: `/programs/add-members`,component: <AddBulkMembers />},

    {path: `/bsm-flow`,component: <FlowList />},
    {path: `/bsm-flow/edit/:id`,component: <BSMFlowEdit />},
    {path: `/bsm-flow/logs/:id`,component: <FlowLogs />},

    {path: `/flows`,component: <Flows />},
    {path: `/flows/edit/:id`,component: <EditFlow />},
    {path: `/flows/logs/:id`,component: <FlowLogsNew />},

    {path: `/attachments`,component: <Folders />},
    {path: `/contact-folders`,component: <ContactFolders />},
    {path: `/crm`,component: <CRMList />},
    {path: `/tracker`,component: <Tracker />},
    {path: `/tracker-details/:id`,component: <TrackerDetails />},
];
const r365Paths = [
    {path: `/r365`,component: <R365Dashboard />},
    {path: `/r365/books`,component: <Books />},
    {path: `/r365/customers`,component: <Customers />},
    {path: `/r365/customer-details/:mobile`,component: <CustomerDetails />},
    {path: `/r365/actions`,component: <Actions />},
    {path: `/r365/stats`,component: <R365UserStats />},
    {path: `/r365/messages`,component: <CustomerMessages />},
]


const Navigator = () =>{
    const {sessionId,alias, r365} = useSelector(state => state.active);
    return  <Routes>
        <Route exact path="/login" element={<PublicOutlet />}>
            <Route path="" element={<Login />}/>
        </Route>
        <Route exact path={`/`} element={<PrivateOutlet />}>
            <Route path="" element={<DeviceList />} />
        </Route>
        {paths.map(path =><Route exact path={`/device/${alias}${path?.path}`} element={path?.path === "/" ? <PrivateOutlet />: <SessionOutlet />}>
            <Route path="" element={path?.component} />
        </Route>)}
        {r365?.productId && r365Paths.map(path =><Route exact path={`/device/${alias}${path?.path}`} element={path?.path === "/" ? <PrivateOutlet />: <SessionOutlet />}>
            <Route path="" element={path?.component} />
        </Route>)}
        <Route path="/update" element={<Update />}/>
        <Route path="/not-connected" element={<NotConnected />}/>
        <Route path="/expiry" element={<ExpiryPage />}/>
        <Route path="/facebook" element={<AddWBA />}/>
        <Route path="/facebook/:type" element={<AddWBA />}/>
        <Route path="*" element={<NotFound />}/>
    </Routes>
}
function SessionOutlet() {
    const {isLogged} = useSelector(state => state.user);
    const {userId,sessionId,connected,alias} = useSelector(state => state.active);
    return  isLogged && connected?.whatsappId ? <Outlet /> : isLogged ? <Navigate to={`/not-connected`} />: <Navigate to="/login" />
}
function PrivateOutlet() {
    const {isLogged,userId} = useSelector(state => state.user);
    return isLogged && userId ? <Outlet /> : <Navigate to="/login" />;
}
function PublicOutlet() {
    const {isLogged,userId} = useSelector(state => state.user);
    return isLogged && userId ? <Navigate to={'/'} /> : <Outlet />;
}

export default Navigator;

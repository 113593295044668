import React, {useEffect, useRef, useState} from "react";
import {Select, Group, Button, Stack, Paper, Text, Grid, MultiSelect, NumberInput, Badge, Divider} from "@mantine/core";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {CheckBoxView, PageLoading, SwitchView} from "../Components/PageComponents";
import {Colors} from "../../Constants/Colors";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {IconEdit, IconRefresh, IconSquare, IconSquareCheck} from "@tabler/icons";
import moment from "moment";
import {DatePicker} from "@mantine/dates";
import {WeekDays} from "../../Constants/Strings";
import AssignTagsCRM from "../Components/AssignTagsCRM";
import {BoldText} from "../Components/TextComponents";

const ProgramSettings = ({programId}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [settings,setSettings] = useState();
    const dispatch = useDispatch();
    useEffect(()=>{
        getDetails();
    },[]);
    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.programs}/details/${sessionId}`,{type: "settings",programId});
        setSettings(data?.settings || {});
        setIsLoading(false);
    }
    const onUpdate = async (body) =>{
       const data =  await RequestPost(`${CloudApi.programSettings}/${sessionId}/${programId}`,body);
        dispatch(ActionHidePopup());
        dispatch(ActionShowAlert({message: data?.message}));
        getDetails();
    }
    return <>
        <Group mb={10} position={"right"}>
            <Button leftIcon={<IconRefresh />} onClick={getDetails} compact>Refresh</Button>
        </Group>
        <PageLoading isLoading={isLoading}>
            <Grid>
                <Grid.Col span={6}> <FirstMessage settings={settings} onUpdate={onUpdate} /> </Grid.Col>
                <Grid.Col span={6}> <MemberUpdateType settings={settings} onUpdate={onUpdate} /> </Grid.Col>
                <Grid.Col span={6}> <DisableDuplicate settings={settings} onUpdate={onUpdate} /> </Grid.Col>
                <Grid.Col span={6}> <ProgramTags settings={settings} onUpdate={onUpdate} /> </Grid.Col>
                <Grid.Col span={6}> <DuplicateType settings={settings} onUpdate={onUpdate} /> </Grid.Col>
                <Grid.Col span={6}> <MoveMembersToComplete settings={settings} onUpdate={onUpdate} /> </Grid.Col>
                <Grid.Col span={6}> <ProgramComplete settings={settings} onUpdate={onUpdate} /> </Grid.Col>
                <Grid.Col span={6}> <AddTagsInCRM settings={settings} onUpdate={onUpdate} /> </Grid.Col>

            </Grid>
        </PageLoading>
    </>
}
export default ProgramSettings;

const MemberUpdateType = ({settings,onUpdate}) =>{
    const [isEdit,setIsEdit] = useState(false);
    const [memberType,setMemberType] = useState(settings?.memberUpdate || "Normal");
    const dispatch = useDispatch();
    const onEdit = () =>{
      setIsEdit(!isEdit);
    }
    const onSubmit = () =>{
        if(settings?.memberUpdate !== memberType){
            dispatch(ActionShowPopup({
                title: "Change Program Member Update Type",
                content: memberType === "Normal" ? "It will Update Verified Members Days Daily. Are you sure want to Change ?" : "It will Update All Members Days Daily. Are you sure want to Change ?",
                successTitle: "Change",
                cancelTitle: "Close",
                onSuccess: () => onUpdate({memberUpdate: memberType,type: "memberUpdate"})
            }))
        }else{
            setIsEdit(false);
        }
    }
    const memberUpdateType = [
        {value: "Normal", label: "Update Days For Verified"},
        {value: "Daily", label: "Update Days For All"},
    ];

    return  <Paper shadow={'mx'} withBorder p={5} sx={{backgroundColor: Colors.Primary}}>
        <Group position={'apart'} sx={{cursor: "pointer"}} onClick={onEdit}>
            <Text  weight={'bold'} mx={5} color={Colors.Secondary}>Members Day Update Type : </Text>
            <Group sx={{cursor: "pointer"}}>
                {/*<Text weight={'bold'} color={Colors.Secondary}>{details?.memberUpdate === "Assignment" ? "Assignment": SelectData?.memberUpdateType?.find(a => a.value === (details?.memberUpdate || "Default"))?.label} </Text>*/}
                <Text weight={'bold'} color={Colors.Secondary}>{settings?.memberUpdate || "Normal"}</Text>
                <IconEdit color={Colors.Secondary} />
            </Group>
        </Group>
        {isEdit && <Group sx={{backgroundColor: Colors.Secondary}} p={10} mt={10} >
            <Select
                size={"xs"}
                label={"Member Type"}
                value={memberType}
                onChange={setMemberType}
                data={memberUpdateType}
            />
            <Group position={'center'} mt={15}>
                <Button size={"xs"} onClick={onSubmit}>Update</Button>
                <Button size={"xs"} sx={{backgroundColor: '#ccc'}} onClick={() =>setIsEdit(false)}>Cancel</Button>
            </Group>
        </Group>}
    </Paper>
}
const DisableDuplicate = ({settings,onUpdate}) =>{
    const dispatch = useDispatch();
    const AlertStrings = {
        disable: {
            title: "Disable Duplicate",
            content:"It will Stop Adding Duplicate Numbers into Program.",
            successTitle: "Disable",
            cancelTitle: "Close",
        },
        enable: {
            title: "Enable Duplicate",
            content:"It will Adding Duplicate Numbers also into Program.",
            successTitle: "Enable",
            cancelTitle: "Close",
        },
    }
    const onChange = () =>{
        const memberAlert = settings?.disableDuplicate ?  AlertStrings?.enable:  AlertStrings?.disable;
        dispatch(ActionShowPopup({
            ...memberAlert,
            onSuccess: () => onUpdate( {disableDuplicate: !settings?.disableDuplicate, type: "disableDuplicate"}),
        }))
    }

    return  <Paper shadow={'mx'} withBorder p={5} sx={{backgroundColor: Colors.Primary}}>
        <SwitchView isSelected={settings?.disableDuplicate} text={"Don't Store Duplicate members"} onClick={onChange} onEdit={onChange} />
    </Paper>
}
const ProgramTags = ({settings,onUpdate}) =>{
    const [isEdit,setIsEdit] = useState(false);
    const [tags,setTags] = useState(settings?.tags?.[0] || "General");
    const [allTags,setAllTags] = useState(settings?.usedTags || []);
    const [tagsList,setTagsList] = useState(settings?.usedTags?.map(a => ({value: a,label: a})) || []);
    const dispatch = useDispatch();
    const onEdit = () =>{
        setIsEdit(!isEdit);
    }
    const onSubmit = () =>{
        dispatch(ActionShowPopup({
            title: "Enable Members Tags",
            content:"It will add Tags when Members Adding.You can Filter / Send Message Based on This Tags. Here After When New Users Added This tag will add",
            successTitle: "Change Tags",
            cancelTitle: "Close",
            onSuccess: () => onUpdate({tags: [tags],reserved: allTags,type: "tags"})
        }))

    }

    return  <Paper shadow={'mx'} withBorder p={5} sx={{backgroundColor: Colors.Primary}}>
        <Group position={'apart'} sx={{cursor: "pointer"}} onClick={onEdit}>
            <Text  weight={'bold'} mx={5} color={Colors.Secondary}>Active Tag For Members: </Text>
            {!isEdit && <Group sx={{cursor: "pointer"}}>
                <Text weight={'bold'} color={Colors.Secondary}>{tags}</Text>
                <IconEdit color={Colors.Secondary}/>
            </Group>}
        </Group>
        {isEdit && <Stack sx={{backgroundColor: Colors.Secondary}} p={10} mt={10} >
            <Select label={"Active Tags"} data={tagsList} creatable searchable
                    value={tags}
                    onChange={setTags}
                    getCreateLabel={(query) => `+ Create ${query}`}
                    onCreate={(query) => {
                        const item = { value: query, label: query };
                        setTagsList((current) => [...current, item]);
                        setTags(query);
                        return item;
                    }}
            />
            <MultiSelect label={"Reserved Tags"} data={tagsList} creatable searchable
                         value={allTags}
                         onChange={setAllTags}
                         getCreateLabel={(query) => `+ Create ${query}`}
                         onCreate={(query) => {
                             const item = { value: query, label: query };
                             setTagsList((current) => [...current, item]);
                             return item;
                         }}
            />
            <Group position={'center'} mb={10}>
                <Button onClick={onSubmit}>Update</Button>
                <Button sx={{backgroundColor: '#ccc'}} onClick={() =>setIsEdit(false)}>Cancel</Button>
            </Group>
        </Stack>}
    </Paper>
}
const DuplicateType = ({settings,onUpdate}) =>{
    const [isEdit,setIsEdit] = useState(false);
    const [duplicateType,setDuplicateType] = useState(settings?.duplicateType || "numbers");
    const dispatch = useDispatch();
    const onEdit = () =>{
        setIsEdit(!isEdit);
    }
    const AlertStrings = {
        tags: {
            title: "Duplicate Members Based On Tags & Numbers",
            content:"If User Number Already Exists in Program It will Consider as Duplicate & Optin Messages Will Not Send",
            successTitle: "Update Type",
            cancelTitle: "Close",
        },
        numbers: {
            title: "Duplicate Members Based On Numbers Only",
            content:"If User Number Already Exists in Program & Tags.Then Only It will Consider as Duplicate & Optin Messages Will Not Send",
            successTitle: "Update Type",
            cancelTitle: "Close",
        },
    }

    const onSubmit = () =>{
        if(duplicateType === settings?.duplicateType){
            setIsEdit(false);
        }else{
            dispatch(ActionShowPopup({
                ...AlertStrings?.[duplicateType],
                onSuccess: () => onUpdate({type: "duplicateType",duplicateType}),
            }));
        }
    }

    return  <Paper shadow={'mx'} withBorder p={5} sx={{backgroundColor: Colors.Primary}}>
        <Group position={'apart'} sx={{cursor: "pointer"}} onClick={onEdit}>
            <Text  weight={'bold'} mx={5} color={Colors.Secondary}>Duplicate Members Based on: </Text>
            {!isEdit && <Group sx={{cursor: "pointer"}}>
                <Text weight={'bold'} color={Colors.Secondary}>{settings?.duplicateType === "tags" ? "Tags & Numbers": "Numbers"}</Text>
                <IconEdit color={Colors.Secondary}/>
            </Group>}
        </Group>
        {isEdit && <Stack sx={{backgroundColor: Colors.Secondary}} p={10} mt={10} >
            <Select
                data={[{label: "Tags & Numbers", value: "tags"},{label: "Numbers", value: "numbers"}]}
                value={duplicateType}
                onChange={setDuplicateType}
            />
            {duplicateType === 'numbers'? <Text>If user <b>number already exists</b> in program , it will consider as <b>Duplicate & Optin messages will not be sent</b></Text>:<Text> if user number already exists in program but under <b>different tag</b> then it will <b>not consider as duplicate & optin messages will be sent</b></Text>}
            <Group position={'center'} mb={10}>
                <Button onClick={onSubmit}>Update</Button>
                <Button sx={{backgroundColor: '#ccc'}} onClick={() =>setIsEdit(false)}>Cancel</Button>
            </Group>
        </Stack>}
    </Paper>
}
const ProgramComplete = ({settings,onUpdate}) =>{
    const [isEdit,setIsEdit] = useState(false);
    const [date,setDate] = useState( !!settings?.programComplete ? new Date(moment.unix(settings?.programComplete)): new Date());
    const [selected, setSelected] = useState(!!settings?.programComplete);
    const dispatch = useDispatch();
    const AlertStrings = {
        disable: {
            title: "Complete Program After Specific Date",
            content:"Are you sure want to Disable this feature ?",
            successTitle: "Disable",
            cancelTitle: "Close",
        },
        enable: {
            title: "Complete Program After Specific Date",
            content:"After Selected Date Program Will Be moved to Completed State. Are you sure?",
            successTitle: "Enable",
            cancelTitle: "Close",
        }
    }
    const onSubmit = () =>{
        dispatch(ActionShowPopup({
            ...(selected ? AlertStrings?.enable : AlertStrings.disable),
            onSuccess: () => onUpdate({type: "programComplete",programComplete: selected ? moment(date).endOf('day').unix(): 0})
        }))
    }
    return<Paper shadow={'mx'} withBorder p={5} sx={{backgroundColor: Colors.Primary}}>
        <Stack sx={{cursor: "pointer"}} onClick={() => setIsEdit(!isEdit)}>
            <SwitchView isSelected={!!settings?.programComplete} onEdit={() => setIsEdit(!isEdit)} text={`Program Completion Date: ${!!settings?.programComplete ? moment.unix(settings?.programComplete).format('ll'):""}`} onClick={() => setIsEdit(!isEdit)} />
        </Stack>
        {isEdit &&  <Stack sx={{backgroundColor: Colors.Secondary, gap: 1}} p={10} mt={10} >
            <Group sx={{gap: 1}}>
                <CheckBoxView isSelected={selected} text={"Complete Program After Specific Date"} onClick={setSelected} />
                {selected && <DatePicker mx={10} size={"xs"} label={"Complete Date"} value={date} onChange={setDate} minDate={new Date()} />}
                {selected && date &&  <Stack sx={{gap: 1}}>
                    <Text size={"sm"} weight={"bold"}>After {moment(date).format('ll')} 11:59 PM:</Text>
                    <Text size={"sm"} color={"dimmed"}>1. Day wise Messages Won't sent.</Text>
                    <Text size={"sm"} color={"dimmed"}>2. Members Days will Not Be updated.</Text>
                    <Text size={"sm"} color={"dimmed"}>3. Optin Messages Will Not Send.</Text>
                </Stack>}
            </Group>
            <Button onClick={onSubmit} my={10}>Update</Button>
        </Stack>}
    </Paper>
}
const AddTagsInCRM = ({settings,onUpdate}) =>{
    const [status,setStatus] = useState(settings?.crm?.status);
    const [isEdit,setIsEdit] = useState(false);
    const ref = useRef();

    const onSubmit = (e) =>{
        e.preventDefault();
        const isValid = ref?.current?.isValid();
        onUpdate({type: "tagsInCrm", crm: {status: true, ...(isValid?.data)}})
    }
    const onDisable = (status) =>{
        setStatus(status)
        if(status){
            setIsEdit(true);
        }else{
            onUpdate({type: "tagsInCrm", crm: {status: false}})
        }

    }

    return<Paper shadow={'mx'} withBorder p={5} sx={{backgroundColor: Colors.Primary}}>
        <Stack sx={{cursor: "pointer"}} onClick={() => setIsEdit(!isEdit)}>
            <SwitchView isSelected={status} onEdit={() => setIsEdit(!isEdit)} text={`Add Tags / Projects to CRM`} onClick={onDisable} />
        </Stack>
        {status && <Stack sx={{backgroundColor: Colors.Secondary, gap: 1, cursor: "pointer"}} p={10} mt={10}  onClick={() =>  !isEdit ? setIsEdit(true): {}}>
            {settings?.crm && !isEdit && <>
                <Group my={5}>
                    <BoldText text={`Tags: `}/>
                    {settings?.crm?.tags?.map(a => <Badge>{a}</Badge>)}
                </Group>
                <Divider />
                <Group my={5}>
                    <BoldText text={`Projects: `}/>
                    {settings?.crm?.projects?.map(a => <Badge>{a}</Badge>)}
                </Group>
            </>}
            {isEdit && <Grid>
                <AssignTagsCRM selected={settings?.crm} ref={ref} />
                <Grid.Col span={12}>
                    <Group >
                        <Button onClick={onSubmit} my={10}>Update</Button>
                    </Group>
                </Grid.Col>
            </Grid>}
        </Stack>}
    </Paper>
}
const MoveMembersToComplete = ({settings,onUpdate}) =>{
    const [isEdit,setIsEdit] = useState(false);
    const [days,setDays] = useState(settings?.memberComplete || 0);
    const [selected, setSelected] = useState(!!settings?.memberComplete);
    const [moveNow, setMove] = useState(false);
    const dispatch = useDispatch();
    const AlertStrings= {
        enable: {
            title: "Move Members to Completed State",
            content:"After Selected Days User will be moved to Completed State. & Daily Messages won't sent to them. Are you sure?",
            successTitle: "Update Days",
            cancelTitle: "Close",
        },
        disable: {
            title: "Complete Program After Specific Date",
            content:"Are you sure want to Disable this feature ?",
            successTitle: "Disable",
            cancelTitle: "Close",
        },
    }

    const onSubmit = () =>{
        dispatch(ActionShowPopup({
            ...(selected ? AlertStrings?.enable : AlertStrings.disable),
            onSuccess: () => onUpdate({type: "memberComplete",memberComplete: selected ? days : undefined,moveNow})
        }))
    }
    return<Paper shadow={'mx'} withBorder p={5} sx={{backgroundColor: Colors.Primary}}>
        <Group position={'apart'} sx={{cursor: "pointer"}} onClick={() => setIsEdit(!isEdit)}>
            <Text  weight={'bold'} mx={5} color={Colors.Secondary}>Move Members to Completed State: </Text>
            {!isEdit && <Group sx={{cursor: "pointer"}}>
                {!settings?.memberComplete ? <Text  weight={'bold'} color={Colors.Secondary}> Don't Move </Text>: <Text  weight={'bold'} color={Colors.Secondary}> After {settings?.memberComplete} Days </Text>}
                <IconEdit color={Colors.Secondary} />
            </Group>}
        </Group>
        {isEdit &&  <Stack sx={{backgroundColor: Colors.Secondary, gap: 1}} p={10} mt={10} >
            <Group sx={{gap: 1}}>
                <CheckBoxView isSelected={selected} text={`Move Members to Complete State ${selected ? "After": ""}`} onClick={setSelected} />
                {selected && <NumberInput size={'xs'} sx={{width: 50}} value={days} onChange={setDays} />}
                {selected && <Text mx={5}>Days</Text>}
            </Group>
            {selected && <Text size={'sm'} color={'dimmed'}>After {days} Days Members will Move To Completed State. Daily Messages Won't sent to them</Text>}
            {selected && <>
                <Group sx={{cursor:"pointer"}} onClick={() => setMove(!moveNow)}>
                    {moveNow ? <IconSquareCheck />: <IconSquare />}
                    <Stack sx={{gap: 1}}>
                        <Text size={"sm"}>Move Members to Completed Now</Text>
                        <Text size={"sm"} color={"dimmed"}>Default It will Be moved at 12AM</Text>
                    </Stack>
                </Group>
            </>}
            <Button onClick={onSubmit} my={10}>Update</Button>
        </Stack>}
    </Paper>
}
const FirstMessage = ({settings,onUpdate}) =>{
    const [isEdit,setIsEdit] = useState(false);
    const [firstDay,setFirstDay] = useState(settings?.firstDay?.map(a => a?.toString()) || []);
    const dispatch = useDispatch();
    const onEdit = () => setIsEdit(!isEdit);
    const AlertStrings = {
        disable :{
            title: "Disable First Day Message in Specific Day",
            content:"It will Send Message to the users.. The Next Day of Verification Done",
            successTitle: "Disable",
            cancelTitle: "Close",
        },
        enable :{
            title: "Enable First Day Message in Specific Day",
            content:"It will Send First Day message on Selected Days only.",
            successTitle: "Enable",
            cancelTitle: "Close",
        },
    }

    const onChange = () =>{
        if(settings?.firstDay?.length > 0 ){
            dispatch(ActionShowPopup({
                ...AlertStrings.disable,
                onSuccess: () =>{
                    onUpdate({type: "firstDay", firstDay: undefined});
                }}));
        }else{
            setIsEdit(!isEdit);
        }

    }
    const onSubmit = () =>{
        dispatch(ActionShowPopup({...AlertStrings.enable, onSuccess: () =>{
            onUpdate({type: "firstDay", firstDay: firstDay?.map(a => parseInt(a,10)).sort()});
         },}));
        setIsEdit(false);
    }

    return  <Paper shadow={'mx'} withBorder p={5} sx={{backgroundColor: Colors.Primary}}>
        <Stack>
            <SwitchView onEdit={onEdit} isSelected={settings?.firstDay?.length > 0 || isEdit} text={"Send First Message On Specific Day"} onClick={onChange} />
            {settings?.firstDay?.length > 0 &&<Group sx={{backgroundColor: Colors.Secondary,cursor: "pointer"}} onClick={onEdit}  px={10}>
                <Text size={12} color={'dimmed'}>Send First Message on: </Text>
                <Text size={15} weight={'bold'}>{settings?.firstDay?.map(a => WeekDays[a])?.join(',')}</Text>
                {!isEdit && <IconEdit size={15} />}
            </Group>}
        </Stack>
        {isEdit && <Stack sx={{backgroundColor: Colors.Secondary}} px={10} mt={10}>
            <MultiSelect label={'Send First Message on:'}
                         value={firstDay}
                         data={WeekDays.map((a,index) => ({label: a,value: index.toString()}))}
                         onChange={setFirstDay}/>
            <Group position={'center'} mb={10}>
                <Button onClick={onSubmit}>Update</Button>
                <Button sx={{backgroundColor: '#ccc'}} onClick={() =>setIsEdit(false)}>Cancel</Button>
            </Group>
        </Stack>}
    </Paper>
}

//FB,zooom Integration

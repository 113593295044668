import {Header, IconView, PageLoading, TableView} from "../../Pages/Components/PageComponents";
import {useEffect, useState} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {Button, Grid, Group, Modal, MultiSelect, Pagination, Select} from "@mantine/core";
import {FilledTextArea, FilledTextInput} from "../../Pages/Sequence/CustomButton";
import {DatePicker} from "@mantine/dates";
import {BoldText, ErrorText} from "../../Pages/Components/TextComponents";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {useDispatch} from "react-redux";
import moment from "moment";
import NotificationPage from "../../Pages/Notifications/NotificationPage";

const PageHeading = {
    title: "Notifications",
    subTitle: "Create /Edit Notifications Here",
    buttons: [{type: "refresh", title: "Refresh"},{type: "add", title: "Create"}]
}
const AdminNotifications = () =>{

    const [lists, setLists] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [details,setDetails] = useState();
    const [isEdit,setIsEdit] = useState();

    const [total,setTotal] = useState(0);
    const [page,setPage] = useState(1);
    const [search,setSearch] = useState("");
    const [searchTerm,setSearchTerm] = useState("");
    const dispatch = useDispatch();


    useEffect(() => {
        getNotifications({});
    },[])

    const getNotifications = async ({params= {}}) => {
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.notifications}/adminList/admin`,{
            page, searchTerm, ...params
        })
        setLists(data?.data);
        setTotal(data?.total);
        setIsLoading(false);
    }
    const handleDelete = (id) =>{
        dispatch(ActionShowPopup({
            title: "Delete Notification",
            content: "Are you sure want to Delete?",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () =>{
               const data =  await RequestPost(`${CloudApi.notifications}/delete/admin`,{id})
                if(data?.status === 1){
                    dispatch(ActionHidePopup());
                    dispatch(ActionHidePopup({message: data?.message}));
                    getNotifications({});
                }
            }
        }))
    }
    const onSearch = async () => {
        setSearchTerm(search);
        getNotifications({params: {searchTerm: search}});
    }

    return <>
            <Header {...PageHeading} buttonProps={[{onClick: getNotifications},{onClick: () => setIsCreate(true)}]} />
            {isCreate && <CreateNotification onRefresh={() => getNotifications({})} onClose={() => setIsCreate(false)}/>}
            {isEdit && <CreateNotification onRefresh={() => getNotifications({})} details={isEdit} isEdit={true} onClose={() => setIsEdit(false)}/>}
            {details && <NotificationPage data={details} onClose={() => setDetails(undefined)} />}
            <PageLoading isLoading={isLoading}>
                <Group position={"apart"}>
                    <Group>
                        <FilledTextInput placeholder={"search"} value={search} onChange={setSearch} />
                        <Button compact onClick={onSearch}>Search</Button>
                    </Group>
                    <BoldText text={`Total Results: ${total}`} />
                    <Pagination size={"xs"} total={Math.ceil(total)/10} page={page} onChange={setPage} />
                </Group>
                <TableView headers={["Title","Types","Type","Start Date","End Date", "options"]}>
                        {lists?.map(a => <tr>
                            <td>{a?.title}</td>
                            <td>{a?.types?.join(",")}</td>
                            <td>{a?.type}</td>
                            <td>{a?.startTime ? moment.unix(a?.startTime).format("ll"): ""}</td>
                            <td>{a?.endTime ? moment.unix(a?.endTime).format("ll"): ""}</td>
                            <td>
                                <Group>
                                    <IconView iconType={"view"} onClick={() => setDetails(a)} />
                                    <IconView iconType={"edit"}  onClick={() => setIsEdit(a)} />
                                    <IconView iconType={"delete"} onClick={() => handleDelete(a?._id)}  />
                                </Group>
                            </td>
                        </tr>)}
                    </TableView>
            </PageLoading>
        </>

}
export default AdminNotifications;

const CreateNotification = ({onClose, details, isEdit, onRefresh}) =>{
    const [title,setTitle] = useState(details?.title || "");
    const [content,setContent] = useState(details?.content ||"");
    const [image,setImage] = useState(details?.image ||"");
    const [types,setTypes] = useState(details?.types || ["WBA"]);
    const [buttons,setButtons] = useState(details?.buttons || []);
    const [type,setType] = useState(details?.type || "All");
    const [sessionIds,setSessionIds] = useState(details?.sessionIds?.join("\n") || "");
    const [startTime,setStartTime] = useState(details?.startTime ?  new Date(moment.unix(details?.startTime)): new Date());
    const [endTime,setEndTime] = useState(details?.endTime ? new Date(moment.unix(details?.endTime)): new Date(moment().add(30,"days")));
    const [loading,setLoading] = useState(false);
    const [isError,setIsError] = useState(false);
    const [error,setError] = useState(false);

    const handleCreate = async () => {

        if(title?.trim() !== "" && content?.trim() !== "" && types?.length > 0 && startTime && endTime && type && buttons?.filter(a => a?.label?.trim() === "" && a?.url?.trim() === "").length ===0){
            setIsError(false);
            setLoading(true);
            const data = await RequestPost(`${CloudApi.notifications}/${isEdit ? "edit": "create"}/admin`,{
                title,content,image,types,type,sessionIds,startTime,endTime, id:details?._id, buttons
            });
            if(data?.status === 1){
                onRefresh();
                onClose();
            }else{
                setError(data?.message);
            }
            setLoading(false);
        }else{
            setIsError(true)
        }
    }

    const AddButton = () => setButtons(buttons => [...buttons,{label: "", url: ""}])
    const DeleteButton = (index) => setButtons(buttons => buttons?.filter((a,i) => i !== index))
    const handleChange = (index,key,value) => setButtons(btn => btn?.map((a,i) => i === index ? {...a,[key]:value} : a))

    return <Modal opened={true} onClose={onClose} size={"90%"} title={<b>{isEdit ? "Edit": "Create"} Notifications</b>}>
            <Grid>
                <Grid.Col span={6}>
                    <FilledTextInput label={"Title"} value={title} onChange={setTitle} required error={isError && title?.trim() === "" ? "Title is Required": ""} />
                </Grid.Col>
                <Grid.Col span={6}>
                    <FilledTextInput label={"Image Url"} value={image} onChange={setImage} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <FilledTextArea label={"Content"} value={content} onChange={setContent} required error={isError && content?.trim() === "" ? "Content is Required": ""} />
                </Grid.Col>
                <Grid.Col span={4}>
                    <MultiSelect label={"Available For"} data={["BSM","WBA"]} value={types} onChange={setTypes} required error={isError && types?.length === 0 ? "Types is Required": ""} />
                </Grid.Col>
                <Grid.Col span={4}>
                    <Select label={"Send For"} data={["All","Active","Custom"]} value={type} onChange={setType} required />
                </Grid.Col>
                {type === "Custom" && <Grid.Col span={4}>
                    <FilledTextArea label={"SessionIds"} value={sessionIds} onChange={setSessionIds} required error={isError && sessionIds?.trim() === "" ? "SessionIds is Required": ""}  />
                </Grid.Col>}
                <Grid.Col span={4}>
                    <DatePicker label={"Start Date"} value={startTime} onChange={setStartTime}  error={isError && !startTime ? "Start date is Required": ""} />
                </Grid.Col>
                <Grid.Col span={4}>
                    <DatePicker label={"End Date"} value={endTime} onChange={setEndTime} error={isError && !endTime ? "End date is Required": ""}  />
                </Grid.Col>

                <Grid.Col span={12}>
                    <Button onClick={AddButton} disabled={buttons?.length >= 3}>Add Button</Button>
                </Grid.Col>
                {buttons?.map((a,i) => {
                    return  <Grid.Col span={6}>
                        <Group>
                            <FilledTextInput label={"Button Label"} value={a?.label} onChange={(value) => handleChange(i,"label",value) } required error={isError && a?.label?.trim() === "" ? "Label is Required": ""} />
                            <FilledTextInput label={"Button Link"} value={a?.url}  onChange={(value) => handleChange(i,"url",value) } required error={isError && a?.url?.trim() === "" ? "Url is Required": ""}/>
                            <IconView iconType={"delete"} onClick={() => DeleteButton(i)} />
                        </Group>
                    </Grid.Col>
                })}
                <Grid.Col span={12}>
                    {error && <ErrorText text={error} />}
                   <Group position={"center"}>
                       <Button loading={loading} onClick={handleCreate}>{isEdit ? "Update": "Create"} Notification</Button>
                   </Group>
                </Grid.Col>
            </Grid>
    </Modal>
}
import {useParams} from "react-router-dom";
import React, {useRef, useState} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CheckBoxView, Header, IconView, PageLoading, TableView} from "../Components/PageComponents";
import {
    Group,
    Paper,
    Tabs,
    Button,
    Stack,
    Grid,
    Modal,
    Divider,
    CopyButton,
    Badge,
    TextInput,
    Pagination, Text
} from "@mantine/core";
import {BoldText, SmallText} from "../Components/TextComponents";
import {
    IconCheck,
    IconChecks,
    IconCopy,
    IconEdit,
    IconEye,
    IconMessage,
    IconPlus,
    IconRefresh,
    IconX
} from "@tabler/icons";
import PreviewScheduleMessage from "../Components/PreviewScheduleMessage";
import {TimeArray} from "../../Constants/Strings";
import MessageOptions from "../Schedule/MessageOptions";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import AddUsers from "./AddUsers";
import moment from "moment";
import {DatePicker} from "@mantine/dates";
import {convertUnixToFormat} from "../../Constants/Functions";
import {Colors} from "../../Constants/Colors";

const PageHeading = {
    title: "Tracker Details",
    buttons: [
        {type: "refresh", title: "Refresh"},
    ]
}
const TrackerDetails = ()=>{
    const {id} = useParams();

    const {sessionId,alias} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.tracker}/details/${sessionId}`,{id});
        setDetails(data?.details);
        setIsLoading(false);
    }
    useEffect(() =>{
        getDetails();
    },[]);



    return <>
        <Header {...PageHeading} buttonProps={[{onClick: getDetails}]} />
        <PageLoading isLoading={isLoading}>
            <Paper shadow={"md"} withBorder p={5}>
                <Group position={"apart"}>
                    <BoldText text={details?.title} size={"md"} />
                </Group>
            </Paper>
            <Tabs defaultValue="messages" keepMounted={false}>
                <Tabs.List>
                    <Tabs.Tab value="messages">Messages</Tabs.Tab>
                    <Tabs.Tab value="users">Users</Tabs.Tab>
                    <Tabs.Tab value="leads">Leads</Tabs.Tab>
                    <Tabs.Tab value="schedules">Schedules</Tabs.Tab>
                    <Tabs.Tab value="settings">Settings</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="messages" pt="xs">
                    <Messages messageList={details?.messages} id={id} />
                </Tabs.Panel>
                <Tabs.Panel value="users" pt="xs">
                    <Users  id={id} />
                </Tabs.Panel>
                <Tabs.Panel value="settings" pt="xs">
                    <Settings  id={id} />
                </Tabs.Panel>
                <Tabs.Panel value="leads" pt="xs">
                    <Leads  id={id} />
                </Tabs.Panel>
                <Tabs.Panel value="schedules" pt="xs">
                    <Schedule  id={id} />
                </Tabs.Panel>
            </Tabs>
        </PageLoading>

    </>
}
export default TrackerDetails;

const Messages = ({id, messageList}) => {
    const [messages, setMessages] = useState(messageList || {});
    const [edit, setEdit] = useState();
    const [render, setRender] = useState(1);

    const {sessionId} = useSelector(state => state.active);
    const messageRef = useRef();
    const dispatch = useDispatch();

    const MessageView = ({label, id, message}) => {
        return <Paper shadow={"md"} withBorder p={5}>
            <Group position={"apart"}>
                <BoldText text={label} size={"md"} variant={"gradient"}/>
                <Group>
                    <IconView iconType={"edit"} onClick={() => setEdit({label, id, message})}/>
                    <IconView iconType={"delete"} onClick={() => handleDeleteMessage(id)}/>
                </Group>
            </Group>
            <Divider/>
            <PreviewScheduleMessage message={message}/>
        </Paper>
    }
    const handleSaveMessage = async () => {
        const {isValid, message} = messageRef?.current?.isValid();
        if (isValid) {
            const data = await RequestPost(`${CloudApi.tracker}/saveMessage/${sessionId}`, {
                id,
                messageId: edit?.id,
                message
            });
            setMessages(data?.messages);
            dispatch(ActionShowAlert({message: data?.message}));
            setRender(render + 1);
            setEdit(undefined);
        }
    }
    const handleDeleteMessage = async (messageId) => {
        dispatch(ActionShowPopup({
            title: "Delete Message",
            content: "Are you sure want to Delete Message",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.tracker}/saveMessage/${sessionId}`, {
                    id,
                    messageId,
                    message: undefined
                });
                setMessages(data?.messages);
                dispatch(ActionShowAlert({message: data?.message}));
                dispatch(ActionHidePopup());
                setRender(render + 1);
            }
        }))
    }
    const list = [
        {label: "Welcome Message", id: "welcome"},
        {label: "Welcome Success Message", id: "success"},
        {label: "Upgrade Message", id: "upgrade"},

        {label: "Start Message", id: "start"},
        {label: "End Message", id: "end"},
        {label: "After End Stats message", id: "stats"},

        {label: "Tracker Expiry Reminder Message", id: "expiry"},
        {label: "Session Expiry in 30 Mins", id: "active30"},
        {label: "Missed reminder", id: "missed"},
        {label: "Time Based Message", id: "time"},

        {label: "Add /Append Message (Chatbot)", id: "addMsg"},
        {label: "Replace Message (Chatbot)", id: "replaceMsg"},

        {label: "Time Based Update Message (Chatbot)", id: "updateTimeMsg"},
        {label: "Time Based Replace (Chatbot)", id: "replaceTimeMsg"},
        {label: "Not Submitted Any Form (Chatbot)", id: "notSubmitted"},
        {label: "Not Started Today Form (Chatbot)", id: "notStarted"},
        {label: "No Active Plan (Chatbot)", id: "noPlan"},

    ]
    return <Stack sx={{gap: 3}}>
        {edit && <Modal size={"70%"} title={<b>{edit?.label}</b>} opened={true} onClose={()=> setEdit(undefined)}>
            <Grid>
                <MessageOptions ref={messageRef} show={['template', 'session']} details={edit?.message?.editOptions}/>
                <Grid.Col span={12}>
                    <Group>
                        <Button onClick={handleSaveMessage}>Save Message</Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </Modal>}
        <Grid>
            {list?.map(a => <Grid.Col span={4}>
                <MessageView label={a?.label} id={a?.id} message={messages?.[a?.id]} />
            </Grid.Col>)}
            {/*{TimeArray?.map((a, i) => {*/}
            {/*    if (i % 2 === 0 && i > 6 && i < 50) {*/}
            {/*        const [time, type] = a?.split(" ");*/}
            {/*        const id = parseInt(time?.replace(":00"), 10) + ((type === "AM" || a === "12:00 PM") ? 0 : 12);*/}
            {/*        return <Grid.Col span={4}>*/}
            {/*            <MessageView label={a} id={id} message={messages?.[id]}/>*/}
            {/*        </Grid.Col>*/}
            {/*    }*/}
            {/*})}*/}
        </Grid>
    </Stack>
}
const Users = ({id}) => {
    const {sessionId} = useSelector(state => state.active);
    const userStatus = ["Pending","Active","Completed","Paused"];
    const userStatusColor = ["blue","green","red","orange"];
    const alertStatus = ["","Every 1 Hr","Every 2 Hr","Every 3 Hr","3 times a day"];
    const formatTime = (time) =>  !time? "": time < 12 ? `${time} AM`: `${time === 12 ? 12: time - 12} PM`

    const [isLoading,setIsLoading] = useState(false);
    const [isAdd,setIsAdd] = useState(false);
    const [users,setUsers] = useState([]);
    const [page,setPage] = useState(1);

    const dispatch = useDispatch();
    const getUsers = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.tracker}/users/${sessionId}`,{id,page});
        setUsers(data?.users);
        setIsLoading(false);
    }

    useEffect(()=>{
        getUsers();
    },[]);
    const DeleteUser = (mobile) =>{
        dispatch(ActionShowPopup({
            title: "Delete",
            content: "Are you sure want to Delete ?",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () =>{
                const data = await RequestPost(`${CloudApi.tracker}/deleteUser/${sessionId}`,{id,mobile});
                dispatch(ActionHidePopup());
                dispatch(ActionShowAlert({message: data?.message}));
            }
        }))
    }

    return <Stack sx={{gap: 3}}>
        <Group position={"right"}>
            <Button size={"xs"} leftIcon={<IconPlus />} onClick={() => setIsAdd(true)}>Add users</Button>
            <Button size={"xs"} leftIcon={<IconRefresh />} onClick={getUsers}>Refresh</Button>
        </Group>
        {isAdd && <AddUsers id={id} onClose={() => setIsAdd(false)} onRefresh={getUsers}  />}
        <PageLoading isLoading={isLoading}>
            <Group position={"apart"}>
                <BoldText text={`Total Results: ${users?.length}`} />
                <Pagination size={"xs"} total={Math.ceil(users?.length / 10)} page={page} onChange={setPage} />
            </Group>

            <TableView headers={["Mobile","Name","Status","Days","TZ","Start Time","End Time","Alert","Created","Options"]}>
                {users?.slice((page-1)*10, page *10)?.map(a =>{
                    return <tr>
                        <td>{a?.mobile}</td>
                        <td>{a?.name}</td>
                        <td><Badge color={userStatusColor?.[a?.status]}>{userStatus?.[a?.status]}</Badge></td>
                        <td>{a?.day}/ {a?.totalDays}</td>
                        {/*<td>{a?.expiry && moment.unix(a?.expiry).format("ll")}</td>*/}
                        <td>{a?.tz}</td>
                        <td>{formatTime(a?.start)}</td>
                        <td>{formatTime(a?.end)}</td>
                        <td>{alertStatus?.[a?.alert]}</td>
                        <td>{moment.unix(a?.created).format("lll")}</td>
                        <td>
                            <Group>
                                <IconView iconType={"view"} />
                                <IconView iconType={"delete"} onClick={() => DeleteUser(a?.mobile)} />
                            </Group>
                        </td>
                    </tr>
                })}
            </TableView>
        </PageLoading>
    </Stack>
}
const Settings = ({id}) =>{
    const {sessionId} = useSelector(state => state.active);
    return <Grid>
        <Grid.Col span={4}>
            <Paper shadow={"md"} withBorder p={5}>
                <Group position={"apart"}>
                    <BoldText text={"Create User Webhook"} size={"md"} variant={"gradient"} />
                    <CopyButton value={`https://wba1.businesssalesmachine.com:3005/public/tracker/create/${sessionId}/${id}`}>
                        {({ copied, copy }) => (
                            <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                                {copied ? 'Copied url' : 'Copy url'}
                            </Button>)}
                    </CopyButton>
                </Group>
                <Divider />
                <SmallText text={`Fields: name,mobile,days`} size={"md"} />
            </Paper>
        </Grid.Col>
        <Grid.Col span={4}>
            <Paper shadow={"md"} withBorder p={5}>
                <Group position={"apart"}>
                    <BoldText text={"Activate User Webhook"} size={"md"} variant={"gradient"} />
                    <CopyButton value={`https://wba1.businesssalesmachine.com:3005/public/tracker/activate/${sessionId}/${id}`}>
                        {({ copied, copy }) => (
                            <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                                {copied ? 'Copied url' : 'Copy url'}
                            </Button>)}
                    </CopyButton>
                </Group>
                <Divider />
                <SmallText text={`Fields: mobile,start,end,alert`}  size={"md"}/>
            </Paper>
        </Grid.Col>
    </Grid>
}
const Leads = ({id}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [leads,setLeads] = useState([]);
    const [times,setTimes] = useState([]);
    const [date,setDate] = useState(new Date());
    const [selected,setSelected] = useState();
    const [headers,setHeaders] = useState([]);
    const getLeads = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.tracker}/leads/${sessionId}`,{id, date});
        setLeads(data?.leads|| []);
        setTimes(data?.leads?.map(a => ({label: moment.unix(a?._id).format("LT"), value: a?._id, count: a?.count})) || []);
        let {_id,mobile,received,error,flowId,flow_token,payload,whatsappId, ...params} = data?.leads?.[0]?.list?.[0] || {};
        setHeaders(Object.keys(params)|| []);
        setIsLoading(false);
    }

    useEffect(()=>{
        getLeads();
    },[date]);

    return <Stack sx={{gap: 3}}>
        <Group position={"apart"}>
            <DatePicker label={"Choose Date"} value={date} onChange={setDate} />
            <Button leftIcon={<IconRefresh />} onClick={getLeads}>Refresh</Button>
        </Group>
        {!isLoading && <Group my={10}>
            {times?.map(a => <Paper shadow={"md"} withBorder p={5}>
                <CheckBoxView text={`${a?.label} - ( ${a?.count} )`} isSelected={selected === a?.value}
                              onClick={() => setSelected(a?.value)}/>
            </Paper>)}
        </Group>}
        <PageLoading isLoading={isLoading}>
            <TableView headers={["Mobile",...headers,"Received"]}>
                {leads?.find(a => a?._id === selected)?.list?.map(a => <tr>
                    <td>{a?.mobile}</td>
                    <>{headers?.map(b => <td>{a?.[b]}</td>)}</>
                    <td>{moment.unix(a?.received).format("lll")}</td>
                </tr>)}
            </TableView>
        </PageLoading>

    </Stack>
}
const Schedule = ({id}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [page,setPage] = useState(1);
    const [list,setList] = useState([]);
    const [total,setTotal] = useState();
    const [totalPage,setTotalPage] = useState();
    useEffect(() =>{
        fetchSchedules();
    },[page]);

    const fetchSchedules = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.tracker}/schedules/${sessionId}`,{id});
        setList(data?.schedules);
        setTotal(data?.total);
        setIsLoading(false);
    }

    return <Stack sx={{gap: 3}}>
        <Grid my={5}>
            <Grid.Col span={6}>
                {/*<BoldText align={"left"} text={`Total Results: ${total}`} mt={10} />*/}
            </Grid.Col>
            <Grid.Col span={6}>
                <Group position={"right"}>
                    {total > 10 && <Pagination position={"center"} size={"xs"} page={page} total={Math.ceil(total / 10)} onChange={setPage}/>}
                    <Button compact size={"xs"} onClick={fetchSchedules} leftIcon={<IconRefresh />}>Refresh</Button>
                </Group>
            </Grid.Col>
        </Grid>
        <PageLoading isLoading={isLoading}>
            <TableView headers={["Time","Contacts","Replied","Log Details"]}>
                {list?.map(b =><tr>
                    <td width={"10%"}>{convertUnixToFormat(b?._id,'LT')}</td>
                    <td width={"10%"}>{b?.leads?.total}</td>
                    <td width={"10%"}>{b?.leads?.replied}</td>
                    <td width={"20%"}>
                        {b?._id <= moment().unix() ? <Group position={"apart"}>
                            <Group sx={{gap: 3}}><IconMessage size={18} color={Colors.Primary} /><Text>{b?.leads?.total}</Text></Group>
                            <Group sx={{gap: 3}}><IconCheck  size={18} color={'grey'} /><Text>{b?.leads?.sent}</Text></Group>
                            <Group sx={{gap: 3}}><IconChecks  size={18} color={'grey'} /><Text>{b?.leads?.delivered}</Text></Group>
                            <Group sx={{gap: 3}}><IconChecks  size={18} color={'green'} /><Text>{b?.leads?.read}</Text></Group>
                            <Group sx={{gap: 3}}><IconX size={18} color={'red'} /><Text>{b?.leads?.failed}</Text></Group>
                        </Group>:  <Group position={"center"}>
                            <Badge>Upcoming</Badge>
                        </Group>}
                    </td>
                </tr>)}
            </TableView>
        </PageLoading>
    </Stack>
}
import React, {useEffect, useState} from "react";
import {Header, IconView, PageLoading, TableView} from "../Pages/Components/PageComponents";
import {AdminApi, RequestPost} from "../Layouts/RequestManager";
import {Button, Drawer, Grid, Group, Modal} from "@mantine/core";
import {FilledTextInput} from "../Pages/Sequence/CustomButton";
import {BoldText, ErrorText} from "../Pages/Components/TextComponents";
import {IconPlus, IconToggleLeft, IconToggleRight} from "@tabler/icons";
import PlanPreview from "../Pages/Dashboard/PlanPreview";
import {ActionShowAlert} from "../store/actions/alert";
import {useDispatch} from "react-redux";
const  PlanUpdate = () =>{
    const [plans,setPlans] = useState([]);
    const [loading,setLoading] = useState(false);
    const [isEdit,setIsEdit] = useState();
    const [isView,setIsView] = useState();
    const dispatch = useDispatch();

    useEffect(()=>{
        getDetails();
    },[]);

    const getDetails = async () =>{
        setLoading(true);
        const data = await RequestPost(`${AdminApi.PlanUpdates}/list`,{});
        setPlans(data?.plans);
        setLoading(false);
    }
    const handleStatusChange = async (plan) =>{
        const data = await RequestPost(`${AdminApi.PlanUpdates}/statusUpdate`,{plan});
        dispatch(ActionShowAlert({message: data?.message}));
        getDetails();
    }

    return <>
        <Header title={"Plans For Update Page"} buttons={[{type: "refresh",title: "refresh"}]} buttonProps={[{onClick: getDetails}]} />
        {isEdit && <UpdatePlan onClose={() => setIsEdit(undefined)} details={isEdit} onRefresh={getDetails} />}
        {isView && <ViewPlan onClose={() => setIsView(undefined)} details={isView} onRefresh={getDetails} />}
        <PageLoading isLoading={loading}>
            <TableView headers={["Type","Plan", "Title","Amount","OldAmount","Options"]}>
                {plans?.map(a => <tr>
                    <td>{a?.type}</td>
                    <td>{a?.plan}</td>
                    <td>{a?.title}</td>
                    <td>{a?.price}</td>
                    <td>{a?.oldPrice}</td>
                    <td>
                        <Group>
                            <IconView icon={a?.status ? <IconToggleRight color={"green"} /> : <IconToggleLeft color={"red"} />} onClick={() => handleStatusChange(a?.plan)} />
                            <IconView iconType={"view"} onClick={() => setIsView(a)} />
                            <IconView iconType={"edit"} onClick={() => setIsEdit(a)} />
                        </Group>
                    </td>
                </tr>)}
            </TableView>
        </PageLoading>
    </>
}
export default PlanUpdate;


const UpdatePlan = ({onClose,onRefresh,details}) =>{

    const [title, setTitle] = useState(details?.title ||"");
    const [description, setDescription] = useState(details?.description ||"");
    const [price, setPrice] = useState(details?.price || "");
    const [oldPrice, setOldPrice] = useState(details?.oldPrice || "");
    const [prefrences, setPrefrences] = useState(details?.prefrences ||[]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(false);

    const AddPref = () => setPrefrences([...prefrences, ""]);
    const DeletePref = (index) => setPrefrences(prefrences?.filter((a,i) => i != index));
    const OnChange = (index, value) => setPrefrences(prefrences?.map((a,i) => i == index ? value: a));

    const handleSubmit = async () =>{
        if(title?.trim() !== "" && price?.trim() !== "" && oldPrice?.trim() !== "" && prefrences?.length > 0){
            setError(false);
            setLoading(true);
            const data = await RequestPost(`${AdminApi.PlanUpdates}/update`,{plan:details?.plan,title,description,price,oldPrice,prefrences});
            setLoading(false);
            onClose();
            onRefresh();
        }else{
            setError(true)
        }
    }


    return <Modal title={<b>{`Update ${details?.plan}`}</b>} opened={true} onClose={onClose} size={"80%"}>
        <Grid>
            <Grid.Col span={4}>
                <FilledTextInput label={"Title"} value={title} onChange={setTitle} />
            </Grid.Col>
            <Grid.Col span={4}>
                <FilledTextInput label={"Description"} value={description} onChange={setDescription} />
            </Grid.Col>
            <Grid.Col span={4}>
                <FilledTextInput label={"Price"} value={price} onChange={setPrice}  />
            </Grid.Col>
            <Grid.Col span={4}>
                <FilledTextInput label={"OldPrice"} value={oldPrice} onChange={setOldPrice}  />
            </Grid.Col>
            <Grid.Col span={12}>
                <Group position={"apart"}>
                    <BoldText text={`Prefrences`} />
                    <Button compact leftIcon={<IconPlus />} onClick={AddPref}>Add More</Button>
                </Group>
            </Grid.Col>
                {prefrences?.map((a,i) => <Grid.Col span={4}>
                    <Group>
                        <Grid.Col span={10}>
                            <FilledTextInput label={"Prefrences"} value={a} onChange={(value) => OnChange(i,value)} />
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <IconView iconType={"delete"} onClick={() => DeletePref(i)} />
                        </Grid.Col>
                    </Group>

                </Grid.Col>)}
            {error && <ErrorText text={`Please Fill All Fields`} />}
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button loading={loading} onClick={handleSubmit}>Update Plan</Button>
                </Group>
            </Grid.Col>

        </Grid>

    </Modal>
}
const ViewPlan = ({onClose,details}) =>{
    return <Drawer opened={true} onClose={onClose} position={"right"} size={"30%"}>
        {/*<Group position={"center"}>*/}
            <PlanPreview details={details} isAdmin={true} />
        {/*</Group>*/}
    </Drawer>
}
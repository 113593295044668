import {DatePicker, DateRangePicker} from "@mantine/dates";
import {useEffect, useState} from "react";
import {CloudApi, R365Api, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {useSelector} from "react-redux";
import {IconView, IconWithText, PageLoading, TableView} from "../../Pages/Components/PageComponents";
import {Grid, Group, Tabs, Paper, Drawer, Pagination, Button, Select, SegmentedControl} from "@mantine/core";
import React from "react";
import {BoldText, SmallText} from "../../Pages/Components/TextComponents";
import moment from "moment";
import {IconCheck, IconDownload, IconUser, IconX} from "@tabler/icons";
import ProductSelect from "../Misc/ProductSelect";

const R365UserStats = () =>{

    return  <Tabs  keepMounted={false} defaultValue="btnClick">
        <Tabs.List grow>
            <Tabs.Tab value="btnClick">Button Clicks</Tabs.Tab>
            <Tabs.Tab value="actions">Daily Actions</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="btnClick" pt="xs">
            <ButtonClicks />
        </Tabs.Panel>
        <Tabs.Panel value="actions" pt="xs">
            <UserActions />
        </Tabs.Panel>
    </Tabs>
}

export default R365UserStats;


const ButtonClicks = () =>{
    const {sessionId, r365} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [date,setDate] = useState([new Date(), new Date()]);
    const [list,setList] = useState([])
    const [unique,setUnique] = useState(0)
    const [isOpen,setIsOpen] = useState()
    const [regType,setRegType] = useState("All");
    useEffect(()=>{
        if(date?.[1]) {
            getDetails();
        }
    },[date, regType])

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${R365Api.ButtonClicks}/list/${sessionId}`,{date,productId: r365?.productId?.[0], regType});
        setList(data?.data);
        setUnique(data?.unique);
        setIsLoading(false);
    }

    return <PageLoading isLoading={isLoading}>

        {r365?.productId?.[0] === "Daily1BookV2" && <SegmentedControl size={"xs"} color={"violet"} label={"RegType"} data={["All","R365_USA","Others"]} value={regType} onChange={setRegType}  />}
        <Group position={"apart"}>
            <DateRangePicker label={"Choose Date Range"} allowSingleDateInRange sx={{width: 400}} value={date} onChange={setDate} />
            <BoldText text={`Total Unique Users: ${unique}`} size={"md"} mx={20} />
            <a href={`${CloudApi.downloadBtnClicks}/${sessionId}?date=${date}`}>
                <Button leftIcon={<IconDownload />}>Download</Button>
            </a>
        </Group>
        {isOpen && <OpenButtonDetails date={date} btnName={isOpen}  onClose={() => setIsOpen()} regType={regType} />}
       <Grid mt={20}>
           {list?.map(a => <Grid.Col span={3}>
               <Paper shadow={"md"} withBorder p={5}>
                   <Group position={"apart"}>
                       <BoldText text={a?._id} />
                       <Group>
                           <SmallText text={`${a?.mobile?.length || 0} / ${a?.count}`}  />
                           <IconView iconType={"view"} onClick={() => setIsOpen(a?._id)} />
                       </Group>
                   </Group>
               </Paper>
           </Grid.Col>)}
       </Grid>
    </PageLoading>
}
const UserActions = () =>{
    const {sessionId,r365} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [productId,setProductId] = useState(r365?.productId?.[0]);
    const [date,setDate] = useState(new Date());
    const [list,setList] = useState([]);
    const [isOpen,setIsOpen] = useState();

    useEffect(()=>{
       getDetails();
    },[date,productId])

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestGet(`${R365Api.UserActions}/${productId}/${moment(date).format("ll")}`);
        setList(data?.list);
        setIsLoading(false);
    }

    return <PageLoading isLoading={isLoading}>
        <Group position={"apart"}>
            <ProductSelect productId={productId} setProductId={setProductId} />
            <DatePicker label={"Choose Date"} allowSingleDateInRange sx={{width: 400}} value={date} onChange={setDate} />
        </Group>
       <TableView headers={["Action","TZ", "count", "Stats", "users"," Time"]}>
           {list?.map(a => <tr>
               <td>{a?.action}</td>
               <td>{a?.offset}</td>
               <td>{a?.count}</td>
               <td>
                   <Group>
                       {a?.list?.total && <IconWithText text={a?.list?.total} icon={<IconUser size={16} />} />}
                       {a?.list?.success &&  <IconWithText text={a?.list?.success} icon={<IconCheck color={"green"} size={16} />} />}
                       {a?.list?.error && <IconWithText text={a?.list?.error} icon={<IconX color={"red"} size={16} />} />}
                   </Group>

               </td>
               <td>{a?.users?.length}</td>
               <td>{moment.unix(a?.created).format("lll")}</td>
           </tr>)}
       </TableView>
    </PageLoading>
}

const OpenButtonDetails = ({date,btnName,onClose,regType}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [list,setList] = useState([]);
    const [page,setPage] = useState(1);
    useEffect(()=>{
        if(date?.length === 2) getDetails();
    },[date])

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${R365Api.ButtonClicks}/details/${sessionId}`,{date,btnName,regType});
        setList(data?.data);
        setIsLoading(false);
    }
    return <Drawer opened={true} position="right" size={"50%"} title={<BoldText text={btnName} size={"md"} mx={10}  />} onClose={onClose}>
            <PageLoading isLoading={isLoading}>
                <Group position={"apart"} m={10}>
                    <BoldText  text={`Total Results: ${list?.length || 0}`} />
                    <Pagination size={"xs"} total={Math.ceil(list?.length /10)} page={page} onChange={setPage} />
                </Group>

                <TableView headers={["Mobile","Type","Payload","Time"]}>
                    {list?.slice((page-1)* 10, page * 10)?.map(a => <tr>
                        <td>{a?.mobile}</td>
                        <td>{a?.regType}</td>
                        <td>{a?.message?.button?.payload}</td>
                        <td>{moment.unix(a?.created).format("lll")}</td>
                    </tr>)}
                </TableView>
            </PageLoading>
    </Drawer>
}
import {
    Button,
    Divider,
    Grid,
    Group,
    Loader,
    Modal,
    NumberInput,
    SegmentedControl,
    Select,
    Stack,
    Text, Paper, Accordion, Badge, MultiSelect
} from "@mantine/core";
import React, {createRef, forwardRef, memo, useImperativeHandle, useState} from "react";
import {FlowActionApps, FlowTriggerApps} from "../../Constants/Strings";
import {useDispatch, useSelector} from "react-redux";
import {CheckBoxView, FlowSuccessMsg, FlowWebhookButton, IconView} from "../Components/PageComponents";
import {IconDiscountCheck, IconPlugConnected, IconPlus, IconRefresh} from "@tabler/icons";
import IntegrationDrawer from "../BSMFlow/IntegrationDrawer";
import {useEffect} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import VariablesView from "../Flows/VariablesView";
import {useRef} from "react";
import MessageOptions from "../Schedule/MessageOptions";
import {BoldText, ErrorText} from "../Components/TextComponents";
import {FilledTextInput} from "../Sequence/CustomButton";
import ResponseView from "../Flows/ResponseView";
import {DatePicker, TimeInput} from "@mantine/dates";
import AssignTagsCRM from "../Components/AssignTagsCRM";

const FlowApps = ({flowId,data,onClose,trigger, onSave,webhookList,webhookRes}) =>{
    const ref = createRef();
    const [app,setApp] = useState(data?.app);
    const [isError,setIsError] = useState(false);
    const {sessionId,userId} = useSelector(state => state.active);
    const handleSave = async () =>{
        const details = ref?.current.isValid();
        if(app && details?.isValid){
            setIsError(false);
            if(app === "fbLeads"){
                const result = await RequestPost(`${CloudApi.FB}/addListen/${userId}`, {...details?.value, flowId});
                onSave({app, id: data?.id, value: result?.flow, isValid:true});
            }else{
                onSave({app, id: data?.id, value: details?.value, isValid:details?.isValid});
            }
            onClose();
        }else{
            setIsError(true);
        }
    }
    const renderApp = (app) =>{
        const params = {flowId,ref,app,value: app === data?.app ?  data?.value: {},props: data,webhookList}
        switch (app) {
            case "instamojo":
                return <Instamojo {...params} />
            case "razorpay":
               return <RazorPay {...params} />
            case "ck":
                return <ConvertKit {...params} />
            case"fbLeads":
                return  <FBLeads {...params} />
            case "triggerSequence":
                return  <TriggerSequence {...params} />
            case "sendMessage":
                return <WBASendMessage {...params} />
            case "pro":
                return  <BSMProgram {...params} />
            case "triggerWebhook":
                return <TriggerWebhook {...params} />
            case "delay":
                return <DelayFlow {...params} />
            case "filter":
                return <BSMFilter {...params} />
            case "routing":
                return <BSMRoutes {...params} />
            case "crm":
                return <CRMOperations {...params} />
            default:
                return  <CustomAppsForm {...params} />
        }

    }
    return  <Modal opened={true} onClose={onClose} size={"70%"} position={"right"}>
                <Grid>
                    <Grid.Col span={6}>
                        <Select size={"xs"} label={"Choose App"} data={trigger ? FlowTriggerApps: FlowActionApps} value={app}  onChange={setApp} />
                    </Grid.Col>
                    {app && renderApp(app)}
                    <Grid.Col span={12}>
                        {webhookRes && webhookRes?.app === app && webhookRes?.res && typeof webhookRes?.res === "object" && <ResponseView response={webhookRes?.res}/>}
                    </Grid.Col>
                    {isError && <Grid.Col span={12}>
                        <ErrorText text={"Please Fill All Fields"}/>
                    </Grid.Col>}
                    <Grid.Col span={12}>
                        <Group position={'center'} mt={25}>
                            <Button variant={'gradient'} onClick={handleSave}>Save</Button>
                        </Group>
                    </Grid.Col>
                </Grid>
        </Modal>
}

//Trigger Apps

const CustomAppsForm = forwardRef(({app,value, props,flowId} , ref) =>{
    const {forms = [],chatbots = []} = useSelector(state => state.active);
    const [formId,setFormId] = useState(value?.formId || "");
    const [chatbotId,setChatbotId] = useState(value?.chatbotId || "");
    const [isError,setIsError] = useState(false);
    const ref1 = createRef();

    useImperativeHandle(ref, () => ({
        isValid: () =>{
            let response = {isValid: false};
            switch(app){
                case "webhook":
                    response =  {isValid: true, value: {}};
                    break;
                case "formLeads":
                    response =  {isValid: formId?.trim() !== "", value: {formId, label: forms?.find(a => a?.value === formId)?.label}};
                    break;
                case "chatbotLeads":
                    response =  {isValid: chatbotId?.trim() !== "", value: {chatbotId, label: chatbots?.find(a => a?.value === chatbotId)?.label}};
                    break;
            }
            setIsError(!response?.isValid);
            return response;
        }

    }));

    return <Grid.Col span={6}>
        {app === "formLeads" && <Select size={"xs"} label={"Choose Form"} data={forms} value={formId} onChange={setFormId} error={isError && formId?.trim() === ""? "Form is Required": ""}   />}
        {app === "webhook" && <FlowWebhookButton id={flowId} /> }
        {app === "chatbotLeads" && <Select size={"xs"} label={"Choose Chatbot"} data={chatbots} value={chatbotId} onChange={setChatbotId} error={isError && chatbotId?.trim() === ""? "Chatbot is Required": ""} /> }
    </Grid.Col>
})
const Instamojo =  forwardRef(({value,app,props,flowId}, ref) =>{
    console.log("Insta", value, props)
    const [event,setEvent] = useState("newPay");
    const [connId,setConnId] = useState(value?.connId || undefined);
    const [openConn,setOpenConn] = useState(false);
    const [withUser,setWithUser] = useState(value?.withUser !== false);
    const [error,setError] = useState("")

    const onEventChange = (event) =>{
        setEvent(event);
        setConnId(undefined);
    }
    const updateConnection = (conn) =>{
        setConnId(conn);
        setOpenConn(false);
    }
    const SelectItem = forwardRef(({ image, label, description, ...others }, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <Text size="sm">{label}</Text>
                    <Text size="xs" color={"dimmed"}>
                        {description}
                    </Text>
                </div>
            </Group>
        </div>
    ));
    const events = [{label: "New Payment Received",description: "When New payment Received from Instamojo", value: "newPay"}];

    useImperativeHandle(ref, () => ({
        isValid: () =>{
            console.log("Calling")
            return {isValid: event && connId, value:{event,connId,withUser}}
        }

    }));
    return <>
        {openConn && <IntegrationDrawer app={app} connId={connId} updateConnection={updateConnection} onClose={() => setOpenConn(false)}/>}
        <Grid.Col span={6}>
            <Select size={"xs"} label={"Choose Event"}
                    itemComponent={SelectItem}
                    data={events} value={event} onChange={onEventChange} />
        </Grid.Col>

        {event && !connId &&  <Grid.Col span={12}>
            <Button  size={"xs"} mt={25}  onClick={() => setOpenConn(true)} variant={'outline'} leftIcon={<IconPlugConnected />}>Connect With Instamojo</Button>
        </Grid.Col>}
        {connId && <>
            <Grid.Col span={6}>
                <Button  size={"xs"} mt={25} color={'green'} onClick={() => setOpenConn(true)} leftIcon={<IconPlugConnected />}>Connected</Button>
            </Grid.Col>
            <Grid.Col span={6}>
                <Group position={"apart"}>
                    <Group mt={20}><CheckBoxView text={"Get User Details With Response"} onClick={setWithUser} isSelected={withUser} /></Group>
                    <FlowWebhookButton id={flowId} />
                </Group>
            </Grid.Col>
        </>}
        {error &&  <ErrorText text={error} />}
    </>
});
const RazorPay =   forwardRef(({value,app,props,flowId}, ref) =>{
    const [connId,setConnId] = useState(value?.connId || undefined);
    const [openConn,setOpenConn] = useState(false);
    const updateConnection = (conn) =>{
        setConnId(conn);
        setOpenConn(false);
    }
    useImperativeHandle(ref, () => ({
        isValid: () =>{
            return {isValid: !!connId, value:{connId}}
        }

    }));
    return <>
        {openConn && <IntegrationDrawer app={'razorpay'} connection={connId} updateConnection={updateConnection} onClose={() => setOpenConn(false)}/>}
        {app && !connId &&  <Grid.Col span={6}> <Button size={"xs"} onClick={() => setOpenConn(true)} variant={'outline'} leftIcon={<IconPlugConnected />}>Connect With Razorpay</Button></Grid.Col>}
        {connId && <Grid.Col span={6}><Button size={"xs"} mt={25} color={'green'} onClick={() => setOpenConn(true)} leftIcon={<IconPlugConnected />}>Connected</Button></Grid.Col>}
        <Grid.Col span={6}>
            <FlowWebhookButton id={flowId} />
        </Grid.Col>
    </>
});

const ConvertKit =  forwardRef(({value,app,props,flowId},ref) =>   {
    const [event,setEvent] = useState(value?.event || undefined);
    const [connId,setConnId] = useState(value?.connId || undefined);
    const [openConn,setOpenConn] = useState(false);
    const [tags,setTags] = useState([]);
    const [forms,setForms] = useState([]);
    const [selected,setSelected] = useState(value?.selected || undefined);
    const {userId,sessionId} = useSelector(state => state.active)
    const [error,setError] = useState("")
    const [loadingForms,setLoadingForms] = useState(false);

    useEffect(() =>{
        if(connId){
            switch (app){
                case 'ck':
                    getFields(event);
                    break;
            }
        }
    },[event,connId]);
    useEffect(() =>{
        setSelected(value?.selected || undefined);
    },[forms,tags]);

    const onEventChange = (event) =>{
        setEvent(event);
        setConnId(undefined);
        setSelected(undefined);
    }
    const updateConnection = (conn) =>{
        setConnId(conn);
        setOpenConn(false);
    }
    const getFields= async (detailsType) =>{
        setLoadingForms(true)
        const details = await RequestPost(`${CloudApi.convertkit}/details/${sessionId}`,{userId,connId,detailsType});
        if(details?.status === 1){
            const results = details?.data?.map(a =>({label: a?.name, value: a?.id})) || [];
            event === 'tag' ? setTags(results): setForms(results);
            setSelected(value?.selected || undefined);
            setLoadingForms(false)
        }else{
            setLoadingForms(false)
        }
    }

    useImperativeHandle(ref, () => ({
        isValid: () =>{
            const flow = {event,connId,selected,id: value?.id};
            let eventDetails = {}
            if(event === 'tag'){
                eventDetails =  {"name": "subscriber.tag_add", "tag_id": selected}
            }else{
                eventDetails = {"name": "subscriber.form_subscribe", "form_id": selected}
            }
            return {isValid: event&&selected&&connId&& value?.id, value:{event,connId,selected,id: value?.id,eventDetails}}
        }

    }));
    const SelectItem = forwardRef(({ image, label, description, ...others }, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <Text size="sm">{label}</Text>
                    <Text size="xs" color={"dimmed"}>
                        {description}
                    </Text>
                </div>
            </Group>
        </div>
    ));
    const events = [{label: "New Tag Assign",description: "When New Tag assigned to email", value: "tag"},{label: "New Form Submit",description: "When User Submitted Form", value: "form"}];
    return <>
        {openConn && <IntegrationDrawer app={'ck'} connection={connId} updateConnection={updateConnection} onClose={() => setOpenConn(false)}/>}
        {app && <Select label={"Choose Event"}
                    itemComponent={SelectItem}
                    data={events} value={event} onChange={onEventChange} />}
        {app && event && !connId &&<Button  mt={25}  onClick={() => setOpenConn(true)} variant={'outline'} leftIcon={<IconPlugConnected />}>Connect With Convertkit</Button>}
        {connId && <>
            <Button mt={25} color={'green'} onClick={() => setOpenConn(true)} leftIcon={<IconPlugConnected />}>Connected</Button>
            {event === 'form' && <>
               <Select label={"Choose Form"} data={forms} value={selected} onChange={setSelected}/>
                    {!loadingForms ? <IconView icon={<IconRefresh/>} label={"Refresh Forms"} onClick={() =>getFields("form")}/> : <Loader size={"sm"} />}
            </>}
            {event === 'tag' &&   <>

                    <Select label={"Choose Tags"} data={tags} value={selected} onChange={setSelected}/>

                    {!loadingForms ? <IconView icon={<IconRefresh />} label={"Refresh Tags"} onClick={() => getFields("tag")} /> : <Loader size={"sm"} />}
            </>}
        </>}
        {error && <Grid.Col span={12}>
            <Text color={"red"}>{error}</Text>
        </Grid.Col>}
    </>
})
const FBLeads =  forwardRef(({app,value,props,flowId},ref) =>{
    const [event,setEvent] = useState("lead");
    const [connId,setConnId] = useState(value?.connId || undefined);
    const [openConn,setOpenConn] = useState(false);
    const [selected,setSelected] = useState(value?.selected || undefined);
    const [waitingWH,setWaitingWh] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [isError,setIsError] = useState(false);
    const [isEdited,setIsEdited] = useState(0);
    const [pages,setPages] = useState([]);
    const [forms,setForms] = useState([]);

    const [pageId,setPageId] = useState(value?.pageId || "");
    const [formId,setFormId] = useState(value?.formId || "");

    const [pageLoading,setPageLoading] = useState(false);
    const [formLoading,setFormLoading] = useState(false);
    const {sessionId,userId} = useSelector(state => state.active);
    const [error,setError] = useState("");

    useEffect(() =>{
        setIsEdited(isEdited+1);
    },[event,connId,selected]);

    useEffect(() =>{
        if(connId) getPages();
    },[connId]);
    useEffect(() =>{
        if(pageId) getForms();
    },[pageId,pages]);

    const getPages = async () =>{
        setPageLoading(true);
        const details = await RequestPost(`${CloudApi.FB}/pages/${userId}`,{connId});
        setPageLoading(false);
        if(details?.status === 1){
            setPages(details?.pages);
        }else{
            setPages([]);
        }
    }
    const getForms = async () =>{
        setFormLoading(true);
        const page = pages?.find(a => a?.id === pageId);
        const details = await RequestPost(`${CloudApi.FB}/forms/${userId}`,{connId,page});
        setFormLoading(false);
        if(details?.status === 1){
            setForms(details?.forms || []);
        }else{
            setForms([]);
        }
    }

    const updatePageId = (pageId) =>{
        setPageId(pageId);
        setFormId("")
    }
    const updateConnection = (conn) =>{
        setConnId(conn);
        setOpenConn(false);
        setWaitingWh(false);
        setFormId("");
        setPageId("");
    }
    const onEventChange = (event) =>{
        setEvent(event);
        setConnId(undefined);
        setSelected(undefined);
        setFormId("");
        setPageId("");
        setWaitingWh(false);
    }
    useImperativeHandle(ref, () => ({
        isValid:  () =>{
            const page = pages?.find(a => a?.id === pageId);
            const form = forms?.find(a => a?.id === formId);
            if(page && form){
                return {isValid: true, value: {page,form,pageId,formId,connId,event,step:props?.step,flowId: props?.id}}
            }else {
                setIsError(true);
                return  {isValid: false}
            }
        }

    }));
    return <>
        {openConn && <IntegrationDrawer app={'fbLeads'} connection={connId} updateConnection={updateConnection} onClose={() => setOpenConn(false)}/>}
        {app && event && !connId && <Grid.Col span={6}>
            <Button size={"xs"} variant={"gradient"} onClick={() => setOpenConn(true)} mt={25}>Connect With Facebook</Button>
        </Grid.Col>}

        {connId && <>
            <Grid.Col span={6}>
                <Button size={"xs"} mt={25} color={"green"} onClick={() => setOpenConn(true)}>Connected</Button>
            </Grid.Col>
                {pageLoading ? <Loader /> : <Grid.Col span={6}><Select
                    label={"Select Pages"}
                    data={pages?.map(a => ({label: a?.name, value: a?.id}))}
                    value={pageId}
                    onChange={updatePageId}
                    error={isError && !pageId ? "Page is Required": ""}
                /></Grid.Col>}
                {pageId && !pageLoading && <>
                    {formLoading ? <Loader /> :  <Grid.Col span={6}><Select label={"Select Forms"}
                                                         data={forms?.map(a => ({label: a?.name, value: a?.id}))}
                                                         value={formId} onChange={setFormId}
                                                         error={isError && !formId ? "Page is Required": ""}
                    /></Grid.Col>}
                </>}
        </>}
        {error &&  <Grid.Col span={12}><Text color={"red"}>{error}</Text></Grid.Col>}
    </>
})

//Next Step Apps
const TriggerSequence = forwardRef(({props,value,webhookList,flowId},ref) =>{
    const {sequence = []} = useSelector(state => state.active);
    const [variables,setVariables] = useState(value?.variables || [{label: "mobile", type: "map", value: "",required: true}]);
    const [seqId,setSeqId] = useState(value?.seqId || "");
    const [render,setRender] = useState(0);

    const updateVar = (variables) =>{
        setVariables(variables);
        setRender(render+1);
    }
    useImperativeHandle(ref, () => ({
        isValid: () =>{
            return {isValid: seqId, value: {seqId,label: sequence?.find(a => a?.value === seqId)?.label, variables}}
        }

    }));
    return  <>
        <Grid.Col span={6}>
            <Select label={"Choose Sequence"} data={sequence} value={seqId} onChange={setSeqId}/>
        </Grid.Col>
        <VariablesView variables={variables} webhookList={webhookList} props={props}  updateVariables={updateVar} addVariable={false} />
    </>
});
const WBASendMessage = forwardRef(({props, value,webhookList,flowId},ref) =>{
    const ref1 = useRef();
    const {sessionId} =  useSelector(state => state.active);
    const [sendType,setSendType] = useState(value?.options?.sendType ||"now");
    const [hr,setHr] = useState(value?.options?.hr || 0);
    const [min,setMin] = useState(value?.options?.min || 30);
    const [variables,setVariables] = useState(value?.variables || [{label: "mobile", type: "map", value: "",required: true}]);
    const [render,setRender] = useState(0);

    useImperativeHandle(ref, () => ({
        isValid: () =>{
            const {isValid,message} = ref1.current.isValid();
            const valid = sessionId && (sendType === "now" || (hr > 0  || min > 0)) && isValid;
            return {isValid: valid, value: {message, id: value?.id, options: {sendType,hr,min}, variables}}
        }
    }));
    const updateVar = (variables) =>{
        setVariables(variables);
        setRender(render+1);
    }
    return <>
            <Grid.Col span={6}>
                <Group>
                    <SegmentedControl data={[{label: "Immediate", value: "now"},{label: "Later", value: "later"}]} value={sendType} onChange={setSendType} mt={20} />
                    {sendType === "later" && <Group mt={15}>
                        <Text> After </Text>
                        <NumberInput size={'xs'} min={0} max={24} sx={{width: 50}} value={hr} onChange={setHr} />
                        <Text weight={'bold'}>Hrs</Text>
                        <NumberInput size={'xs'} min={0} max={59} sx={{width: 50}} value={min} onChange={setMin} />
                        <Text><b>Mins</b> </Text></Group>}
                </Group>
            </Grid.Col>
        <MessageOptions ref={ref1} sessionId={sessionId} details={value?.message?.editOptions} show={['template','session']} />
        <VariablesView variables={variables} webhookList={webhookList} props={props} updateVariables={updateVar} addVariable={true} />
    </>
});
const BSMProgram = forwardRef(({props,value,flowId,webhookList}, ref) =>{
    const [programId,setProgramId] = useState(value?.programId || undefined);
    const [render,setRender] = useState(0);
    const [variables,setVariables] = useState(value?.variables || [{label: "name", type: "map", value: "",required: true},{label: "email", type: "map", value: "",required: true},{label: "mobile", type: "map", value: "",required: true},{label: "link", type: "map", value: "",required: true}]);
    const {sessionId,programs} = useSelector(state => state.active);
    const updateVar = (variables) =>{
        setVariables(variables);
        setRender(render+1);
    }
    useImperativeHandle(ref, () => ({
        isValid: () =>{
            return {isValid: programId?.trim() !== "", value: {programId,label: programs?.find(a => a?.value === programId)?.label, variables,extraValues: variables?.filter(a => !a?.required)?.map(a => a?.label)}}
        }
    }));

    return <>
        <Grid.Col span={6}>
            <Select required label={"Choose Program"} data={programs} value={programId} onChange={setProgramId}/>
        </Grid.Col>

        {programId && <>
            <VariablesView webhookList={webhookList} variables={variables} props={props} updateVariables={updateVar} addVariable={true} />
        </>}
    </>
})

const payloadTypes = [
    {label: "Json", value: "json"},
    {label: "Form Type", value: "form"},
    {label: "Url Encoded", value: "url"},
];
const authTypes = [
    {label: "No Auth", value: "no"},
    {label: "Basic Auth", value: "basic"},
    {label: "Bearer Token", value: "bearer"},
];
const TriggerWebhook = forwardRef(({props,value,flowId}, ref) =>{
    const app= "triggerWebhook";

    const [method,setMethod] = useState(value?.method || "POST");
    const [url,setUrl] = useState(value?.url || "");
    const [payloadType,setPayloadType] = useState(value?.payloadType || "json");
    const [authType,setAuthType] = useState(value?.auth?.authType || "no");

    const [authToken,setAuthToken] = useState(value?.auth?.authToken || "");
    const [authkey,setAuthKey] = useState(value?.auth?.authkey || "");
    const [authValue,setAuthValue] = useState(value?.auth?.authValue || "");

    const [headers,setHeaders] = useState(value?.headers || []);
    const [params,setParams] = useState(value?.params || []);

    const [render,setRender] = useState(0);
    useImperativeHandle(ref, () => ({
        isValid: () =>{
            // return {isValid: programId?.trim() !== "", value: {programId,label: programs?.find(a => a?.value === programId)?.label, variables,extraValues: variables?.filter(a => !a?.required)?.map(a => a?.label)}}
        }
    }));

    // const handleSaveFlow = async () =>{
    //     if(method && url && payloadType){
    //         setIsError(false);
    //         setIsLoading(true);
    //         const flow = {app,sessionId,id: value?.id,method,url,payloadType, auth: {authType, authkey,authValue,authToken}, headers,params,}
    //         const data = await RequestPost(`${CloudApi.bsmFlows}/saveDetails/${sessionId}`,{flow,step: props?.step, id: props?.id});
    //         setSuccessMsg(data?.message);
    //         setIsLoading(false);
    //     }else{
    //         setIsError(true);
    //     }
    //
    // }
    const updateParams = (variables) =>{
        setParams(variables);
        setRender(render+1);
    }
    const updateHeaders = (variables) =>{
        setHeaders(variables);
        setRender(render+1);
    }

    return  <Grid>
        <Grid.Col span={6}>
            <BoldText mt={20} text={`POST Method`} size={"md"} />
            <SegmentedControl mt={20} label={"Method"} data={["GET","POST"]?.map(a => ({label: a,value: a}))} value={method} onChange={setMethod} />
        </Grid.Col>
        <Grid.Col span={6}>
            <FilledTextInput label={"Endpoint Url"} value={url} onChange={setUrl} />
        </Grid.Col>
        <Grid.Col span={6}>
            <Select label={"Payload Type"} data={payloadTypes} value={payloadType} onChange={setPayloadType} />
        </Grid.Col>
        <Grid.Col span={6}>
            <Select label={"Authentication"} data={authTypes} value={authType} onChange={setAuthType} />
        </Grid.Col>
        {authType === "basic" && <>
            <Grid.Col span={3}>
                <FilledTextInput label={"Key"}   value={authkey} onChange={setAuthKey} />
            </Grid.Col>
            <Grid.Col span={3}>
                <FilledTextInput label={"Value"}  value={authValue} onChange={setAuthValue} />
            </Grid.Col>
        </>}
        {authType === "bearer" && <Grid.Col span={6}>
            <FilledTextInput label={"Token"} value={authToken} onChange={setAuthToken} />
        </Grid.Col>}


        <VariablesView label={"Headers"} variables={headers} props={props} updateVariables={updateHeaders} addVariable={true} />
        <Grid.Col span={12}><Divider /></Grid.Col>
        <VariablesView label={"Parameters"} variables={params} props={props} updateVariables={updateParams} addVariable={true} />
    </Grid>
});
const DelayFlow =  forwardRef(({props,value,webhookList}, ref) =>{
    const [action,setAction] = useState(value?.action || "delay1");
    const [hr,setHr] = useState(value?.hr || 0);
    const [min,setMin] = useState(value?.min || 5);
    const [date,setDate] = useState(value?.date || new Date());
    const [time,setTime] = useState(value?.time || new Date());


    useImperativeHandle(ref, () => ({
        isValid: () =>{
            let isValid = action && ((action === "delay1" && (hr >0 || min > 0)) || (action === "delay2" && date && time));
            return {isValid, value: {action, ...(action === "delay1" ? {hr,min}:{date,time})}}
        }
    }));

    return  <>
        <Grid.Col span={6}>
            <Select size={"xs"} label={"Action Type"} data={[{label: "Delay For", value: "delay1"},{label: "Delay Until", value: "delay2"}]} value={action} onChange={setAction} />
        </Grid.Col>
        {action === "delay1" && <Grid.Col span={6}>
            <Group>
                <NumberInput size={"xs"} label={"Hour"} min={0} max={23} value={hr} onChange={setHr}/>
                <NumberInput size={"xs"} label={"Min"} min={0} max={59} value={min} onChange={setMin} />
            </Group>
        </Grid.Col>}
        {action === "delay2" &&  <Grid.Col span={6}>
            <Group>
                <DatePicker size={"xs"} label={"Date"} value={date} onChange={setDate}  />
                <TimeInput size={"xs"} label={"Time"} format={"12"} value={time} onChange={setTime}  />
            </Group>
        </Grid.Col>}
    </>
});
const BSMFilter =  forwardRef(({props,value,webhookList}, ref) =>{
    const [list,setList] = useState(value?.filter || []);
    const [render,setRender] = useState(1);
    const [isError,setIsError] = useState(false);


    useImperativeHandle(ref, () => ({
        isValid: () =>{
            const isValid = !(list?.map(a =>  a?.filter(b => b?.key?.trim() === "" || b?.action?.trim() === ""  || b?.value?.trim() === "" )?.length === 0)?.includes(false));
            setIsError(!isValid);
            return {isValid, value: {filter: list}}
            // return {isValid: programId?.trim() !== "", value: {programId,label: programs?.find(a => a?.value === programId)?.label, variables,extraValues: variables?.filter(a => !a?.required)?.map(a => a?.label)}}
        }
    }));
    console.log("Filter", isError)

    return  <Paper my={10} shadow={"md"} withBorder sx={{width: "100%",backgroundColor: "#ccc"}} px={5} pb={5}>
            <Group position={"apart"} p={5}>
                <BoldText size={"md"} text={`Filter Options`} />
            </Group>
            <Stack sx={{backgroundColor: "white"}}>
                <FilterOptions list={list} setList={setList} webhookList={webhookList} render={render} setRender={setRender} isError={isError}  />
            </Stack>
        </Paper>
});
const FilterOptions = ({list,setList,webhookList,render,setRender, isError}) =>{
    const handleOrCondition = () =>{
        const l = list;
        l.push([{key: "", action: "equal", value: ""}]);
        setList(l);
        setRender(render+1);
    }
    const handleAndCondition = (i) =>{
        const l = list;
        l?.[i]?.push({key: "", action: "equal", value: ""});
        setList(l);
        setRender(render+1);
    }
    const handleDelete = (i) =>{
        const l = list?.filter((a,index) => index !== i);
        setList(l);
        setRender(render+1);
    }
    const handleDeleteOption = (i, j) =>{
        const l = list;
        list[i] = list?.[i]?.filter((b,index) => index !== j);
        setList(l);
        setRender(render+1);
    }
    const handleChange = (i,j,key,value) =>{
        const l = list;
        l[i][j][key] = value;
        setList(l);
        setRender(render+1);
    }
    return  <Grid.Col span={12}>
        {list?.map((a,i) => {
           return <Stack  sx={{border: "1px solid"}} p={10} m={5}>
                {a?.map((b,j) => <FilterView value={b} webhookList={webhookList} isError={isError} onChange={(key,value) => handleChange(i,j,key,value)} onDelete={() => handleDeleteOption(i,j)} />)}
               <Group>
                   <Button leftIcon={<IconPlus />} compact onClick={() => handleAndCondition(i)}>Add AND Condition</Button>
                   <IconView iconType={"delete"} onClick={() => handleDelete(i)}  />
               </Group>
            </Stack>
        })}
        {list?.length < 3 && <Button leftIcon={<IconPlus />} compact onClick={handleOrCondition}>Add OR Condition</Button>}
    </Grid.Col>
}
const FilterView = ({value,webhookList,onChange,onDelete,isError}) => {
    const FilterActions = [
        {label: "Equals to", value: "equal"},
        {label: "Not Equals to", value: "notEqual"},
        {label: "Contains", value: "contains"},
        {label: "Not Contains", value: "notContain"},
        // {label: "Exists", value: "exists"},
        // {label: "Not Exists", value: "notExists"},
        // {label: "Empty", value: "empty"},
        // {label: "Not Empty", value: "notEmpty"},
        {label: "Starts With", value: "start"},
        {label: "Not Starts With", value: "notStart"},
        {label: "Ends With", value: "end"},
        {label: "Not Ends With", value: "notEnd"},
        {label: "Less than", value: "lessThan"},
        {label: "Greater tan", value: "greaterThan"},
    ]
    const SelectItem = forwardRef(({label, description, ...others }, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <Text size="sm">{label}</Text>
                    <Text size="xs" color={"dimmed"}>
                        {description}
                    </Text>
                </div>
            </Group>
        </div>
    ));

    return <Grid>
        <Grid.Col span={4}>
            <Select label={"Key"} searchable
                    itemComponent={SelectItem}
                    size={"xs"}
                    data={webhookList || []}
                    value={value?.key}
                    nothingFound={"No Results Found"}
                    filter={(value, item) => item?.label?.toString()?.toLowerCase().includes(value?.toLowerCase()?.trim()) ||
                    item?.description?.toString()?.toLowerCase().includes(value?.toLowerCase()?.trim())}
                    onChange={(value) => onChange("key",value)}
                    error={isError && value?.key?.trim() === "" ? "Key is Required": ""}
            />
        </Grid.Col>
        <Grid.Col span={3}>
            <Select size={"xs"} label={"action"} data={FilterActions} value={value?.action} onChange={(value) => onChange("action",value)}  error={isError&& value?.action?.trim() === "" ? "Action is Required": ""} />
        </Grid.Col>
        <Grid.Col span={4}>
            <FilledTextInput label={"Value"} value={value?.value} onChange={(value) => onChange("value",value)}  error={isError&& value?.value?.trim() === "" ? "Value is Required": ""} />
        </Grid.Col>
        <Grid.Col span={1}>
            <IconView mt={10} iconType={"delete"} onClick={onDelete} />
        </Grid.Col>
    </Grid>
}

const BSMRoutes =  forwardRef(({props,value,webhookList}, ref) =>{
    const [routes,setRoutes] = useState(value?.routes || [[[{key: "", value: "",action: "exact"}]]]);
    const [render,setRender] = useState(1);
    const [errors,setErrors] = useState([]);
    useImperativeHandle(ref, () => ({
        isValid: () =>{
            const errors = routes?.map((list, i) => {
                return  list?.map(a =>  a?.filter(b => b?.key?.trim() === "" || b?.action?.trim() === ""  || b?.value?.trim() === "" )?.length === 0)?.includes(false) ? i : undefined;
            }).filter(a => a != undefined);
            setErrors(errors);
            return {isValid: errors?.length === 0, value: {routes}}
            // return {isValid: programId?.trim() !== "", value: {programId,label: programs?.find(a => a?.value === programId)?.label, variables,extraValues: variables?.filter(a => !a?.required)?.map(a => a?.label)}}
        }
    }));

    const handleRoutes = (i,value) =>{
        let list = routes;
        list[i] = value;
        setRoutes(list);
        setRender(render+1);
    }
    const AddRoute = () =>{
        setRoutes([...routes, [[{key: "", value: "",action: "exact"}]]]);
        setRender(render+1)
    }
    const DeleteRoute = (i) =>{
        setRoutes(routes?.filter((a,index) => index!== i));
        setRender(render+1)
    }

    return  <Grid.Col span={12}>
        <Group>
            <Button onClick={AddRoute}>Add Route</Button>
        </Group>
        <Accordion defaultValue={"0"}>
                {routes?.map((a,i) => <Accordion.Item value={`${i?.toString()}`} sx={{backgroundColor: "#ccc"}}>
                    <Accordion.Control>
                        <Group position={"apart"} p={5}>
                            <BoldText size={"md"} text={`Route ${i+1}`} />
                            <Group>
                                {errors?.includes(i) ? <Badge color={"red"}>Required</Badge>: errors?.length > 0 ? <IconDiscountCheck color={"green"} />: <></>}
                                <IconView style={{pointerEvents: "none"}} iconType={"delete"} onClick={() => DeleteRoute(i)} />
                            </Group>
                        </Group>
                    </Accordion.Control>
                        <Accordion.Panel sx={{backgroundColor: "white"}}>
                            <FilterOptions isError={errors?.includes(i)}  list={a} setList={(value) => handleRoutes(i,value)}  webhookList={webhookList} render={render} setRender={setRender} />
                        </Accordion.Panel>
                </Accordion.Item>)}
        </Accordion>
    </Grid.Col>
});
const CRMOperations =  forwardRef(({props,value,webhookList}, ref) =>{

    const [type,setType] = useState(value?.type || "Add");
    const [params, setParams] = useState(value?.params ||  [{label: "mobile", type: "map", value: "",required: true}]);
    const crmRef = useRef();
    const [render,setRender] = useState(1);

    useImperativeHandle(ref, () => ({
        isValid: () =>{
            const picked = crmRef?.current?.isValid();
            const isValid = type && (picked?.data?.tags?.length > 0 || picked?.data?.projects?.length > 0 || params?.length > 0);
            return {isValid, value: {type,...(picked?.data || {}), params}}
        }
    }));
    const updateVar = (variables) =>{
        setParams(variables);
        setRender(render+1);
    }
    useEffect(() =>{
        if(type === "Remove"){
            setParams(prev => prev.filter(a => a?.label === "mobile"));
            setRender(render+1);
        }
    },[type])

    return  <>
        <Grid.Col span={4}>
            <Select size={"xs"} label={"Choose Type"} data={["Add", "Remove"]} value={type} onChange={setType} />
        </Grid.Col>
        <AssignTagsCRM ref={crmRef} selected={value} />
        <Grid.Col span={12}>
            <Divider />
        </Grid.Col>
        <VariablesView label={"Contact Parameters"} variables={params} webhookList={webhookList} props={props}  updateVariables={updateVar} addVariable={type === "Add"} />
    </>
});

export default FlowApps;

import {useSelector} from "react-redux";
import React, {useRef, useState} from "react";
import {IconBookmark, IconEdit, IconTrash} from "@tabler/icons";
import {Badge, Button, Group, Image, Modal, Paper, Stack, Text} from "@mantine/core";
import {ButtonWithHandle, CustomButton, CustomHandle, MenuOptions, MultiLineText} from "../CustomButton";
import {Handle, Position} from "@xyflow/react";
import WBAMessageCreate from "../../Schedule/TemplateMessage";

const TemplateNode = ({data,id}) =>{
    console.log("Temp",data);
    const {sessionId} = useSelector(state => state.active);
    const [onEdit,setIsEdit] = useState();
    const ref1 = useRef();
    const onSave = () =>{
        const details = ref1.current.isValid();
        data?.onChange({message: {...details?.message, details: details?.details},id});
        CloseModal();
    }
    const CloseModal = () =>{
        setIsEdit();
    }
    const menuOptions = () =>{

        let options = [];
        if(data?.message){
            options.push({label: "Edit",type: "item",icon:  <IconEdit size={12} />, onClick: () => setIsEdit(data?.message)});
            options.push({type: "divider"});
        }
        options.push({label: "Delete", type: "item",icon:  <IconTrash color={"red"} size={12} />, onClick: () => data?.onDelete(id)});
        options.push({type: "divider"});
        if(data?.startNode){
            options.push({label: "Remove Start Node",type: "item",icon:  <IconBookmark size={12} />, onClick: () => data?.onStartNodeChange()});
            options.push({type: "divider"});
        }else{
            options.push({label: "Add Start Node",type: "item",icon:  <IconBookmark size={12} />, onClick: () => data?.onStartNodeChange(id)});
            options.push({type: "divider"});
        }
        return options;
    }
    return  <Paper shadow={"md"} withBorder px={2 } pb={2} sx={{backgroundColor: "lightblue", width: 200}}>
        <Group position={"apart"} m={3}>
            <Text size={"xs"} weight={"bold"}>Template</Text>
            {data?.startNode === true && <Badge>Start</Badge>}
            <MenuOptions options={menuOptions()} />
        </Group>
        <Handle type="target" position={Position.Left}  style={{ background: 'red' }} />
        <CustomHandle id={id} />
        <Modal opened={onEdit !== undefined} size={"50%"} title={<b>Add Messages</b>} onClose={CloseModal}>
            <WBAMessageCreate sessionId={sessionId} ref={ref1} />
            <CustomButton title={"Save Message"} onClick={onSave} />
        </Modal>
        {data?.message ? <Stack sx={{gap: 3, backgroundColor:"white"}} p={5}>
            <Text size={"xs"} weight={"bold"}>{data?.message?.name}</Text>
            {data?.message?.details?.header?.image && <Image src={data?.message?.details?.header?.image} height={200} fit={"contain"} />}
            <MultiLineText text={data?.message?.details?.header?.text} weight={"bold"} />
            <MultiLineText text={data?.message?.details?.body?.text} />
            <MultiLineText text={data?.message?.details?.footer?.text} />
            {data?.message?.details?.buttons?.length > 0 && <Group grow my={5}>
                {data?.message?.details?.buttons?.map(a =>  a?.type !== "URL"  ? <ButtonWithHandle label={a?.text} id={a?.text} />: <Button variant={"outline"}>{a?.text}</Button>)}
            </Group>}
        </Stack> :<Text size={"xs"} sx={{cursor: "pointer"}} onClick={() => setIsEdit([])} align={"center"} variant={"gradient"} my={5}>+ Add Message</Text>}
    </Paper>
}
export default TemplateNode;

import React, {useEffect, useState} from "react";
import {Header, IconView, PageLoading, TableView} from "../Pages/Components/PageComponents";
import {AdminApi, RequestGet, RequestPost} from "../Layouts/RequestManager";
import {Button, Grid, Group, Loader, Modal, NumberInput, Pagination, Paper, Select} from "@mantine/core";
import {FilledTextInput} from "../Pages/Sequence/CustomButton";
import {DatePicker, DateRangePicker} from "@mantine/dates";
import {useDispatch} from "react-redux";
import {PaymentDefaults} from "../Constants/Strings";
import {ActionShowAlert} from "../store/actions/alert";
import {BoldText} from "../Pages/Components/TextComponents";
import {IconClearAll, IconClearFormatting, IconXboxX} from "@tabler/icons";
import moment from "moment";
const PageHeading = {
    title: "Payments",
    subTitle:   "You will see all Payments here",
    buttons: [{type: "refresh", title: "Refresh"},{type: "add", title: "Create Payment"}],
}
const Payments  = () =>{
    const [loading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [isCreate,setIsCreate] = useState(false);
    const [isRevert,setIsRevert] = useState();
    const [filter, setFilter] = useState({payType: "All",search: "",plan: "All",date: new Date()});
    const [total,setTotal] = useState(0)
    const [page,setPage] = useState(1)

    const getPayments = async ({params = {}}) => {
        setLoading(true);
        const data = await RequestPost(`${AdminApi.userPayments}/list`,{...filter,page,...params});
        setPayments(data?.data || []);
        setTotal(data?.total || 0)
        setLoading(false);
    }
    useEffect(()=>{
        getPayments({});
    }, [page])
    const handleFilter = (filter) =>{
        console.log(filter);
        setFilter(filter);
        setPage(1)
        getPayments({params: {...filter,page: 1}})
    }

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: () => getPayments({})}, {onClick: () => setIsCreate(true)}]} />
        <PageLoading isLoading={loading}>
            <Filter  filter={filter} setFilter={handleFilter} />
            <Group position={"apart"}>
                <BoldText text={`Total Results: ${total}`} />
                {total > 10 && <Pagination size={"xs"} total={Math.ceil(total / 10)} page={page} onChange={setPage}/>}
            </Group>
            {isCreate && <CreatePayment onClose={() => setIsCreate(false)} onRefresh={() => getPayments({})}/>}
            {isRevert && <RevertPayment details={isRevert} onClose={() => setIsRevert(false)} onRefresh={() => getPayments({})}/>}
            <TableView headers={["PayType","Plan","Email","Amount","WBA", "BSM", "Created", "Options"]}>
                {payments.map((payment) => <tr>
                    <td>{payment?.payType}</td>
                    <td>{payment?.plan}</td>
                    <td>{payment?.email}</td>
                    <td>{payment?.payment?.amount}</td>
                    <td>{payment?.wba?.sessionId}</td>
                    <td>{payment?.bsm?.sessionId}</td>
                    <td>{moment.unix(payment?.created).format("lll")}</td>
                    <td>
                        <Group>
                            <IconView label={"Revert Device"} icon={<IconXboxX />} onClick={() => setIsRevert(payment)} />
                        </Group>
                    </td>
                </tr>)}
            </TableView>
        </PageLoading>
    </>
}
const CreatePayment = ({onClose, onRefresh}) =>{
    const [plan, setPlan] = useState("WBA_YEAR");

    const [users,setUsers] = useState([]);
    const [devices,setDevices] = useState([]);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");


    const [userId, setUserId] = useState("");
    const [bsmSessionId, setBSMSessionId] = useState("");
    const [wbaSessionId, setWBASessionId] = useState("");
    const [userLoading,setUserLoading] = useState(false);
    const [deviceLoading,setDeviceLoading] = useState(false);
    const [error,setError] = useState(false);
    const [loading,setLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() =>{
        getUsersList();
    },[])
    useEffect(() =>{
        getDevicesList();
    },[userId])

    const getUsersList = async () =>{
        setUserLoading(true)
        const data = await RequestGet(AdminApi.usersList);
        setUsers(data?.users || []);
        setUserLoading(false);
    }
    const getDevicesList = async () =>{
        setDeviceLoading(true)
        const data = await RequestGet(`${AdminApi.deviceList}/${userId}`);
        setDevices({wba: data?.wba || [], bsm: data?.bsm || []});
        setDeviceLoading(false)
    }
    const  handleSubmit = async () =>{
        const isValid =  plan && userId && !PaymentDefaults?.[plan]?.products?.map(a => a === "WBA" ? !!wbaSessionId : !!bsmSessionId)?.includes(false);
        if(isValid){
            setLoading(true);
            setError(false);
            const data = await RequestPost(`${AdminApi.userPayments}/add`,{
                amount: 0,
                plan,
                userId,
                userDetails: {name: name?.trim(),email: email?.trim(),mobile: phone},
                email: users?.find(a => a?.id === userId)?.email,
                wbaSessionId,
                bsmSessionId,
                payment: {amount: 0,userId, wbaSessionId,bsmSessionId, email}
            });
            onClose();
            onRefresh();
            dispatch(ActionShowAlert({message: data?.status === 1 ? "Created Successfully": "Something went Wrong"}));
            setLoading(false);
        }else{
            setError(true)
        }
    }


    return <Modal opened={true} size={"90%"} onClose={onClose} title={<BoldText text={`Create Payments`} />}>
        <Grid>
            <Grid.Col span={6}>
                <Select  required size={"xs"} label={"Plans"} data={Object.keys(PaymentDefaults)} value={plan} onChange={setPlan} />
            </Grid.Col>
            {userLoading? <Loader size={"sm"} /> : <Grid.Col span={6}>
                <Select required searchable
                        size={"xs"}
                        label={"User"}
                        data={[{label: "Create New User", value: "new"},...users?.map(a => ({label: a?.email, value: a?.id}))]}
                        value={userId} onChange={setUserId}/>
            </Grid.Col>}
            {userId === "new" && <>
                <Grid.Col span={4}>
                    <FilledTextInput label={"name"} value={name} onChange={setName} />
                </Grid.Col>
                <Grid.Col span={4}>
                    <FilledTextInput label={"email"} value={email} onChange={setEmail} />
                </Grid.Col>
                <Grid.Col span={4}>
                    <FilledTextInput label={"phone"} value={phone} onChange={setPhone} />
                </Grid.Col>
            </>}
            {userId && <>
                {deviceLoading  ? <Loader size={"sm"} /> : <>
                    {PaymentDefaults?.[plan]?.products?.includes("WBA") &&<Grid.Col span={3}>
                        <Select required searchable size={"xs"} label={"WBA Device"}
                                data={[{label: "Add New Device", value: "new"},...devices?.wba?.map(a => ({label: a?.name, value: a?.sessionId}))]}
                                value={wbaSessionId} onChange={setWBASessionId}/>
                    </Grid.Col>}
                    {PaymentDefaults?.[plan]?.products?.includes("BSM") && <Grid.Col span={3}>
                        <Select required searchable size={"xs"} label={"BSM Device"} data={[{label: "Add New Device", value: "new"},...devices?.bsm?.map(a => ({label: a?.name, value: a?.sessionId}))]}
                                value={bsmSessionId} onChange={setBSMSessionId}/>
                    </Grid.Col>}
                </>}
            </>}
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button onClick={handleSubmit} loading={loading} disabled={!userId || (!wbaSessionId  && !bsmSessionId)}>Create Payment</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>
}
const RevertPayment = ({onClose, onRefresh, details}) =>{
    const [plan, setPlan] = useState(details?.plan || "WBA_YEAR");
    const [users,setUsers] = useState([]);
    const [devices,setDevices] = useState([]);
    const [userId, setUserId] = useState(details?.userId || "");
    const [bsmSessionId, setBSMSessionId] = useState(details?.bsm?.sessionId ||"");
    const [wbaSessionId, setWBASessionId] = useState(details?.wba?.sessionId ||"");
    const [userLoading,setUserLoading] = useState(false);
    const [deviceLoading,setDeviceLoading] = useState(false);
    const [error,setError] = useState(false);
    const [loading,setLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() =>{
        getUsersList();
    },[])
    useEffect(() =>{
        getDevicesList();
    },[userId])

    const getUsersList = async () =>{
        setUserLoading(true)
        const data = await RequestGet(AdminApi.usersList);
        setUsers(data?.users || []);
        setUserLoading(false);
    }
    const getDevicesList = async () =>{
        setDeviceLoading(true)
        const data = await RequestGet(`${AdminApi.deviceList}/${userId}`);
        setDevices({wba: data?.wba || [], bsm: data?.bsm || []});
        setDeviceLoading(false)
    }
    const  handleSubmit = async () =>{
        const isValid =  plan && userId && !PaymentDefaults?.[plan]?.products?.map(a => a === "WBA" ? !!wbaSessionId : !!bsmSessionId)?.includes(false);
        if(isValid){
            setLoading(true);
            setError(false);
            const data = await RequestPost(`${AdminApi.userPayments}/revert`,{
                id: details?._id,
                userId,
                email: users?.find(a => a?.id === userId)?.email,
                wbaSessionId,
                bsmSessionId
            });
            onClose();
            onRefresh();
            dispatch(ActionShowAlert({message: data?.status === 1 ? "Created Successfully": "Something went Wrong"}));
            setLoading(false);
        }else{
            setError(true)
        }
    }


    return <Modal opened={true} size={"90%"} onClose={onClose} title={<BoldText text={`Create Payments`} />}>
        <Grid>
            {userLoading? <Loader size={"sm"} /> : <Grid.Col span={6}>
                <Select required searchable
                        size={"xs"}
                        label={"User"}
                        data={[{label: "Create New User", value: "new"},...users?.map(a => ({label: a?.email, value: a?.id}))]}
                        value={userId} onChange={setUserId}/>
            </Grid.Col>}
            {userId && <>
                {deviceLoading  ? <Loader size={"sm"} /> : <>
                    {PaymentDefaults?.[plan]?.products?.includes("WBA") &&<Grid.Col span={3}>
                        <Select required searchable size={"xs"} label={"WBA Device"}
                                data={[{label: "Add New Device", value: "new"},...devices?.wba?.map(a => ({label: a?.name, value: a?.sessionId}))]}
                                value={wbaSessionId} onChange={setWBASessionId}/>
                    </Grid.Col>}
                    {PaymentDefaults?.[plan]?.products?.includes("BSM") && <Grid.Col span={3}>
                        <Select required searchable size={"xs"} label={"BSM Device"} data={[{label: "Add New Device", value: "new"},...devices?.bsm?.map(a => ({label: a?.name, value: a?.sessionId}))]}
                                value={bsmSessionId} onChange={setBSMSessionId}/>
                    </Grid.Col>}
                </>}
            </>}
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button onClick={handleSubmit} loading={loading} disabled={!userId || (!wbaSessionId  && !bsmSessionId)}>Revert Payment</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>
}

const Filter = ({filter, setFilter}) =>{
    const [date,setDate] = useState(filter?.date || new Date());
    const [payType,setPayType] = useState(filter?.payType || "All");
    const [search,setSearch] = useState(filter?.search ||"");
    const [plan,setPlan] = useState(filter?.plan ||"All");
    const handleFilter = () =>{
        setFilter({payType,search,plan,date})
    }
    const ResetFilter = () =>{
        setFilter({payType: "All",search: "",plan: "All",date: new Date()})
    }

    return <Paper shadow={"md"} withBorder p={5} my={5}>
        <Grid>
            <Grid.Col span={2}>
                <Select label={"PayType"} size={"xs"} data={["All","Razorpay","Dashboard"]} value={payType} onChange={setPayType} />
            </Grid.Col>
            <Grid.Col span={2}>
                <Select label={"Plan"}  size={"xs"} data={["All",...Object.keys(PaymentDefaults)]} value={plan} onChange={setPlan} />
            </Grid.Col>
            <Grid.Col span={3}>
                <FilledTextInput label={"Search"} size={"xs"} placeholder={"Search email"} value={search} onChange={setSearch} />
            </Grid.Col>
            <Grid.Col span={2}>
                <DatePicker label={"Date"}  size={"xs"} value={date} onChange={setDate} />
            </Grid.Col>
            <Grid.Col span={3}>
                <Group mt={25}>
                    <Button compact onClick={handleFilter}>Filter</Button>
                    <Button compact onClick={ResetFilter}>Reset</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Paper>
}

export  default Payments;
import {Drawer, Group, Stack, Paper, Button, Accordion,Box,Text} from "@mantine/core";
import {BoldText} from "../Components/TextComponents";
import {IconRefresh} from "@tabler/icons";
import {CloudApi, RequestGet} from "../../Layouts/RequestManager";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {PageLoading} from "../Components/PageComponents";

const Settings = ({onClose}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [fetching,setFetching] = useState();
    const [tags,setTags] = useState([]);
    const [projects,setProjects] = useState([]);
    const [params,setParams] = useState([]);

    useEffect(() =>{
        Refetch("list");
    },[])


    const Refetch = async (type) => {
        setFetching(type)
        const data = await RequestGet(`${CloudApi.CRMFormat}/${type}/${sessionId}`);
        if(type === "tags") setTags(data?.tags || []);
        if(type === "projects") setProjects(data?.projects || []);
        if(type === "params") setParams(data?.params || []);
        if(type === "list") {
            setTags(data?.tags || []);
            setProjects(data?.projects || []);
            setParams(data?.params || []);
        }
        setFetching(undefined);
    }

     return <Drawer opened={true} onClose={() => onClose(false)} position={"right"} size={"50%"}>
             <PageLoading isLoading={fetching === "list"}>
                 <Stack sx={{gap: 3}} mx={10}>
                     <Accordion defaultValue="tags">
                         <Accordion.Item key={"tags"} value={"tags"}>
                             <Accordion.Control>
                                 <Group position={"apart"}>
                                     <BoldText text={`Tags:  (${tags.length})`} size={"md"} />
                                     <Button loading={fetching === "tags"} leftIcon={<IconRefresh />} compact onClick={() => Refetch("tags")}>
                                         Refresh
                                     </Button>
                                 </Group>
                             </Accordion.Control>
                             <Accordion.Panel>
                                 <Group grow>
                                     {tags?.map(a =>{
                                         return <Paper shadow={"md"} withBorder p={5} sx={{minWidth: 100}}>
                                             <BoldText text={a}  lineClamp={1} align={"center"} />
                                         </Paper>
                                     })}
                                 </Group>
                             </Accordion.Panel>
                         </Accordion.Item>
                         <Accordion.Item key={"projects"} value={"projects"}>
                             <Accordion.Control>
                                 <Group position={"apart"}>
                                     <BoldText text={`Projects:  (${projects.length})`} size={"md"} />
                                     <Button loading={fetching === "projects"} leftIcon={<IconRefresh />} compact onClick={() => Refetch("projects")}>
                                         Refresh
                                     </Button>
                                 </Group>
                             </Accordion.Control>
                             <Accordion.Panel>
                                 <Group grow>
                                     {projects?.map(a =>{
                                         return <Paper shadow={"md"} withBorder p={5} sx={{minWidth: 100}}>
                                             <BoldText text={a}  lineClamp={1} align={"center"} />
                                         </Paper>
                                     })}
                                 </Group>
                             </Accordion.Panel>
                         </Accordion.Item>
                         <Accordion.Item key={"params"} value={"params"}>
                             <Accordion.Control>
                                 <Group position={"apart"}>
                                     <BoldText text={`Contact Params:  (${params.length})`} size={"md"} />
                                     <Button loading={fetching === "params"} leftIcon={<IconRefresh />} compact onClick={() => Refetch("params")}>
                                         Refresh
                                     </Button>
                                 </Group>
                             </Accordion.Control>
                             <Accordion.Panel>
                                 <Group grow>
                                     {params?.map(a =>{
                                         return <Paper shadow={"md"} withBorder p={5} sx={{minWidth: 100}}>
                                                 <BoldText text={a}  lineClamp={1} align={"center"} />
                                         </Paper>
                                     })}
                                 </Group>
                             </Accordion.Panel>
                         </Accordion.Item>
                     </Accordion>

                 </Stack>
             </PageLoading>
        </Drawer>
}
export default Settings;
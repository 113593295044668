import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Paper, Group, Image, Stack, Drawer} from "@mantine/core";
import {BoldText, TextWithLines} from "../Components/TextComponents";
import {PageLoading} from "../Components/PageComponents";
import NotificationPage from "./NotificationPage";
import {UpdateActiveSession} from "../../store/actions/user";

const Notifications = ({onClose}) => {

    const [list,setList] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    const dispatch = useDispatch();

    const {sessionId} = useSelector(state => state.active);
    useEffect(() => {
        if(!details) getNotifications();
    }, [details]);

    const getNotifications = async () => {
        setIsLoading(true)
        const data = await RequestPost(`${CloudApi.notifications}/list/${sessionId}`,{
            types: "WBA"
        });
        setList(data?.data || []);
        dispatch(UpdateActiveSession({notification: {notRead: data?.notRead}}));
        setIsLoading(false)
    }
    const handleOpenDetails = async (details) =>{
        setDetails(details);
        if(!details?.isRead) await RequestPost(`${CloudApi.notifications}/updateRead/${sessionId}`,{id: details?._id});
    }

    return <Drawer title={<BoldText size={"md"} text={"Notifications"} mx={5} />} opened={true} onClose={onClose} position={"right"} size={"80%"}>
        {details && <NotificationPage data={details} onClose={() => setDetails(undefined)} />}
        <PageLoading isLoading={isLoading}>
            <Stack sx={{gap: 1}} m={5}>
                {list?.map(a =>{
                    return <Paper shadow={"md"} withBorder p={5} sx={{height: 60, cursor: "pointer", backgroundColor: a?.isRead ? "#ccc": "white"}} onClick={() => handleOpenDetails(a)}>
                        <Group>
                            {a?.image && <Image src={a?.image} width={50} height={50} fit={"contain"}/>}
                            <Stack sx={{gap: 3}}>
                                <BoldText text={a?.title} />
                                <TextWithLines size={"xs"} color={a?.isRead ? "black":"dimmed"} text={a?.content} maxLine={1} />
                            </Stack>
                        </Group>
                    </Paper>
                })}
            </Stack>
        </PageLoading>
    </Drawer>
}
export default Notifications;
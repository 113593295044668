// CustomEdge.js
import React, { useState } from 'react';
import {EdgeLabelRenderer, getBezierPath, BaseEdge, useReactFlow, MarkerType,} from '@xyflow/react';
import {IconView} from "../Components/PageComponents";
import {Colors} from "../../Constants/Colors";
import {IconTrash} from "@tabler/icons";

const CustomEdge = ({
                        id,
                        sourceX,
                        sourceY,
                        targetX,
                        targetY,
                        sourcePosition,
                        targetPosition,
                        style = {},
                        arrowHeadType,
                        markerEnd,
                        markerStart,
                        data,
                    }) => {
    const { setEdges } = useReactFlow();
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const onEdgeClick = () => {
        setEdges((edges) => edges.filter((edge) => edge.id !== id));
        data?.onDelete(id);
    };

    return (
        <>
            <BaseEdge path={edgePath}
                      markerStart={{
                          ...markerStart,
                          width: 20,
                          height: 20,
                          color: Colors.Primary,
                      }}
                      markerEnd={{
                ...markerEnd,
                // type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: Colors.Primary,
            }} style={{
                strokeWidth: 2,
                stroke: Colors.Primary,
            }} />
        {/*    <EdgeLabelRenderer>*/}
        {/*        <div*/}
        {/*            style={{*/}
        {/*                position: 'absolute',*/}
        {/*                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,*/}
        {/*                fontSize: 12,*/}
        {/*                pointerEvents: 'all',*/}
        {/*                overflow: 'hidden',*/}
        {/*            }}*/}
        {/*            className="nodrag nopan"*/}
        {/*        >*/}
        {/*            <IconView label={"Delete"} icon={<IconTrash color={"white"} />} color={"white"} variant={"filled"} onClick={onEdgeClick} />*/}
        {/*        </div>*/}
        {/*    </EdgeLabelRenderer>*/}
        </>
    );
};

export default CustomEdge;

import React, {useEffect, useState} from "react";
import {IconBookmark, IconEdit, IconTrash} from "@tabler/icons";
import {Badge, Button, Divider, Group, Modal, Paper, Stack, Text} from "@mantine/core";
import {Handle, Position} from "@xyflow/react";
import {
    ButtonWithHandle,
    CustomButton,
    FilledTextArea,
    FilledTextInput,
    MenuOptions
} from "../CustomButton";
import {getRandomString} from "../../../Constants/Functions";

const ButtonNode = ({data,id, ...props}) =>{
    console.log("JKq ", props);
    const [header,setHeader] = useState("");
    const [body,setBody] = useState("");
    const [footer,setFooter] = useState("");
    const [buttons,setButtons] = useState([{label: "Answer1", id: "Answer2"}]);
    const [newButton,setNew] = useState("");
    const [render,setRender] = useState(0);
    const [isError,setIsError] = useState(false);
    const initialOpen = {
        buttons: [{label: "Button 1",id: getRandomString(5)}],
        body: "Ask your Question",
    }

    const [onEdit,setIsEdit] = useState();
    const menuOptions = () =>{
        let options = [];
        if(data?.message){
            options.push({label: "Edit",type: "item",icon:  <IconEdit size={12} />, onClick: () => setIsEdit(data?.message)});
            options.push({type: "divider"});
        }
        options.push({label: "Delete", type: "item",icon:  <IconTrash color={"red"} size={12} />, onClick: () => data?.onDelete(id)});
        options.push({type: "divider"});
        if(data?.startNode){
            options.push({label: "Remove Start Node",type: "item",icon:  <IconBookmark size={12} />, onClick: () => data?.onStartNodeChange()});
        }else{
            options.push({label: "Add Start Node",type: "item",icon:  <IconBookmark size={12} />, onClick: () => data?.onStartNodeChange(id)});
        }
        return options;
    }

    useEffect(() => {
        if(onEdit){
            setHeader(onEdit?.header || "");
            setBody(onEdit?.body || "");
            setFooter(onEdit?.footer || "");
            setButtons(onEdit?.buttons || []);
        }
    }, [onEdit])

    const addButton = () =>{
        setButtons([...buttons, {label: newButton, id: getRandomString(5)}]);
        setNew("");
        setRender(render+1);
    }
    const deleteButton = (id) =>{
        setButtons(buttons?.filter(a => a?.id !== id));
        setNew("");
        setRender(render+1);
    }
    const ChangeButton = (id,value) =>{
        setButtons(buttons?.map(a => a?.id !== id ? a : {...a,label: value}));
        setRender(render+1);
    }
    const onSave = () =>{

        if(body?.trim() !== "" && buttons?.filter(a => a?.label?.trim() === "")?.length === 0){
            setIsError(false);
            const message = {header,body,footer,buttons};
            data?.onChange({message,id});
            CloseModal();
        }else{
            setIsError(true);
        }



    }
    const CloseModal = () =>{
        setIsEdit();
        setHeader("");
        setButtons([]);
        setBody("");
        setFooter("");
        setNew("");
    }

    return  <Paper shadow={"md"} withBorder px={2} pb={2} sx={{backgroundColor: "lightblue", width: 200}}>
        <Handle type="target" position={Position.Left}  style={{ background: 'red' }} />
        <Group position={"apart"} m={3}>
            <Text size={"xs"} weight={"bold"}>Button</Text>
            {data?.startNode === true && <Badge>Start</Badge>}
            <MenuOptions options={menuOptions()} />
        </Group>
        <Modal opened={onEdit !== undefined} size={"20%"} title={<b>Button Message</b>} onClose={CloseModal}>
            <FilledTextInput label={"Header"} limit={60}  placeholder={"Header Message Here"} value={header} onChange={setHeader} />
            <FilledTextArea  label={"Body Message"} limit={1024} placeholder={"Body Message Here"} value={body} onChange={setBody} error={isError && body?.trim() === ""?"Message is Required": ""} />
            <FilledTextInput label={"Footer"} limit={60}  placeholder={"Footer Message Here"} value={footer} onChange={setFooter} />
            <Stack sx={{gap: 3}}>
                {buttons?.map((a,i) => <FilledTextInput fullwidth limit={20} label={`Button ${i+1}`}  placeholder={"Button Title"} error={isError && a?.label?.trim() === ""?"Button Title is Required": ""} rightSection={<IconTrash color={"red"} size={14} onClick={() => deleteButton(a?.id)}/>} value={a?.label} onChange={value => ChangeButton(a?.id,value)} />)}
                {buttons?.length < 3 && <FilledTextInput label={`New Button`} limit={20} placeholder={"Button Title"} value={newButton} onChange={setNew} rightSection={<Button compact onClick={addButton}>Create</Button>}/>}
            </Stack>
            <CustomButton title={"Save Message"} onClick={onSave}  />
        </Modal>
        {data?.message ?  <Stack sx={{gap: 1 , backgroundColor: "white"}} px={5} pb={5}>
            {data?.message?.header && <Text size={"xs"} weight={"600"}>{data?.message?.header}</Text>}
            {data?.message?.body && data?.message?.body?.split("\n")?.map(a => <Text size={"xs"}>{a}</Text>)}
            {data?.message?.footer && <Text size={"xs"} color={"dimmed"}>{data?.message?.footer}</Text>}
            <Divider my={5} />
            {data?.message?.buttons?.map((a,i) => <ButtonWithHandle label={a?.label} id={a?.id} />)}
            <ButtonWithHandle label={"Default"} id={"default"} buttonProps={{variant: "filled"}} />
        </Stack> : <Text size={"xs"} sx={{cursor: "pointer"}} onClick={() => setIsEdit(initialOpen)} align={"center"} variant={"gradient"} my={5}>+ Add Message</Text>}
    </Paper>
}
export default ButtonNode

import {Header, PageLoading, TableView} from "../Pages/Components/PageComponents";
import {AdminApi, RequestGet, RequestPost} from "../Layouts/RequestManager";
import {useEffect, useState} from "react";
import {Button, CopyButton, Grid, Group, Loader, Modal, NumberInput, Pagination, Paper, Select} from "@mantine/core";
import {BoldText} from "../Pages/Components/TextComponents";
import {PaymentDefaults} from "../Constants/Strings";
import {IconCopy} from "@tabler/icons";
import moment from "moment";
import {ActionShowAlert} from "../store/actions/alert";
import {useDispatch} from "react-redux";
import {FilledTextInput} from "../Pages/Sequence/CustomButton";

const PageHeading = {
    title: "RazorPay PayLinks",
    subTitle:   "You will Create /View  PayLinks here",
    buttons: [{type: "refresh", title: "Refresh"},{type: "add", title: "Create"}]
}
const PayLinks = () =>{
    const [loading, setLoading] = useState(true);
    const [links, setLinks] = useState([]);
    const [isCreate, setIsCreate] = useState(false);
    const [filter, setFilter] = useState({status: "Active",search: "",plan: "All"});
    const [total,setTotal] = useState(0)
    const [page,setPage] = useState(1)

    const getPayLinks = async ({params}) => {
        setLoading(true);
        const data = await RequestPost(`${AdminApi.payLinks}/list`,{...filter, page,...params});
        setLinks(data?.links || []);
        setTotal(data?.total || 0);
        setLoading(false);
    }
    useEffect(()=>{
        getPayLinks({});
    }, [page]);
    const handleFilter = (filter) =>{
        console.log(filter);
        setFilter(filter);
        setPage(1)
        getPayLinks({params: {...filter,page: 1}})
    }

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: () => getPayLinks({})},{onClick: () => setIsCreate(true)}]} />
        <Filter filter={filter} setFilter={handleFilter} />
        <Group position={"apart"}>
            <BoldText text={`Total Results: ${total}`} />
            {total > 10 && <Pagination size={"xs"} total={Math.ceil(total / 10)} page={page} onChange={setPage}/>}
        </Group>
        <PageLoading isLoading={loading}>
            {isCreate && <CreatePayLink onClose={() => setIsCreate(false)} onRefresh={getPayLinks}  />}
            <TableView headers={["Email","Plan","amount","Expiry","Link","Options"]}>
                {links?.map(a => <tr>
                    <td>{a?.email}</td>
                    <td>{a?.plan}</td>
                    <td>{a?.amount}</td>
                    <td>{moment.unix(a?.result?.expire_by).format("lll")}</td>
                    <td>{a?.result?.short_url}</td>
                    <td>
                        <Group>
                            <CopyButton value={a?.result?.short_url}>
                                {({ copied, copy }) => (
                                    <Button compact color={copied ? 'teal' : 'blue'} onClick={copy}>
                                        {copied ? 'Copied url' : 'Copy url'}
                                    </Button>
                                )}
                            </CopyButton>
                        </Group>
                    </td>
                </tr>)}
            </TableView>
        </PageLoading>
    </>
}

export default PayLinks;

const Filter = ({filter, setFilter}) =>{
    const [status,setStatus] = useState(filter?.status || "Active");
    const [search,setSearch] = useState(filter?.search ||"");
    const [plan,setPlan] = useState(filter?.plan ||"All");
    const handleFilter = () =>{
        setFilter({status,search,plan})
    }
    const ResetFilter = () =>{
        setFilter({status: "Active",search: "",plan: "All"})
    }

    return <Paper shadow={"md"} withBorder p={5} my={5}>
        <Grid>
            <Grid.Col span={3}>
                <Select size={"xs"} data={["Active","Expired","All"]} value={status} onChange={setStatus} />
            </Grid.Col>
            <Grid.Col span={3}>
                <FilledTextInput size={"xs"} placeholder={"Search email, link"} value={search} onChange={setSearch} />
            </Grid.Col>
            <Grid.Col span={3}>
                <Select  size={"xs"} data={["All",...Object.keys(PaymentDefaults)]} value={plan} onChange={setPlan} />
            </Grid.Col>
            <Grid.Col span={3}>
                <Group>
                    <Button compact onClick={handleFilter}>Filter</Button>
                    <Button compact onClick={ResetFilter}>Reset</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Paper>
}


const CreatePayLink = ({onClose, onRefresh}) =>{
    const [amount, setAmount] = useState(1);
    const [plan, setPlan] = useState("WBA_YEAR");

    const [users,setUsers] = useState([]);
    const [devices,setDevices] = useState([]);

    const [userId, setUserId] = useState("");
    const [bsmSessionId, setBSMSessionId] = useState("");
    const [wbaSessionId, setWBASessionId] = useState("");
    const [expiry, setExpiry] = useState("");

    const [userLoading,setUserLoading] = useState(false);
    const [deviceLoading,setDeviceLoading] = useState(false);
    const [error,setError] = useState(false);
    const [loading,setLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() =>{
        getUsersList();
    },[])
    useEffect(() =>{
        getDevicesList();
    },[userId])

    const getUsersList = async () =>{
        setUserLoading(true)
        const data = await RequestGet(AdminApi.usersList);
        setUsers(data?.users || []);
        setUserLoading(false);
    }
    const getDevicesList = async () =>{
        setDeviceLoading(true)
        const data = await RequestGet(`${AdminApi.deviceList}/${userId}`);
        setDevices({wba: data?.wba || [], bsm: data?.bsm || []});
        setDeviceLoading(false)
    }
    const  handleSubmit = async () =>{
        const isValid = amount > 0 && plan && userId && !PaymentDefaults?.[plan]?.products?.map(a => a === "WBA" ? !!wbaSessionId : !!bsmSessionId)?.includes(false);
        if(isValid){
            setLoading(true);
            setError(false);
            const data = await RequestPost(AdminApi.createPay, {
                amount,
                plan,
                userId,
                email: users?.find(a => a?.id === userId)?.email,
                wbaSessionId,
                bsmSessionId
            });
            onClose();
            onRefresh();
            dispatch(ActionShowAlert({message: data?.status === 1 ? "Created Successfully": "Something went Wrong"}));
            setLoading(false);
        }else{
            setError(true)
        }
    }


    return <Modal opened={true} size={"90%"} onClose={onClose} title={<BoldText text={`Create PayLinks`} />}>
        <Grid>
            <Grid.Col span={6}>
                <NumberInput min={1} required size={"xs"} label={"Amount"} value={amount} onChange={setAmount} />
            </Grid.Col>
            <Grid.Col span={6}>
                <Select  required size={"xs"} label={"Plans"} data={Object.keys(PaymentDefaults)} value={plan} onChange={setPlan} />
            </Grid.Col>
            {userLoading? <Loader size={"sm"} /> : <Grid.Col span={6}>
                <Select required searchable
                        size={"xs"}
                        label={"User"}
                        data={users?.map(a => ({label: a?.email, value: a?.id}))}
                        value={userId} onChange={setUserId}/>
            </Grid.Col>}
            {userId && <>
                {deviceLoading  ? <Loader size={"sm"} /> : <>
                    {PaymentDefaults?.[plan]?.products?.includes("WBA") &&<Grid.Col span={3}>
                        <Select required searchable size={"xs"} label={"WBA Device"}
                                data={[{label: "Add New Device", value: "new"},...devices?.wba?.map(a => ({label: a?.name, value: a?.sessionId}))]}
                                value={wbaSessionId} onChange={setWBASessionId}/>
                    </Grid.Col>}
                    {PaymentDefaults?.[plan]?.products?.includes("BSM") && <Grid.Col span={3}>
                        <Select required searchable size={"xs"} label={"BSM Device"} data={[{label: "Add New Device", value: "new"},...devices?.bsm?.map(a => ({label: a?.name, value: a?.sessionId}))]}
                                value={bsmSessionId} onChange={setBSMSessionId}/>
                    </Grid.Col>}
                </>}
            </>}
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button onClick={handleSubmit} loading={loading} disabled={!userId || (!wbaSessionId  && !bsmSessionId)}>Create PayLink</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>
}
import {Grid, MultiSelect} from "@mantine/core";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import {useSelector} from "react-redux";
import _ from "underscore";
const formatTags = (tag) =>{
    return tag?.toString()?.toLowerCase()?.replaceAll(" ","_");
}
const AssignTagsCRM = forwardRef(({selected}, ref) =>{
    console.log(selected,"Jk1")
    const {sessionId,...active} = useSelector(state => state.active);
    const [tags, setTags] = useState(_.unique([...(active?.tags || []), ...(selected?.tags || [])]));
    const [projects, setProjects] = useState(_.unique([...(active?.projects || []), ...(selected?.projects || [])]));

    const [selectedTag,setSelectedTag] = useState(selected?.tags || []);
    const [selectedProjects,setSelectedProjects] = useState(selected?.projects || []);

    useImperativeHandle(ref, () => ({
        isValid() {
            return {isValid: true, data: {tags: selectedTag?.map(a => formatTags(a)),projects: selectedProjects?.map(a => formatTags(a))}};
        }
    }));
    return  <>
            <Grid.Col span={4}>
                <MultiSelect key={`tags`} creatable
                             getCreateLabel={(query) => `+ Create ${query}`}
                             onCreate={(query) => {
                                 setTags((current) => [...current, query]);
                                 return query;
                             }}
                             searchable size={"xs"}  data={tags} label={"Tags"} value={selectedTag} onChange={setSelectedTag}/>
            </Grid.Col>
            <Grid.Col span={4}>
                <MultiSelect  key={`projects`} creatable
                             getCreateLabel={(query) => `+ Create ${query}`}
                             onCreate={(query) => {
                                 setProjects((current) => [...current, query])
                                 return query
                             }}
                             searchable size={"xs"} data={projects} label={"Projects"} value={selectedProjects} onChange={setSelectedProjects} />
            </Grid.Col>
        </>
});
export default AssignTagsCRM;
import React, {useEffect, useRef, useState} from 'react';
import {
    Text,
    Grid,
    ScrollArea,
    TextInput,
    Stack,
    Group,
    Paper,
    Button,
    Badge,
    Drawer, Select, Textarea, Image, Divider, Modal, Menu, Loader, SegmentedControl
} from '@mantine/core';
import {useMediaQuery, useViewportSize} from "@mantine/hooks";
import {useSelector} from "react-redux";
import {CloudApi, RequestGet, RequestPost, WBAUrls} from "../../Layouts/RequestManager";
import {Header, PageLoading} from "../Components/PageComponents";
import UserMessages from "./UserMessages";
import moment from "moment";
import {BoldText, SmallText} from "../Components/TextComponents";
const UserChatStatus = [
    {label: "NotAssigned", value: ""},
    {label: "Open", value: "open"},
    {label: "Closed", value: "close"},
]
const PageHeading = {title: "Message Inbox", subTitle: "User Sent and Received Messages Will Show Here", buttons: [{type: 'refresh',title: "Refresh"}]}

const Inbox = () =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [numbers,setNumbers] = useState([]);
    const [selectedNumber,setSelected] = useState("919677964754");
    const [error,setError] = useState();
    const {width,height} = useViewportSize();
    const [userType,setUserType] = useState("all");

    const getDetails = async () =>{
        setIsLoading(true);
        setSelected("");
        setNumbers([]);
        const response = await RequestPost(`${CloudApi.InboxNumbers}/${sessionId}`, {userType});
        setNumbers(response?.numbers || []);
        setIsLoading(false);
    }
    const changeNumber = (mobile) =>{
        setNumbers(numbers?.map(a => a?._id === mobile ? ({...a, unread: 0}): a));
        setSelected(mobile);
    }
    useEffect(() =>{
        getDetails();
    },[sessionId,userType]);
    const isMobile = useMediaQuery('(max-width: 700px)');

    return <Stack sx={{gap: 3}}>
        <Header {...PageHeading} buttonProps={[{onClick: getDetails}]} />
        <PageLoading isLoading={isLoading}>
            <Grid mr={5} style={{height: height - 60}}>
                {((!selectedNumber  && isMobile) || !isMobile) && <Grid.Col span={isMobile ? 12 : 3}>
                    <ScrollArea type={"auto"} style={{height: height - 100}}>
                        {error ?
                            <Text weight={"bold"} color={"red"} size={"sm"} align={"center"} my={10}>{error}</Text> :
                            <MessageUsersView selectedNumber={selectedNumber} changeNumber={changeNumber}
                                              numbers={numbers} userType={userType} setUserType={setUserType}/>}
                    </ScrollArea>
                </Grid.Col>}
                <Grid.Col span={isMobile ? 12 : 9}>
                    {selectedNumber && <UserMessages mobile={selectedNumber} sessionId={sessionId} onClose={setSelected} />}
                </Grid.Col>
            </Grid>
        </PageLoading>
    </Stack>

}
export default Inbox;




const MessageUsersView = ({numbers,changeNumber, selectedNumber,userType,setUserType}) =>{
    const [search,setSearch] = useState("");
    const [count, setCount] = useState(50);
    const [filtered,setFiltered] = useState(numbers?.slice(0,50) || []);
    useEffect(() =>{
        let filtered = numbers?.filter(a => a?._id?.toString()?.includes(search));
        setFiltered(filtered);
    },[search]);

    const renderNumbers = (a, idx) =>{
        return <Paper shadow={"md"} withBorder p={3} sx={{backgroundColor: selectedNumber === a?._id ? "lightgray": "white"}}>
            <Group position={"apart"} sx={{cursor: "pointer"}} onClick={()=> changeNumber(a?._id)}>
                <Text>{a?._id}</Text>
                <Group position={"right"}>
                    {a?.unread > 0 && <Badge color={'green'}>{a?.unread}</Badge>}
                    <SmallText text={moment.unix(a?.lastMessage).format(a?.lastMessage <= moment().startOf("day").unix() ? "ll": "LT")} color={"dimmed"} />
                </Group>
            </Group>
        </Paper>
    }
    return <Stack sx={{gap: 3}} px={5} mx={5}>
        <Grid>
            <Grid.Col span={8} sx={{paddingLeft: 2,paddingRight: 2}}>
                <TextInput size={"xs"} placeholder={"Search Numbers"}  value={search} onChange={(e) =>setSearch(e.target.value)} />
            </Grid.Col>
            <Grid.Col span={4} sx={{paddingLeft: 2}}>
                <Select size={"xs"} data={[{label: "All",value:"all"},{label: "Active",value:"active"},{label: "Open",value:"open"},{label: "Close",value:"close"},{label: "UnRead", value: "unread"}]} value={userType} onChange={setUserType} />
            </Grid.Col>
        </Grid>
        {search?.trim() != "" && <Paper shadow={"md"} withBorder p={1} onClick={() => changeNumber(search)}>
            <Text color={"dimmed"} weight={"bold"}>Chat with {search}</Text>
        </Paper>}
        {filtered?.slice(0,count)?.map(a =>{
            return  <Paper shadow={"md"} withBorder p={3} sx={{backgroundColor: selectedNumber === a?._id ? "lightgray": "white"}}>
                <Group position={"apart"} sx={{cursor: "pointer"}} onClick={()=> changeNumber(a?._id)}>
                    <Text>{a?._id}</Text>
                    <Group position={"right"}>
                        {a?.unread > 0 && <Badge color={'green'}>{a?.unread}</Badge>}
                        <SmallText text={moment.unix(a?.lastMessage).format(a?.lastMessage <= moment().startOf("day").unix() ? "ll": "LT")} color={"dimmed"} />
                    </Group>
                </Group>
            </Paper>
        })}
        <Group position={"apart"}>
            <BoldText text={`Showing 1 - ${count > filtered?.length ? filtered?.length : count} of ${filtered?.length}`} />
            <Button compact disabled={count > filtered?.length} onClick={() => setCount(count+50)}>Loadmore</Button>
        </Group>
    </Stack>
}

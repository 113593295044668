import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    Group,
    Paper,
    Button,
} from "@mantine/core";
import {
    ReactFlow,
    addEdge,
    Controls, Handle,
    MiniMap, Position,
    ReactFlowProvider,
    useEdgesState,
    useNodesState, useStore, useReactFlow,
} from "@xyflow/react";
import {forwardRef, useCallback, useImperativeHandle} from "react";
import {Header, IconView, PageLoading} from "../Components/PageComponents";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {flattenObject, getRandomString} from "../../Constants/Functions";
import CustomEdge from "../Sequence/CustomEdge";
import {FlowAppDetails} from "../../Constants/Strings";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {IconDiscountCheck, IconPlus, IconToggleLeft, IconToggleRight, IconTrash, IconX} from "@tabler/icons";
import {Colors} from "../../Constants/Colors";
import FlowApps from "./FlowApps";
import {BoldText} from "../Components/TextComponents";
import CreateFlow from "./CreateFlow";
import {socket} from "../Alerts/Socket";
const PageHeading = {
    link: {name: "Flows", link: `flows`},
    title: "Edit",
    subTitle: "You can Edit Flow Steps Here",
    buttons: [
        {type: "refresh", title: "Refresh"},
        {type: "view", title: "View Logs"},
    ]
}
const EditFlow = () =>{
    const {id} = useParams();
    const {sessionId,alias} = useSelector(s => s.active);
    const flowRef = useRef();
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    const dispatch= useDispatch();
    const [isEdit,setIsEdit]= useState(false);
    const navigation = useNavigate();

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.bsmFlows}/details/${sessionId}`,{id});
        setDetails(data?.details);
        setIsLoading(false);
    }
    useEffect(() => {
        getDetails();

    },[]);

    useEffect(() => {
        prepareWebhookList()
    },[details]);

    const [webhookList,setWebhookList] = useState([]);
    const prepareWebhookList = () =>{
        const result = [];
        let i = 0;
        if(details?.webhookRes){
            for(const key in details?.webhookRes){
                const data = details?.webhookRes?.[key];
                if(data){
                    const node = details?.nodes?.find(a => a?.id === key);
                    const label = FlowAppDetails?.[node?.app]?.label;
                    if(data?.res && typeof data?.res === "object"){
                        Object.keys(data?.res)?.map(b => {
                            if(data?.res?.[b] && typeof data?.res?.[b] === "object"){
                                const obj = flattenObject(data?.res?.[b]).reduce((acc, item) =>({ ...acc, ...item }), {});
                                Object.keys(obj)?.map(c => {
                                    result.push({
                                        group: `${i + 1}. ${label}`,
                                        label: `${i + 1}. ${label} --> ${c}`,
                                        value: `${node?.id}__${c}`,
                                        description: obj?.[c]
                                    })
                                });
                            }else{
                                result.push({group: `${i+1}. ${label}`, label: `${i+1}. ${label} --> ${b}` ,value: `${node?.id}__${b}`,description: details?.webhookRes?.[key]?.res?.[b] })

                            }

                        });
                    }
                    i++;
                }
            }
        }
        setWebhookList(result);
        return result;
    }
    const handleAddStep = () => flowRef?.current?.addNode();
    const handleSave = async (nodes = []) =>{
        const details = nodes?.length > 0 ? {nodes} : flowRef?.current?.onSave();
        const data = await RequestPost(`${CloudApi.bsmFlows}/saveNodes/${sessionId}`, {id,...details});
        getDetails();
        dispatch(ActionShowAlert({message: data?.message}));
    }
    const handleListenWebhooks = async () =>{
        const isEdited = flowRef?.current?.isEdited();
        if(details?.status === 0){
            dispatch(ActionShowAlert({message: `Please Enable Flow to Listen Webhooks`, color: "red"}));
        }else if(isEdited){
            dispatch(ActionShowAlert({message: `You have Unsaved Changes. Save then Listen Webhooks`, color: "red"}));
        }else{
            socket.on(`Flow_${id}`, () => {
                getDetails();
                dispatch(ActionShowAlert({message: `Response Captured`}));
                socket.off(`Flow_${id}`)
            });
            const data = await RequestPost(`${CloudApi.bsmFlows}/storeWebhook/${sessionId}`, {id});
            getDetails();
        }

    }
    const changeStatus = (status) =>{
        dispatch(ActionShowPopup({
            title: "Change status",
            content: status === 1 ? "Disabling the flow will stop processing and ignore webhook responses. Are you sure?":"Enabling the flow will start listening and execute the steps. Are you sure?",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.bsmFlows}/changeStatus/${sessionId}`,{id,status:  status === 1 ? 0 : 1});
                dispatch(ActionShowAlert({message: data?.message}));
                dispatch(ActionHidePopup());
                getDetails();
            },
            successTitle:"Continue",
            cancelTitle: "Cancel"
        }))
    }
    const ViewLogs= () =>{
        navigation(`/device/${alias}/flows/logs/${id}`);
    }

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: getDetails},{onClick: ViewLogs}]} />
        <PageLoading isLoading={isLoading}>
            {isEdit && <CreateFlow details={isEdit} onClose={() => setIsEdit(undefined)} onRefresh={getDetails} />}
            <Paper shadow={"md"} withBorder p={5}>
                <Group position={"apart"}>
                    <Group>
                        <BoldText text={details?.title} />
                        <IconView iconType={"edit"} onClick={() => setIsEdit(details)} />
                    </Group>

                    <Group position={"right"}>
                        <IconView size={"md"} icon={details?.status === 0 ? <IconToggleLeft color={"red"} />: <IconToggleRight color={'green'} />} label={"Change Flow Status"} onClick={() => changeStatus(details?.status)} />
                        <Button variant={"outline"} compact onClick={handleListenWebhooks} loading={details?.storeWebhook}>Listen Response</Button>
                        {/*<Button variant={"outline"} compact onClick={handleAddStep} leftIcon={<IconPlus />}>Add Step</Button>*/}
                        <Button variant={"outline"} compact onClick={handleSave}>Save</Button>
                    </Group>
                </Group>

            </Paper>
            <ReactFlowProvider>
                <FlowBuilder flowId={id} ref={flowRef} details={details} webhookList={webhookList} onSaveFlow={handleSave} webhookRes={details?.webhookRes} />
            </ReactFlowProvider>
            </PageLoading>
    </>
}
export default EditFlow;
const TriggerNode = ({data,id}) =>{
    const selector = (s) => ({nodeInternals: s.nodeInternals,edges: s.edges,});
    const { edges } = useStore(selector);
    return  <Paper value={id?.toString()} mx={10} p={5} sx={{width: 300, borderStyle: "solid", borderWidth: 1, borderColor: Colors.Primary}}>
        <Group position={"apart"}>
            <Group position={"left"} sx={{gap: 3}}>
                <BoldText size={14} variant={"gradient"} text={FlowAppDetails?.[data?.app]?.label || (data?.startNode ? "Select Trigger": "Select Step")} />
                {data?.isValid && <IconDiscountCheck color={'green'} size={16} />}
            </Group>
            <Group sx={{gap: 3}}>
                <IconView iconType={"edit"}  onClick={() => data?.onEdit(data)}/>
                {!data?.startNode && <IconView iconType={"delete"} onClick={() => data?.onDelete(data?.id)}/>}
            </Group>
        </Group>

        {!data?.startNode && <CustomHandler type={"target"} position="top" id={id}  isConnected={edges?.filter(a => a?.target === id)?.length > 0} />}

        {["filter"].includes(data?.app) && <>
            <Group position={"apart"} mt={10}>
                <Button variant={"outline"} compact sx={{width: "45%"}}>Yes
                    <CustomHandler   position="left" id={`${id}_YES`} isConnected={edges?.filter(a => a?.source === id && a?.sourceHandle === `${id}_YES`)?.length > 0} />
                </Button>
                <Button variant={"outline"} compact sx={{width: "45%"}}>No
                    <CustomHandler  position="right"  id={`${id}_NO`}  isConnected={edges?.filter(a => a?.source === id && a?.sourceHandle === `${id}_NO`)?.length > 0}  />
                </Button>
            </Group>
        </>}
        {["routing"].includes(data?.app) && <>
            {data?.value?.routes?.map((a,i) =><Button variant={"outline"} compact sx={{width: "100%"}}>{`Route ${i+1}`}
                    <CustomHandler  position="right" id={`${id}_${i}`}  isConnected={edges?.filter(a => a?.source === id && a?.sourceHandle === `${id}_${i}`)?.length > 0} />
                </Button>)}
        </>}
        {!["filter","routing"].includes(data?.app) && <CustomHandler  onClick={() => data?.onDelete(data?.id)} id={id} isConnected={edges?.filter(a => a?.source === id && a?.sourceHandle === id)?.length > 0} />}
    </Paper>
}
const nodeTypes = {trigger: TriggerNode };
const FlowBuilder =  forwardRef(({flowId,details,onSaveFlow,webhookList,webhookRes}, ref) =>  {
    const reactFlowWrapper = useRef(null);
    const [editedCount,setEditedCount]=useState(0);
    const [nodes, setNodes, onNodesChange] = useNodesState( []);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [isEdit,setIsEdit] = useState();
    const [render,setRender] = useState(0);
    const onConnectEnd =(event, connectionState) => {
            if (!connectionState.isValid) {
                const id = getRandomString(5);
                const newNode = {
                    id,
                    type: "trigger",
                    position: connectionState?.fromHandle?.type  === "source" ? {x: connectionState?.from?.x + 100, y: connectionState?.from?.y +100}: {x: connectionState?.from?.x - 100, y: connectionState?.from?.y -100},
                    data: { id,onDelete: onDeleteValues,onEdit },
                    origin: [0.5, 0.0],
                };
                setNodes((nds) => nds.concat(newNode));
                const params =  connectionState?.fromHandle?.type  === "source" ? {
                        source: connectionState.fromNode.id,sourceHandle: connectionState.fromHandle.id,target: id,
                    }: {
                    target: connectionState.fromNode.id,sourceHandle: id,source: id,
                }

                    setEdges((eds) =>
                    eds.concat({ id,  type: 'custom', data: { onDelete: handleDeleteEdge },...params }),
                );
            }
        }
    const onEdit =  useCallback( (node) => setIsEdit(node),[]);
    const onConnect = useCallback((params) => {
        setEditedCount(editedCount =>editedCount + 1)
        setEdges((eds) => addEdge({ ...params, type: 'custom', data: { onDelete: handleDeleteEdge }}, eds))
    }, []);

    const handleDeleteEdge = (id) => {
        setEdges((els) => els.filter((el) => el.id !== id));
        setEditedCount(editedCount => editedCount+1)
    }

    const onDeleteValues = useCallback( (props) =>{
        setNodes(nodes => nodes?.filter(a => a?.id !== props));
        setEdges(edges => edges?.filter(a => a?.source !== props && a?.target !== props ));
        setEditedCount(editedCount => editedCount+1)
    },[]);
    const onSave = useCallback( ({app,id,value, ...props}) =>{
        setNodes(nodes => nodes?.map(a => a?.id === id ? {...a, id, app, data: {...a?.data, id, app, value, ...props}} : a));
        setEditedCount(editedCount => editedCount+1)
        // onSaveFlow();
    },[]);

    useImperativeHandle(ref, () => ({
        addNode: async () =>{
            // const { x: viewportX, y: viewportY, zoom } = getViewport();
            // const newNodePosition = {
            //     x: viewportX + 100 / zoom, // Offset by 100px horizontally within the viewport
            //     y: viewportY + 100 / zoom, // Offset by 100px vertically within the viewport
            // };
            const id = getRandomString(5);
            setNodes(nodes => [...nodes,{id, type: "trigger", position: {x: 100, y:100}, data: {id, onDelete: onDeleteValues,onEdit}}]);
            setRender(render+1);
            setEditedCount(editedCount+1)
        },
        onSave: () =>{
            setEditedCount(0)
            return {nodes,edges}
        },
        isEdited: () =>{
            return editedCount>0;
        }
    }));
    useEffect(() =>{
        setNodes(details?.nodes?.map(a => ({...a,data: {...a?.data,onDelete: onDeleteValues,onEdit}})) || []);
        setEdges(details?.edges?.map(a => ({...a, data: { ...a?.data,onDelete: handleDeleteEdge }})) || []);
        setRender(render + 1);
    },[details]);

    return <div className="wrapper" ref={reactFlowWrapper}>
        <div style={{height: "75vh", width: "100%", overflow: "auto", backgroundColor: "#ccc"}}>
            {editedCount != 0 && <Paper shadow={"md"} withBorder p={3}>
                <Group position={"apart"}>
                    <BoldText color={"red"} text={`You have unsaved Changes. Please Save.`}/>
                    <Button compact onClick={onSaveFlow}>Save</Button>
                </Group>
            </Paper>}
            {isEdit &&
                <FlowApps flowId={flowId} onClose={() => setIsEdit(undefined)} data={isEdit} trigger={isEdit?.startNode}
                          onSave={onSave} webhookList={webhookList} webhookRes={webhookRes?.[isEdit?.id]}/>}
            <ReactFlowProvider>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={nodeTypes}
                    edgeTypes={{custom: CustomEdge}}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onConnectEnd={onConnectEnd}
                    // connectionLineComponent={ConnectionLine}
                    // minZoom={2}
                    // maxZoom={2}
                    fitView
                    nodeOrigin={[0.5,0]}
                >
                    <Controls/>
                    <MiniMap/>
                    {/*<Background variant="dots" gap={12} size={1} style={{backgroundColor: "#ccc"}} />*/}
                </ReactFlow>
            </ReactFlowProvider>
        </div>
    </div>
});


const CustomHandler = ({id,type, isConnected,position}) =>{
    const { setEdges } = useReactFlow();
    const removeEdge=() =>{
        setEdges(eds => eds?.filter(a => a?.[type || "source"] !== id));
    }

    return <Handle
        id={id}
        type={type || "source"}
        position= {position || "bottom"}
        style={{ background: "transparent", border: "none", padding: 0 }}
        isConnectable={!isConnected}
        aria-disabled={isConnected}
    >
        <Group m={-5} style={{pointerEvents: !isConnected ? "none": "all"}} onClick={removeEdge}>
            <IconView icon={isConnected ? <IconX color={"darkred"}/> : <IconPlus color={"green"}/>} variant={"filled"}
                      color={isConnected ? "red" : "green"} size={16} />
        </Group>
    </Handle>
}


import React, {useEffect, useState} from 'react';
import {
    Button, Grid,
    Group,
    Modal,
    MultiSelect,
    Pagination,
    Paper,
    SegmentedControl,
    Select,
    Text, Textarea,
    TextInput
} from "@mantine/core";
import EditBookTemplate from "./EditBookTemplate";
import {useDispatch, useSelector} from "react-redux";
import {IconCheck, IconPlane, IconSend, IconSquare, IconSquareCheck} from "@tabler/icons";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {R365Api, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import { Header, IconView, PageLoading, TableView} from "../../Pages/Components/PageComponents";
import * as XLSX from "xlsx";
import moment from "moment";
import PreviewScheduleMessage from "../../Pages/Components/PreviewScheduleMessage";

const PageHeading = {
    title: "Book Templates",
    subTitle: "Manage Book Part Templates.",
    buttons: [{type: "upload", title: "Upload Books"},{type: "add", title: "Create Templates"},{type: "refresh", title: "Refresh"}]
};
const BookTemplates = ({productId}) =>{
    const dispatch = useDispatch();
    const {userId} = useSelector(state => state.active);
    const [isLoading, setIsLoading] = useState(false);
    const [templates,setTemplates] = useState([]);

    const [isCreate,setIsCreate] = useState(false);

    const [filtered,setFiltered] = useState([]);
    const [search,setSearch] = useState("");
    const [notExits,setNotExists] = useState([]);

    const [page,setPage] = useState(1);
    const [isEdit,setIsEdit] = useState("");
    const [isUpload,setIsUpload] = useState(false);
    const [render,setRender] = useState(0);

    useEffect(() => {
        getTemplates()
    },[productId])
    useEffect(() => {
        getTemplates()
    },[])
    useEffect(() =>{
        let filtered = templates;
        if(search?.trim() !== "") filtered = filtered?.filter(a => a?.name?.includes(search) || a?.bookId?.includes(search) || a?.en?.includes(search) || a?.ta?.includes(search) || a?.hin?.includes(search))
        console.log(notExits);
        if(notExits?.length>0){
            if(notExits?.includes("en")) filtered = filtered?.filter(a => a?.en === undefined);
            if(notExits?.includes("ta")) filtered = filtered?.filter(a => a?.ta === undefined);
            if(notExits?.includes("hin")) filtered = filtered?.filter(a => a?.hin === undefined);
        }
        setFiltered(filtered);
        setPage(1);
    },[search,templates,render])

    const getTemplates = async () =>{
        setIsLoading(true);
        const templates = await RequestGet(`${R365Api.bookTemplates}/${userId}/${productId}`);
        setTemplates(templates?.templates || []);
        setFiltered(templates.templates || []);
        setSearch("");
        setPage(1);
        setIsLoading(false);
    }
    const sendTemplate = async  (name,bookId,lang) =>{
        dispatch(ActionShowAlert({message: `Sending Test Template:  ${name} (${lang})`,color:"green"}));
        const result = await RequestGet(`${R365Api.TestTemplate}/${userId}/${productId}/${name}/${lang}`)
        if(result?.status === 1){
            dispatch(ActionShowAlert({message: result?.message,color:"green"}));
        }else{
            dispatch(ActionShowAlert({message: result?.message,color:"red"}));

        }
    }
    const handleDelete =(bookId,name) =>{
        dispatch(ActionShowPopup({
            title: "Delete Book Templates",
            content: "It will Delete the Book template. If any Book added With that Will Not Work. Are you Sure?",
            onSuccess: async () =>{
                const result = await RequestGet(`${R365Api.DeleteBookTemplate}/${productId}/${bookId}/${name}`);
                dispatch(ActionHidePopup());
                dispatch(ActionShowAlert({message: result?.message, color: result?.status === 1 ? "green": "red"}))
                getTemplates();
            },
            successTitle: "Delete",
            cancelTitle: "Cancel"
        }))
    }
    const openCreate = () => setIsCreate(true);
    const hideCreate = (isRefresh = false) => {
        setIsCreate(false);
        if(isRefresh) getTemplates();
    }
    const handleNotExits = (lang) =>{
        let e = notExits;
        if(notExits?.includes(lang)){
            e = e.filter(a => a != lang)
        }else{
            e.push(lang)
        }
        console.log(e);
        setNotExists(e);
        setRender(render+1);

    }

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: () => setIsUpload(true)},{onClick: openCreate, isLoading},{onClick: getTemplates, isLoading}]} />
        <PageLoading isLoading={isLoading}>

            <Paper shadow={"md"} withBorder my={10} p={5}>
                <Group position={"apart"}>
                    <Group>
                        <TextInput size={"xs"} placeholder={"Search Name, bookId, Template"} value={search} onChange={e => setSearch(e.target.value)} />
                    </Group>
                    <Text>Showing <b>{filtered?.length} / {templates?.length}</b> Results</Text>
                    <Group>
                        <Text>Not Exists</Text>
                        <Group onClick={() => handleNotExits("en")}>{notExits?.includes("en") ? <IconSquareCheck /> : <IconSquare />}EN</Group>
                        <Group onClick={() => handleNotExits("ta")}>{notExits?.includes("ta") ? <IconSquareCheck /> : <IconSquare />}Ta</Group>
                        <Group onClick={() => handleNotExits("hin")}>{notExits?.includes("hin") ? <IconSquareCheck /> : <IconSquare />}Hin</Group>
                    </Group>
                    <Pagination size={"xs"} total={Math.ceil(filtered?.length/ 10)} value={page} onChange={setPage} />
                </Group>
            </Paper>
            <TableView headers={["Name","BookId","EN","TA","HIN","Options"]}>
                {filtered?.slice((page-1) * 10,page*10)?.map(a => <tr>
                    <td>{a?.name}</td>
                    <td>{a?.bookId}</td>
                    <td>{a?.en && <IconCheck size={"12"} />}</td>
                    <td>{a?.ta && <IconCheck size={"12"} />}</td>
                    <td>{a?.hin && <IconCheck size={"12"} />}</td>
                    <td>
                        <Group>
                            <IconView iconType={"edit"} label={"Edit Template"} onClick={() => setIsEdit(a?.name)} />
                            <IconView iconType={"delete"} label={"Delete Template"} onClick={() => handleDelete(a?.bookId,a?.name)}  />
                        </Group>
                    </td>
                </tr>)}
            </TableView>
            {isEdit && <EditBookTemplate productId={productId} name={isEdit} onClose={() => setIsEdit(false)} />}
            {isCreate && <CreateBookTemplates onClose={hideCreate} productId={productId} />}
            {isUpload && <UploadBookParts productId={productId}  onClose={() => setIsUpload(false)} onRefresh={getTemplates} />}
        </PageLoading>
    </>
}

export default BookTemplates


const CreateBookTemplates = ({onClose,productId}) =>{
    const dispatch = useDispatch();
    const {userId} = useSelector(state => state.active);
    const [bookId,setBookId] = useState("");
    const [tempIds,setTempIds] = useState("");
    const [isError,setIsError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);

    const AddTemplates = async () =>{
        const tempList = tempIds?.split("\n")?.map(a => a?.trim()?.toLowerCase())?.filter(a => a?.trim() !== "")
        if(bookId?.trim() !== "" && tempList?.length > 0){
            setIsError(false);
            setIsLoading(true);
            const result = await RequestPost(`${R365Api.CreateBookTemplate}/${userId}/${productId}`,{bookId, templates: tempList});
            setIsLoading(false);
            dispatch(ActionShowAlert({message: result?.message}));
            onClose(true)
        }else{
            setIsError(true);
        }
    }

    return <Modal mt={20} title={<Text weight={'bold'}>Create Templates</Text>} opened={true} size={"70%"} onClose={onClose}>
        <TextInput label={"BookId"} required error={isError && bookId?.trim() === "" ? "BookId is Required": ""} value={bookId} onChange={(e) => setBookId(e.target.value)} />
        <Textarea label={"Template Name(s) (one template per Line)"} required value={tempIds} onChange={(e) => setTempIds(e.target.value)} error={isError && tempIds?.trim() === "" ? "Add Atleaset One TemplateId": ""} />
        <Group position={"center"} mt={10}>
            <Button onClick={AddTemplates} loading={isLoading}>Create Template</Button>
        </Group>
    </Modal>
}

const UploadBookParts = ({onClose, productId, onRefresh}) =>{
    const {userId,sessionId} = useSelector(state => state.active);
    const [data,setData] = useState();
    const [sheet,setSheet] = useState();
    const [content,setContent] = useState([]);
    const [page,setPage] = useState(1);
    const [isLoading,setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleUpload = (e) => {
        console.log('upload calling');
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            setData(readedData);
            setSheet(readedData?.SheetNames?.[0]);
        };
        reader.readAsBinaryString(f)
    }
    useEffect(() => {
        if(sheet){
            const ws = data.Sheets[sheet];
            let [title,...dataparsed] = XLSX.utils.sheet_to_json(ws, {header:1});
            const content = dataparsed?.map(a => title.reduce((acc, header, index) => {
                acc[header] = a[index];
                return acc;
            }, {}));
            const formatted = content?.map(a => {
                let temp = {
                    "name": a?.tempId?.toString()?.trim(),
                    "bookId": a?.bookId?.toString()?.trim(),
                    "productId": "Daily1BookV2",
                    "created": moment().unix()
                };
                if ((a?.btn1_id && a?.btn1_title) || (a?.btn2_id && a?.btn2_title) || (a?.btn3_id && a?.btn3_title)) {
                    const buttons = [];
                    if(a?.btn1_id && a?.btn1_title) buttons.push({title: a?.btn1_title, id: a?.btn1_id});
                    if(a?.btn2_id && a?.btn2_title) buttons.push({title: a?.btn2_title, id: a?.btn2_id});
                    if(a?.btn3_id && a?.btn3_title) buttons.push({title: a?.btn3_title, id: a?.btn3_id});
                    temp = {
                        ...temp, [a?.lang?.toLowerCase()]: {
                            "header": {"type": "IMAGE", "image": {"code": a?.image}},
                            "bodyText": a?.content,
                            "footerText": a?.footer,
                            buttons,
                            "editOptions": {
                                "msgType": "Buttons",
                                "type": "session",
                                "headerText": "",
                                "bodyText": a?.content,
                                "footerText": a?.footer,
                                buttons,
                                "headerType": "IMAGE",
                                "file": a?.image
                            }
                        }
                    }
                } else {
                    temp = {
                        ...temp, [a?.lang?.toLowerCase()]: {
                            "message": a?.content,
                            "image": {"code": a?.image},
                            "editOptions": {
                                "msgType": "Image",
                                "type": "session",
                                "caption": a?.content,
                                "isCode": true,
                                "mediaCode": a?.image,
                                "folder": "",
                                "file": a?.image
                            }

                        }
                    }
                }
                return temp;
            }).filter(a => a?.name && a?.bookId);
            setContent(formatted);
        }
    },[sheet]);
    const handleSubmit = async () =>{
        setIsLoading(true);
        const result = await RequestPost(`${R365Api.BulkBookUpload}/${userId}/${productId}`,{list: content,sessionId});
        dispatch(ActionShowAlert(result?.message));
        onClose();
        onRefresh();
        setIsLoading(false);
    }

    return <Modal onClose={onClose}  opened  title={<b>Upload Books</b>} size={"80%"}>
        <Grid>
            <Grid.Col span={6}>
                <input type="file" onChange={handleUpload} style={{"padding": "10px"}}/>
            </Grid.Col>
            {data?.SheetNames && <Grid.Col span={6}>
                <Select size={"xs"} label={"Sheet Name"} data={data?.SheetNames || []} value={sheet} onChange={setSheet} />
            </Grid.Col>}
            {content?.length > 0 && <>
                <Grid.Col span={12}>
                    <Pagination position={"right"} size={"xs"} total={content?.length} page={page} onChange={setPage} />
                    <PreviewScheduleMessage message={content?.[page-1]?.en} />
                    <Group position={"center"} my={10}>
                        <Button loading={isLoading} disabled={content?.length < 0} onClick={handleSubmit}>Update Message</Button>
                    </Group>
                </Grid.Col>
            </>}

        </Grid>
    </Modal>
}


import {Button, Divider, Group, Paper, Select, Stack} from "@mantine/core";
import {BoldText, ErrorText, SmallText} from "../Components/TextComponents";
import {IconView} from "../Components/PageComponents";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IconCheck, IconX} from "@tabler/icons";
import {AdminApi, RequestPost} from "../../Layouts/RequestManager";
import {ActionShowAlert} from "../../store/actions/alert";

const PlanPreview = ({details, devices,isAdmin}) =>{
    const {email} = useSelector(state => state.user);
    const {sessionId,userId} = useSelector(state => state.active);
    const [wbaSessionId, setWbaSessionId] = useState(sessionId);
    const [bsmSessionId, setBsmSessionId] = useState(undefined);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(false);
    const dispatch = useDispatch();
    const getPayLink = async () =>{
        const isValid = wbaSessionId && ((details?.products?.includes("BSM") && bsmSessionId ) || !details?.products?.includes("BSM"))
        if(isValid){
            setError(undefined);
            setLoading(true);
            const data = await RequestPost(AdminApi.createPay,{
                amount: details?.price,email,plan: details?.plan,userId, wbaSessionId,bsmSessionId
            });
            setLoading(false)
            if(data?.status === 1 && data?.data?.short_url){
                window.open(data?.data?.short_url);
            }else{
                dispatch(ActionShowAlert({message: "Something went wrong", color: "red"}))
            }
        }else{
            setError("Please Choose Devices");
            dispatch(ActionShowAlert({message: "Please Choose Devices", color: "red"}))
        }
    }
    return  <Paper shadow={"md"} width={"33%"} height={"80%"} withBorder>
        <BoldText variant={"gradient"} text={details?.title} align={"center"} my={3} size={"md"} />
        <SmallText color={"dimmed"} text={details?.description} align={"center"} size={"sm"} my={3} />
        <Group sx={{gap: 20}} position={"center"} my={5}>
            <BoldText text={`₹. ${details?.price}`} />
            <BoldText color={"dimmed"} text={`₹. ${details?.oldPrice}`} style={{textDecoration: "line-through"}} />
        </Group>
        {!isAdmin &&  <>
            <Divider />
            {details?.products?.includes("WBA") && <EditDevice devices={devices?.wba} sessionId={wbaSessionId} type={"wba"} onChange={setWbaSessionId} />}
            {details?.products?.includes("BSM") && <EditDevice devices={devices?.bsm} sessionId={bsmSessionId}  type={"bsm"} onChange={setBsmSessionId} />}
            {error && <ErrorText text={error} />}
            <Group position={"center"} my={10}>
                <Button loading={loading} compact fullWidth mx={20} onClick={getPayLink}>Update</Button>
            </Group>
        </>}
        <Divider />
        <Stack sx={{gap: 5}} mx={10} my={10}>
            <BoldText text={`Features: `} size={"md"} variant={"gradient"} />
            {details?.prefrences?.map(a => <BoldText text={a} />)}
        </Stack>
    </Paper>
}

export default PlanPreview;

const EditDevice = ({devices, sessionId, onChange, type}) =>{
    const [isEdit,setIsEdit] = useState(false);
    const [deviceId,setDeviceId] = useState(sessionId);

    return  <Group position={"apart"} m={5}>
        <BoldText text={`Choose ${type?.toUpperCase()} Device:`}/>
        {isEdit ? <Group>
            <Select size={"xs"} data={devices?.map(a => ({value: a?.sessionId, label: a?.name}))} value={deviceId} onChange={setDeviceId} />
            <IconView icon={<IconCheck />} onClick={() => {
                onChange(deviceId);
                setIsEdit(false)
            }}  />
            <IconView icon={<IconX />} onClick={() => setIsEdit(false)} />
        </Group> :<Group>
            <BoldText text={devices?.find(a => a?.sessionId === sessionId)?.name}/>
            {!(sessionId && devices?.length === 1 )&& <IconView iconType={"edit"} onClick={() => setIsEdit(true)}/>}
        </Group>}
    </Group>
}
import {useDispatch, useSelector} from "react-redux";
import {ActionUserLogout} from "../store/actions/user";
import {
    IconBook,
    IconForms,
    IconGauge, IconGraph,
    IconInbox, IconList,
    IconLogout,
    IconMessage, IconPhoto, IconSettingsAutomation,
    IconTemplate,
    IconTimeline, IconUsers
} from "@tabler/icons";
import {Colors} from "../Constants/Colors";
import {Divider, Group, ScrollArea, Stack, Text} from "@mantine/core";
import React from "react";
import {ActionShowAlert, ActionShowPopup} from "../store/actions/alert";
import {useMediaQuery} from "@mantine/hooks";

const SideBar = ({height,navigation}) =>{
    const {sessionId,alias,r365,addOns} = useSelector(state => state.active);
    const dispatch = useDispatch();

    const handleNavigation = (path) => navigation(`device/${alias}${path}`);
    const handleLogout = () =>{
        dispatch(ActionShowPopup({
            title: "Logout",
            content: "Are you sure want to Logout ?",
            successTitle: "Logout",
            cancelTitle: "Cancel",
            onSuccess: () =>{
                dispatch(ActionUserLogout());
                navigation("/")
            }
        }))
    }
    const  lists =[
        {icon: <IconGauge size={20} color={Colors.Primary}  />,label: 'Dashboard',path: `/`},
        ...(addOns?.includes("Tracker") ?
            [
                {icon: <IconTimeline size={20} color={Colors.Primary}  />,label: 'Tracker',path: `/tracker`},
            ]:
            []),
        ...(r365?.productId ?
            [
                {icon: <IconBook size={20} color={Colors.Primary}  />,label: 'Read365',path: `/r365`},
            ]:
            []),
        {icon: <IconUsers size={20} color={Colors.Primary}  />,label: 'CRM',path: `/crm`},
        {icon: <IconSettingsAutomation size={20} color={Colors.Primary}  />,label: 'Flows',path: `/flows`},
        {icon: <IconPhoto size={20} color={Colors.Primary}  />,label: 'Attachments',path: `/attachments`},
        {icon: <IconTemplate size={20} color={Colors.Primary}  />,label: 'Templates',path: `/templates`},
        {icon: <IconForms size={20} color={Colors.Primary}  />,label: 'Forms',path: `/forms`},
        {icon: <IconGraph size={20} color={Colors.Primary}  />,label: 'Sequence',path: `/sequence`},
        {icon: <IconList size={20} color={Colors.Primary}  />,label: 'MessageList',path: `/message-list`},
        {icon: <IconInbox size={20} color={Colors.Primary}  />,label: 'Inbox',path: `/inbox`},
        {icon: <IconMessage size={20} color={Colors.Primary}  />,label: 'Chatbot',path:`/chatbots`},
        {icon: <IconMessage size={20} color={Colors.Primary}  />,label: 'Programs',path: `/programs`},
        {icon: <IconList size={20} color={Colors.Primary}  />,label: 'Scheduled',path: `/schedules`},
        {icon: <IconTimeline size={20} color={Colors.Primary}  />,label: 'Message Logs',path:`/logs`},
        // {icon: <IconTimeline size={20} color={Colors.Primary}  />, label: 'BSM Flow', path: `/bsmflow`},
        // {icon: <IconSquareX size={20} color={Colors.Primary}  />,label: 'Blocked Contacts',path: `/blocked`},
    ];
    const isMobile = useMediaQuery(`(max-width:  750px)`);
    return <ScrollArea pt={10} sx={{position: 'fixed', bottom: 0, left: 0,top: 0,backgroundColor:'rgb(90, 50, 168,0.2)', height, borderRightWidth: 2, borderRightColor: Colors.Primary,borderRightStyle: 'solid',gap: 14}} align={'center'} scrollbarSize={1} type={"auto"} >
        <Stack mb={50} sx={{gap: 10}} pt={10}>
            {/*<Stack sx={{gap: 3,cursor: "pointer"}} onClick={() => navigation("/")}>*/}
            {/*    <Text size={"md"} weight={"bolder"} color={"violet"} my={10}>BSM</Text>*/}
            {/*</Stack>*/}
            {lists.map(a => {
                if(a?.type === 'divider') <Divider sx={{gap: 3}} color={'white'} />
                return <Stack sx={{gap: 3,cursor: "pointer"}} onClick={() => handleNavigation(a?.path)}>
                    <Group position={"center"}>{a?.icon}</Group>
                    {!isMobile && <Text size={"xs"} color={"violet"}>{a?.label}</Text>}
                </Stack>
            })}
            <Stack sx={{gap: 3,cursor: "pointer"}} onClick={handleLogout}>
                <Group position={"center"}><IconLogout size={20} /></Group>
                {!isMobile && <Text size={"xs"} color={"violet"}>Logout</Text>}
            </Stack>
        </Stack>
    </ScrollArea>
}
export default SideBar;

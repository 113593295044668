import CryptoJS from "crypto-js";
import moment from "moment";
import {ActionHidePopup, ActionShowPopup} from "../store/actions/alert";
import {useDispatch, useSelector} from "react-redux";
export const encodedStr = (sessionId,userId) => CryptoJS.AES.encrypt(JSON.stringify({sessionId,userId}), 'coachesMagaduka').toString();
export const formatMobileNumber = (phone,cc = "91") =>{
    let mobile = phone?.toString();
    if(mobile?.startsWith("+")){
        mobile =  mobile?.replace(/\D/g, '');
    }else{
        mobile = mobile?.replace(/\D/g, '')?.replace(/^0+/, "");
        if(["9","8","7","6"]?.includes(mobile?.[0])){
            //indian Number
            if(mobile?.length === 10) mobile = `${cc}${mobile}`;
        }
    }
    return mobile
}
export const flattenObject = (obj, parentKey = '') => {
    return Object.keys(obj).reduce((acc, key) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        if (  obj[key] && typeof obj[key] === 'object') {
            return acc.concat(flattenObject(obj[key], fullKey));
        } else {
            return acc.concat({ [fullKey]: obj[key] });
        }
    }, []);
}
export const getRandomString = (len = 5) =>{
    var text = "";
    var shuffle = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for( var i= 0; i < len; i++ ){
        text += shuffle.charAt(Math.floor(Math.random() * shuffle.length));
    }
    return text;
}
export const findVariables = (msg) =>{
    const regex = /{{([^}]+)}}/g;
    const matches = msg?.matchAll(regex) || [];
    const result = [];
    for (const match of matches) {
        result.push(match[1]);
    }
    return result;
}
export const formatTime = (duration) =>{
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
        ret += "" + hrs + "hr " + (mins < 10 ? "0" : "");
    }
    if(mins > 0){
        ret += "" + mins + "m" + (secs < 10 ? "0" : "");
    }
    ret += "" + secs+ "s";
    return ret;
}

export const convertSecondsToHoursMinutes = (seconds)  => {
    const hours = Math.floor(seconds / 3600); // Calculate hours (3600 seconds in an hour)
    const remainingSeconds = seconds % 3600; // Calculate the remaining seconds after extracting hours
    const minutes = Math.floor(remainingSeconds / 60); // Calculate minutes (60 seconds in a minute)
    // Format the output with leading zeros if needed
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}
export const currentISTTime = (date) =>{
    var currentTime = date  ? new Date(date): new Date();
    var currentOffset = currentTime.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30
    var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset)*60000);
    return ISTTime;
}
export const formatLTTime = (sendTime) =>{
    const h = sendTime.getHours();
    const t = `${ h > 12 ? h - 12 : h}:${`0${sendTime.getMinutes()}`.slice(-2)} ${h >= 12 ? "PM":"AM"}`;
    return t
}
export const getValuesOfDate = (sendTime) =>{
    const date = sendTime.getDate();
    const month = sendTime.getMonth();
    const year = sendTime.getFullYear();
    return {date,month,year}
}
export const getValuesOfTime = (sendTime) =>{
    const h = sendTime.getHours();
    const t = {hour: h, minutes: sendTime.getMinutes()};
    return t
}
export const convertUnixToFormat = (timestamp, format = 'lll') =>{
    return timestamp ? moment.unix(timestamp).format(format): ""
}

export const timeConversionWithOffset = ({type,timestamp,format,dateTime}) =>{
    const offset =  330;
    switch(type){
        case 'timestampFromNow':
            return  moment.unix(timestamp).utcOffset(offset)?.fromNow();
        case 'diffFromNow':
            return  moment.unix(timestamp).utcOffset(offset)?.diff(moment().utcOffset(offset) ,format || 'day');
        case 'momentFormat':
            return moment(dateTime).utcOffset(offset).format(format || 'lll');
    }
}
export const isArrayUnique = (array) => {
    const checkSet = new Set(array);
    return checkSet.size === array.length;  
}
export const getFileTypefromMime = (type) => {
   if(type?.includes("image/")) return "Image"
   if(type?.includes("audio/")) return "Audio"
   if(type?.includes("video/")) return "Video"
    return "Document"
}

export const showMaxAlert = ({dispatch,type}) =>{
        dispatch(ActionShowPopup( {
            title: "Max. Reached",
            content: `You’ve reached the maximum number of ${type} allowed on your current plan.\n\nTo add more, please delete any unused ${type} or consider upgrading your plan.`,
            successTitle: "Okay",
            hideCancel: true,
            onSuccess: () => dispatch(ActionHidePopup())
        }));

}
import {Badge, Grid, Group, Paper, Stack} from "@mantine/core";
import React from "react";
import {DetailsCard} from "../Components/PageComponents";

const UserDetails = ({details}) =>{

    return <Stack sx={{gap: 2, width: "100%"}}>
        <DetailsCard label={"Mobile"} value={details?.mobile} mb={20} />
        {details?.params && Object.keys(details?.params)?.map(a => <DetailsCard label={a} value={details?.params?.[a]} />)}
        <DetailsCard label={"Tags"} mt={20}  rightComponent={<Group>
                {details?.tags?.map(a => <Badge>{a}</Badge>)}
                </Group>} />
        <DetailsCard label={"Projects"} mt={20}  rightComponent={<Group>
                {details?.projects?.map(a => <Badge>{a}</Badge>)}
            </Group>} />
    </Stack>
}



export default UserDetails;
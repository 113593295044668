import {Accordion, Drawer, Grid, Group, Paper, Button, Stack, Badge, Modal} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {BoldText, SmallText} from "../Components/TextComponents";
import {IconView, PageLoading} from "../Components/PageComponents";
import moment from "moment";
import React from "react";
import {FilledTextInput} from "../Sequence/CustomButton";
import {ActionHidePopup, ActionShowAlert} from "../../store/actions/alert";
import {useNavigate} from "react-router-dom";

const ProfilePage = ({onClose}) =>{
    const {userId} = useSelector(state => state.user);
    const [isLoading,setIsLoading] = useState(false);
    const [profile,setProfile] = useState();
    const [sessions,setSessions] = useState([]);
    const [isEditProfile,setIsEditProfile] = useState(false);
    const [isChangePwd,setIsChangePwd] = useState(false);
    const navigation = useNavigate();

    useEffect(() => {
        getDetails();
    },[])
    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.UserProfile}/details/${userId}`,{})
        setProfile(data?.user);
        setSessions(data?.sessions || []);
        setIsLoading(false);
    }
    const handlePlanUpdate = () => {
        onClose();
        navigation("/update")
    }



    return <Drawer opened={true} onClose={onClose} position={"right"} size={"50%"} title={<BoldText size={"md"} text={"Profile"} mx={5} />}>
        <PageLoading isLoading={isLoading}>
            {isEditProfile && <EditProfile onRefresh={getDetails} userId={userId} profile={profile}  onClose={() => setIsEditProfile(false)} />}
            {isChangePwd && <ChangePassword onRefresh={getDetails} userId={userId} onClose={() => setIsChangePwd(false)} />}
            <Paper shadow={"md"} withBorder p={5} mx={10}>
                <Stack sx={{gap: 5}}>
                    <Group position={"apart"}>
                        <SmallText text={profile?.email} />
                        <IconView iconType={"edit"} onClick={() => setIsEditProfile(true)} />
                    </Group>
                    <Group position={"apart"}>
                        <BoldText text={profile?.name} />
                        <SmallText text={profile?.mobile} />
                    </Group>
                </Stack>
            </Paper>
            <Button compact onClick={() => setIsChangePwd(true)} mx={10} my={5}>Change Password</Button>
            {/*<Button compact onClick={handlePlanUpdate} mx={10} my={5}>Upgrade Plan </Button>*/}
            <Accordion>
                {sessions?.map(a =>{
                    return  <Accordion.Item value={a?.sessionId}>
                    <Accordion.Control>
                        <Group position={"apart"}>
                            {a?.name}
                            <Group>
                                {a?.expiry && <SmallText text={`Expires  ${moment.unix(a?.expiry).fromNow()}`}/>}
                                {a?.activePlans?.length > 0 ? <Badge>{a?.activePlans}</Badge>: <Badge color={"red"}>No Active Plans</Badge>}
                            </Group>
                        </Group>

                    </Accordion.Control>
                        <Accordion.Panel>
                            <PrefCard prefrences={a?.prefrences} prefUsed={a?.prefUsed} />
                        </Accordion.Panel>
                    </Accordion.Item>
                })}
            </Accordion>
        </PageLoading>

    </Drawer>
}

export default ProfilePage;

const EditProfile = ({profile,onClose,userId,onRefresh}) =>{
    const [name,setName] = useState(profile?.name || "");
    const [mobile,setMobile] = useState(profile?.mobile ||"");
    const [isLoading,setIsLoading] = useState(false);
    const [isError,setIsError] = useState(false);
    const dispatch = useDispatch();

    const UpdateProfile = async () =>{
        if(name?.trim() !== "" && mobile?.trim() !== ""){
            setIsError(false);
            setIsLoading(true);
            const data = await RequestPost(`${CloudApi.UserProfile}/updateDetails/${userId}`,{name,mobile});
            dispatch(ActionShowAlert({message: data?.message}));
            onClose();
            onRefresh();
            setIsLoading(false);
        }else{
            setIsError(true);
        }
    }

    return <Modal opened={true} onClose={onClose} title={<b>Edit Profile</b>} >
        <Stack sx={{gap: 3}}>
            <FilledTextInput label={"Name"} value={name} onChange={setName} error={isError && name?.trim() === "" ? "Name is Required":""} />
            <FilledTextInput label={"Mobile"} value={mobile}  onChange={setMobile} error={isError && mobile?.trim() === "" ? "Mobile is Required":""} />
            <Group position={"center"} my={20}>
                <Button compact onClick={UpdateProfile} loading={isLoading}>Update Profile</Button>
            </Group>
        </Stack>
    </Modal>
}
const ChangePassword = ({onClose,userId,onRefresh}) =>{
    const [password,setPassword] = useState( "");
    const [confirmPwd,setConfirmPwd] = useState("");

    const [isLoading,setIsLoading] = useState(false);
    const [isError,setIsError] = useState(false);
    const dispatch = useDispatch();

    const ChangePassword = async () =>{
        if(password?.trim() !== "" && confirmPwd?.trim() !== "" && password === confirmPwd){
            setIsError(false);
            setIsLoading(true);
            const data = await RequestPost(`${CloudApi.UserProfile}/changePassword/${userId}`,{password});
            onClose();
            onRefresh();
            dispatch(ActionShowAlert({message: data?.message}));
            setIsLoading(false);
        }else{
            setIsError(true);
        }
    }


    return <Modal opened={true} onClose={onClose} title={<b>Change Password</b>}>
        <Stack sx={{gap: 3}}>
            <FilledTextInput label={"Password"} value={password} onChange={setPassword} error={isError && password?.trim() === "" ? "Password is Required":""} />
            <FilledTextInput label={"Confirm Password"} value={confirmPwd} onChange={setConfirmPwd} error={isError && confirmPwd?.trim() === "" ? "Password is Required": isError && confirmPwd?.trim() !== password?.trim()  ? "Passwords Not Matched":""} />
            <Group position={"center"}>
                <Button compact loading={isLoading} onClick={ChangePassword}> Update password</Button>
            </Group>
        </Stack>
    </Modal>
}

const PrefCard = ({prefrences, prefUsed}) => {
    const data = [
        // {label: "Total Days",total: user?.totalDays || 0,value: user?.day,mul: 1},
        {label: "Chatbot",total: prefrences?.chatbots || 0,value: prefUsed?.chatbots || 0,mul: 1},
        {label: "Sequence",total: prefrences?.sequence || 0,value: prefUsed?.sequence || 0,mul: 1},
        {label: "Folders",total: prefrences?.attachments || 0,value: prefUsed?.attachments || 0,mul: 1},
        {label: "Files",total: prefrences?.files || 0,value: prefUsed?.files || 0,mul: 1},
        {label: "Programs",total: prefrences?.programs || 0,value: prefUsed?.programs || 0,mul: 1},
        {label: "Forms",total: prefrences?.forms || 0,value: prefUsed?.forms || 0,mul: 1},

    ];
    console.log(prefrences,prefUsed,data);
    const calculatePer = ({total,value,mul}) =>{
        return Math.ceil((value / (total * mul))* 100);
    }
    return  <Grid>
        {data?.map(a =>(
            <Grid.Col span={6}>
                <Paper shadow={"md"} withBorder p={5}>
                    <Group position={'apart'}>
                        <BoldText text={a?.label}  />
                        <Badge>{a?.value}/{a?.total}</Badge>
                    </Group>
                </Paper>
            </Grid.Col>
        ))}
    </Grid>
}